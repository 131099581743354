import React, { useState } from 'react';
import { Button, Col, Radio, Row, Modal } from "antd";
import mercadopagoLogo from '../../../../images/MercadopagoLogo.webp';
import Paso1mercadopago from '../../../../images/Paso1mercadopago.svg';
import Paso2mercadopago from '../../../../images/Paso2mercadopago.svg';
import Paso3mercadopago from '../../../../images/Paso3mercadopago.svg';
import visaLogo from '../../../../images/visaLogo.png'
import redCompraLogo from '../../../../images/redCompraLogo.png'
import mastercardLogo from '../../../../images/mastercardLogo.png'
import dinnerClubInternationalLogo from '../../../../images/dinnerClubInternationalLogo.png'
import americanExpressLogo from '../../../../images/americanExpressLogo.png'
import mercadopagoAvailableLogo from '../../../../images/mercadoPagoAvailableLogo.png'

const PaymentBlock = ({
  paymentMethod,
  setPaymentMethod,
  name,
  disabled,
  children,
  }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => setModalVisible(true);

  const closeModal = () => setModalVisible(false);


  return (
    <>
    <Button
      block
      className={name !== 'mercadopagocl' ? 'payment-method' : 'payment-method--mercadopago'}
      size='large'
      onClick={() => setPaymentMethod(name)}
      disabled={disabled}
    >
    {
      name === 'mercadopagocl' ?
        <Row justify='space-between'>
          <Col>
            {children}
          </Col>
          <Col className='radio-button-wrapper'>
            <Radio className='cmt-radio' checked={paymentMethod === name}/>
          </Col>
          <Col lg={24} xs={24} className="modal-button-wrapper pt-20">
            <Row>
              <Col lg={24} xs={24}>
                <p className='text-left mb-10'>TARJETAS DE CRÉDITO</p>
                <Row>
                  <img src={visaLogo} alt="Visa logo" height={30} className="mr-10 mb-10" />
                  <img src={mastercardLogo} alt="Mastercard logo" height={30} className="mr-10 mb-10" />
                  <img src={americanExpressLogo} alt="American express logo" height={30} className="mr-10 mb-10" />
                  <img src={dinnerClubInternationalLogo} alt="Dinner club logo" height={30} className="mr-10 mb-10" />
                </Row>
              </Col>
              <Col lg={24} xs={24} className="mt-20">
                <p className='text-left mb-10'>TARJETAS DE DÉBITO</p>
                <Row>
                  <img src={redCompraLogo} alt="Red compra logo" height={35} className="mr-10" />
                </Row>
              </Col>
              <Col lg={24} xs={24} className="mt-20">
                <p className='text-left mb-10'>DINERO DE MERCADO PAGO</p>
                <Row>
                  <img src={mercadopagoAvailableLogo} alt="Mercadopago logo" height={40} className="mr-10" />
                </Row>
              </Col>
            </Row>
            <Row justify='end'>
              <Col>
                <Button
                  onClick={showModal}
                  className='cmt-button primary outline' 
                  type='primary'
                  size='small'
                >
                  Más información
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      :
        <Row justify='space-between'>
          <Col>
            {children}
          </Col>
          <Col>
            <Radio className='cmt-radio' checked={paymentMethod === name}/>
          </Col>
        </Row>
    }
    </Button>

    <Modal
      className='mercadopago-modal'
      width={900}
      open={modalVisible}
      onCancel={closeModal}
      footer={false}
    >
      <Row justify='center' className='pt-lg-10 pb-lg-20 pl-lg-30 pr-lg-30'>
        <Col lg={24} xs={24}>
          <Row justify='center'>
            <Col>
              <h1 className='mb-0'>Funcionamiento de pago con</h1>
            </Col>
            <Col className='logo-wrapper'>
              <img src={mercadopagoLogo} alt="mercadopago logo" height={30} />
            </Col>
          </Row>
          <Row justify='space-between' className="images-wrapper mt-20">
            <Col lg={7} xs={24}>
              <Row justify='center'>
                <Col lg={24} xs={24} className="logo-container">
                  <img src={Paso1mercadopago} alt="Paso 1 mercado pago" width={200} height={200} />
                </Col>
                <Col lg={24} xs={24}>
                  <h2 className='text-center'>Selecciona tu método de pago</h2>
                  <p className='text-center'>Si quieres pagar con tarjeta de débito o crédito, al contado o en cuotas, selecciona Mercado Pago</p>
                </Col>
              </Row>
            </Col>
            <Col lg={7} xs={24}>
              <Row justify='center'>
                <Col lg={24} xs={24} className="logo-container">
                  <img src={Paso2mercadopago} alt="Paso 2 mercado pago" width={200} height={200} />
                </Col>
                <Col lg={24} xs={24}>
                  <h2 className='text-center'>Espera tu confirmación</h2>
                  <p className='text-center'>Tu cupo está registrado. <br/> Te contactarán para verificar los datos de tu reserva y enviaremos el link de pago</p>
                </Col>
              </Row>
            </Col>
            <Col lg={7} xs={24}>
              <Row justify='center'>
                <Col lg={24} xs={24} className="logo-container">
                  <img src={Paso3mercadopago} alt="Paso 3 mercado pago" width={200} height={200} />
                </Col>
                <Col lg={24} xs={24}>
                  <h2 className='text-center'>Al pagar, tu reserva está lista</h2>
                  <p className='text-center'>Cuando recibamos el pago, tu reserva queda confirmada automáticamente y puedes presentarte en la sucursal</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
    </>
  )
}

export default PaymentBlock;