import React, { useState, useEffect } from 'react'
import SelectBranchOffice from './components/selectBranchOffice'
import SelectExams from './components/selectExams'
import SelectDateBlock from './components/selectDateBlock'
import Confirmation from './components/confirmation'
import Help from './components/Help'
import SEO from '../../../components/seo'
import constants from '../../../../utils/constants.json'
import { Button, Col, Row } from 'antd'
import ScheduleSteps from './components/ScheduleSteps'
import SelectPayment from './components/SelectPayment'
import { useTranslation } from "react-i18next"
import { useLocation } from '@reach/router'

const Main = ({
  isUserLoggedIn,
  isLogged,
  setIsLogged,
  scheduleTimeState,
  setScheduleTimeState,
  branchOffice,
  setBranchOffice,
  costCenter,
  setCostCenter,
  documentNumber,
  setDocumentNumber,
  documentType,
  setDocumentType,
  phone,
  setPhone,
  cartState,
  setCart,
  countryCode,
}) => {
  const { t } = useTranslation();
  const search = new URLSearchParams(useLocation().search);
  const [queryParams, setQueryParams] = useState({});
  const { default_phone_cl, default_phone_pe } = constants;

  useEffect(() => {
    if (search && search.size > 0) {
      setQueryParams({
        branchOfficeId: parseInt(search.get('branchOfficeId')),
        patientsQty: parseInt(search.get('patientsQty')),
        date: search.get('date'),
      });
    }
  }, []);

  useEffect(() => {
    const confirmExit = e => {
      e.stopImmediatePropagation();
      const leaveThisPage = window.confirm("¿Estás seguro que deseas abandonar la reserva?");
      if (!leaveThisPage) {
        window.history.forward();
      }
    }

    window.addEventListener("popstate", confirmExit);

    return () => {
      window.removeEventListener("popstate", confirmExit);
    }
  }, []);

  useEffect(() => {
    if (branchOffice.phone) {
      setPhone(branchOffice.phone);
      return;
    }

    if (countryCode === 'CL') {
      setPhone(default_phone_cl);
    } else {
      setPhone(default_phone_pe);
    }
  }, [branchOffice, isLogged]);

  const handleBack = () => {
    setScheduleTimeState(time => time - 1);
  };


  const schemaExtraData = [{
    "@type": "Organization",
    logo: "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    sameAs: [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador",
    ],
  }];

  return (
    <div>
      <SEO
        title={t('seo.scheduleTime.title')}
        description={t('seo.scheduleTime.description')}
        dataLayer={{
          userId: "",
          pageId: "",
          pageType: "",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Row justify="center" className='schedule-main-title'>
        <Col lg={22} xs={22} sm={22} md={22}>
          <Row>
            <Col lg={2}>
              {
                scheduleTimeState > 0 &&
                <Button className="go-back-button" size="large" onClick={handleBack} block>Atrás</Button>
              }
            </Col>
            <Col lg={20} xs={24}>
              <h1 className="text-center">
                {
                  scheduleTimeState < 4 ?
                    <>
                      {
                        scheduleTimeState === 3 ? t('scheduleTime.headerSteps.confirmTitleReservation') : t('scheduleTime.headerSteps.titleReservation')
                      }
                    </>
                    :
                    t('scheduleTime.headerSteps.titlePayment')
                }
              </h1>
            </Col>
          </Row>
        </Col>
        {
          scheduleTimeState < 3 &&
          <ScheduleSteps scheduleTimeState={scheduleTimeState} />
        }
      </Row>
      {
        scheduleTimeState === 0 &&
        <SelectBranchOffice
          scheduleTimeState={scheduleTimeState}
          setScheduleTimeState={setScheduleTimeState}
          setBranchOffice={setBranchOffice}
          branchOffice={branchOffice}
          documentNumber={documentNumber}
          setDocumentNumber={setDocumentNumber}
          costCenter={costCenter}
          setCostCenter={setCostCenter}
          documentType={documentType}
          setDocumentType={setDocumentType}
          countryCode={countryCode}
          cartState={cartState}
          setCart={setCart}
          queryParams={queryParams}
        />
      }
      {
        scheduleTimeState === 1 &&
        <SelectExams
          branchOffice={branchOffice}
          scheduleState={setScheduleTimeState}
          countryCode={countryCode}
          cartState={cartState}
          setCart={setCart}
        />
      }
      {
        scheduleTimeState === 2 &&
        <SelectDateBlock
          branchOffice={branchOffice}
          scheduleState={setScheduleTimeState}
          cartState={cartState}
          setCart={setCart}
          queryParams={queryParams}
          countryCode={countryCode}
        />
      }
      {
        scheduleTimeState === 3 &&
        <Confirmation
          scheduleState={setScheduleTimeState}
          branchOffice={branchOffice}
          countryCode={countryCode}
          cartState={cartState}
          setCart={setCart}
          isUserLoggedIn={isUserLoggedIn}
          isLogged={isLogged}
          setIsLogged={setIsLogged}
          t={t}
        />
      }
      {
        scheduleTimeState === 4 &&
        <SelectPayment
          branchOffice={branchOffice}
          costCenter={costCenter}
          documentNumber={documentNumber}
          documentType={documentType}
          cartState={cartState}
        />
      }
      <Help phone={phone} />
    </div>
  )
}

export default Main
