import React, { useState } from 'react';
import { Row, Col, Button } from 'antd';
import BillingDataModal from './BillingDataModal';

const BillingData = ({
  isSuperUser,
  billingData,
  loading,
  countryCode,
  saveBillingData,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Row justify='center' className='company-profile__data pt-lg-80 pt-md-40 pt-sm-40 pt-xs-40'>
        <Col lg={24} xs={24}>
          <h2 className='mb-20'>Datos de facturación</h2>
        </Col>
        <Col lg={24} xs={24} className="gray-wrapper pt-20 pb-20 pl-lg-30 pl-md-20 pl-sm-20 pl-xs-20 pr-lg-30 pr-xs-20">
          <Row className='mb-10'>
            <Col lg={24} xs={24}>
              <h2 style={{ fontSize: '18px' }}>Receptor de datos de facturación</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={24} xs={24}>
              <p>Nombre contacto:
                <strong className="ml-10">{
                  billingData && billingData.name
                }</strong></p>
            </Col>
          </Row>
          <Row>
            <Col lg={24} xs={24}>
              <p>Teléfono de contacto:
                <strong className="ml-10">{
                  billingData && billingData.phone
                }</strong></p>
            </Col>
          </Row>
          <Row>
            <Col lg={24} xs={24}>
              <p className='mb-0'>Correo electrónico contacto:
                <strong className="ml-10">{
                  billingData && billingData.email.split(',').join(', ')
                }</strong></p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify='end' className='mt-40'>
        <Col xl={5} lg={7} xs={24}>
          {
            isSuperUser &&
            <Button
              onClick={() => setOpen(true)}
              className='cmt-button secondary outline'
              type='primary'
            >
              Editar datos
              <span className="icon-system-edit ml-20" />
            </Button>
          }
        </Col>
      </Row>
      <BillingDataModal
        billingData={billingData}
        saveBillingData={saveBillingData}
        loading={loading}
        countryCode={countryCode}
        setOpen={setOpen}
        open={open}
      />
    </>
  )
}

export default BillingData