import React from 'react';
import { Row, Col } from 'antd';
import { default_phone_cl, default_phone_pe } from '../../../../../utils/constants.json';

const { format } = require('rut.js')

const CompanyData = ({ currentCompany, t, countryCode }) => {
  const phoneNumber = () => {
    if (countryCode === 'CL') {
      return default_phone_cl
    } else {
      return default_phone_pe
    }
  }

  return (
    <Row justify='center' className='company-profile__data pt-lg-80 pt-md-40 pt-sm-40 pt-xs-40'>
      <Col lg={24} xs={24}>
        <h2 className='mb-20'>{currentCompany.business_name}</h2>
      </Col>
      <Col lg={24} xs={24} className="gray-wrapper pt-20 pb-20 pl-lg-30 pl-md-20 pl-sm-20 pl-xs-20 pr-lg-30 pr-xs-20">
        <Row>
          <Col>
            <p>Nombre empresa: <strong className="ml-10">{currentCompany.business_name}</strong></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>{t(`companyProfile.${currentCompany.document_type}`)}
              <strong className="ml-10">{
                (currentCompany.document_type === 'rut')
                  ? format(currentCompany.document_number)
                  : currentCompany.document_number
              }</strong></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Dirección: <strong className="ml-10">{currentCompany.address}</strong></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Nombre contacto: <strong className="ml-10">{
              (currentCompany?.contact?.length > 0)
              && currentCompany.contact[0].contact_name
            }</strong></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Teléfono de contacto: <strong className="ml-10">{
              (currentCompany?.contact?.length > 0)
              && currentCompany.contact[0].contact_phone
            }</strong></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Correo electrónico de contacto: <strong className="ml-10">{
              (currentCompany?.contact?.length > 0)
              && currentCompany.contact[0].contact_email
            }</strong></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Tipo de empresa: <strong className="ml-10">{
              currentCompany?.company_type
            }</strong></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Nombre ejecutiva/o: <strong className="ml-10">{
              currentCompany?.admin?.name
            }</strong></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>Correo ejecutiva/o: <strong className="ml-10">{
              currentCompany?.admin?.email
            }</strong></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='mb-0'>Número de teléfono:
              <strong className="ml-10">{currentCompany?.admin?.phone} </strong>
              / Soporte Clientes: <strong className="ml-10">{phoneNumber()}</strong>
            </p>
          </Col>
        </Row>

      </Col>
    </Row>
  )
}

export default CompanyData