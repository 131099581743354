import React, { useEffect, useState } from 'react'
import { Row, Col, Select, Form, Radio, Input } from 'antd'
import { DocumentCard, PhoneInput } from 'cmt-ui-kit';
import evaluationTypes from '../../../../../utils/evaluationType.json';

const { Option } = Select

const WithoutPatientForm = ({ index, services, initializeForm, countryCode }) => {
  const [documentType, setDocumentType] = useState(countryCode && countryCode.toUpperCase() === 'CL' ? 'rut' : 'dni');
  const [form] = Form.useForm();

  useEffect(() => {
    initializeForm(form)
    form.setFieldsValue({ documentType });
  }, [])

  const selectServices = () => {
    const exams = []
    for (let service of services) {
      exams.push(
        <Option key={service.id} value={service.id}>
          {service.name}
        </Option>
      )
    }
    return exams
  }

  return (
    <React.Fragment>
      <Col lg={24} xs={24} key={index} className="gray-patients-row mb-10 pt-20 pb-lg-20 pb-xs-0 pl-lg-30 pl-md-15 pl-xs-15 pr-lg-30 pr-md-15 pr-xs-15">
        <Form form={form} layout="vertical">
          <Row gutter={20}>
            <Col lg={4} xs={24}>
              <Row justify="start">
                <Col lg={{ order: 1, span: 16 }} xs={{ order: 2, span: 26 }}>
                  <p>Paciente {index}</p>
                </Col>
                <Col lg={{ order: 2, span: 5 }} xs={{ order: 1, span: 2 }}>
                  <span className='icon-system-user' />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={20} className="mb-10">
            <Col lg={10} xs={24} className="mt-10">
              <Form.Item
                name="services"
                label="Prestaciones"
                rules={[
                  { required: true, message: 'Por favor, seleccione al menos una prestación del paciente' }
                ]}
              >
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Seleccionar prestación"
                  className='cmt-select'
                >
                  {selectServices()}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={14} xs={24}>
              <Row justify="end" gutter={20} className="mt-10">
                <Col lg={12} xs={24}>
                  <p className='custom-label'>Tipo y N° de documento</p>
                  <DocumentCard
                    countryCode={countryCode}
                    setDocumentType={setDocumentType}
                    form={form}
                    type='person'
                  />
                </Col>
                <Col lg={12} xs={24}>
                  <PhoneInput.ReactPhone
                    countryCode={countryCode}
                    form={form}
                    optional={true}
                    labelText='Teléfono (opcional)'
                  />
                </Col>
                {
                  countryCode === 'PE' &&
                  <>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        form={form}
                        name='firstName'
                        required={true}
                      >
                        <Input
                          placeholder='Nombres'
                          id='firstName'
                          className='cmt-input'
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        form={form}
                        name='lastName'
                        required={true}
                      >
                        <Input
                          placeholder='Apellidos'
                          id='lastName'
                          className="cmt-input"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        form={form}
                        name='email'
                      >
                        <Input
                          placeholder='Correo electrónico'
                          id='email'
                          className='cmt-input'
                        />
                      </Form.Item>
                    </Col>
                  </>
                }
              </Row>
              {
                countryCode === 'PE' &&
                <Row gutter={[0, 10]}>
                  <Col lg={24} xs={24} className="mb-10">
                    <Form name='evaluation_type'>
                      <Radio.Group
                        id='evaluation_type'
                        className='cmt-radio'
                      >
                        {
                          evaluationTypes.map((type) =>
                            <Radio value={type.value}>{type.text}</Radio>
                          )
                        }
                      </Radio.Group>
                    </Form>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
        </Form>
      </Col>
    </React.Fragment>
  )
}

export default WithoutPatientForm