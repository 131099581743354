import config from "../config";

const generalInformation = async (params) => {
  const url = new URL(`${config.client_api}/dashboard/generalData`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const dataResponse = response.json();
  return dataResponse;
};

const businessAccount = async () => {
  const url = `https://run.mocky.io/v3/822919ac-e26f-456e-84ef-c90ec03ca409`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  });
  const dataResponse = response.json();
  return dataResponse;
};

const byBranchOffice = async (params) => {
  const url = new URL(`${config.client_api}/dashboard/branches`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const dataResponse = response.json();
  return dataResponse;
};

const byBattery = async (params) => {
  const url = new URL(`${config.client_api}/dashboard/batteries`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const dataResponse = response.json();
  return dataResponse;
};

const batteryList = async (params) => {
  const url = new URL(`${config.client_api}/dashboard/batteries`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const dataResponse = response.json();
  return dataResponse;
};

const byAgeRange = async (params) => {
  const url = new URL(`${config.client_api}/dashboard/ages`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const dataResponse = response.json();
  return dataResponse;
};

const byGenderRange = async (params) => {
  const url = new URL(`${config.client_api}/dashboard/gender`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const dataResponse = response.json();
  return dataResponse;
};

const download = (branchOffices, params) => {
  const url = new URL(`${config.admin_url}/agreements/details`);
  url.searchParams.append('report_type', 'agreementsDetails');
  url.searchParams.append('company_id[]', params.company);
  url.searchParams.append('filters[date_start]', params.startDate);
  url.searchParams.append('filters[date_stop]', params.endDate);

  for (let branchOffice of branchOffices) {
    url.searchParams.append('filters[branch_office_ids][]', branchOffice.id);
  }

  window.open(url, "_blank");
};

const getExcelData = async (params) => {
  const url = new URL(`${config.client_api}/dashboard/generateResultsExcel`);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const dataResponse = response.json();
  return dataResponse;
};

export const dashboardService = {
  generalInformation,
  businessAccount,
  byBranchOffice,
  byBattery,
  batteryList,
  byAgeRange,
  byGenderRange,
  download,
  getExcelData,
};
