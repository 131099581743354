import React, { Fragment, useEffect, useState } from "react"
import { useParams } from '@reach/router'
import {
  Row,
  Col,
  Spin,
  Button,
  message
} from "antd"
import { handlers } from "../../../helpers/handlers"
import { reevaluationService } from "../../../services/reevaluation"
import ReevaluationFirstStep from "./components/reevaluationFirstStep"
import ReevaluationSecondStep from "./components/reevaluationSecondStep"
import ReevaluationThirdStep from "./components/reevaluationThirdStep"
import { useTranslation } from "react-i18next"


const PatientReevaluationPage = () => {
  const [loading, setLoading] = useState({
    fetch: false,
    download: false,
    sendDocument: false,
    sendFakeDocument: false,
    deleteDocument: false
  })
  const [reevaluationData, setReevaluationData] = useState()
  const [isReevaluationFinished, setIsReevaluationFinished] = useState()
  const { medicalConsultationId } = useParams()
  const { t } = useTranslation()

  const fetchReevaluationData = async (params) => {
    try {
      setLoading(loading => { return { ...loading, fetch: true } })
      const response = await reevaluationService.getPatientPrescriptionDetail(params);
      setReevaluationData(response);
    } catch (error) {
      console.error('Error interno:', error);
      message.error({
        content: `Error al obtener la información de la reevaluación. Inténtalo nuevamente`,
        style: {
          marginTop: '40vh'
        }
      });
    } finally {
      setLoading(loading => { return { ...loading, fetch: false } });
    }
  }

  const fetchIsReevaluationFinished = async params => {
    setLoading(loading => { return { ...loading, fetch: true } })
    const response = await reevaluationService.getIsReevaluationFinished(params)
    setIsReevaluationFinished({ is_finish: response.is_finish })
    setLoading(loading => { return { ...loading, fetch: false } })
  }

  useEffect(() => {
    fetchIsReevaluationFinished({ medicalConsultationId })
  }, [medicalConsultationId])

  useEffect(() => {
    if (isReevaluationFinished) {
      fetchReevaluationData({ medical_consultation_detail_id: medicalConsultationId })
      if (isReevaluationFinished.is_finish === false) {
        const patientDocuments = reevaluationData?.extra_data?.map(item => item?.documents).flat();
        if (patientDocuments) {
          window.addEventListener('beforeunload', confirmRefreshPage)

          return () => {
            window.removeEventListener('beforeunload', confirmRefreshPage)
          }
        }
      }
    }
  }, [isReevaluationFinished])


  const downloadPrescription = (medicalPrescriptionId) => {
    setLoading(loading => { return { ...loading, download: true } })

    reevaluationService
      .getAsset({ id: medicalPrescriptionId, type: 'MedicalPrescription', field: 'document' })
      .then(response => {
        handlers.urlDownloadAndOpen(response.document_url, 'Prescripcion.pdf');
        message.success({
          content: 'Prescripción descargada exitosamente',
          style: {
            marginTop: '40vh'
          }
        })
      }).catch(err => {
        console.error('Error interno:', err)
        message.error({
          content: `Error interno: ${err}. Inténtalo nuevamente`,
          style: {
            marginTop: '40vh'
          }
        })
      }).finally(() => {
        setLoading(loading => { return { ...loading, download: false } })
      })
  }

  const confirmRefreshPage = (event) => {
    event.preventDefault()

    const message = "¿Deseas volver a cargar el sitio?"

    return event.returnValue = message
  }


  return (
    <Row justify="center" className="patient-reevaluation-page pt-50 pb-80">
      <Col lg={24} xs={24}>
        <Row justify="center">
          <Col lg={24} xs={24}>
            <Row justify="center">
              <Col lg={24} xs={24}>
                <h1 className="text-center mb-50">{t('patientReevaluation.reevaluationPage.reevaluationTitle')}</h1>
              </Col>
              <Col lg={24} xs={24}>
                <p className="text-center bold mb-5">{t('patientReevaluation.reevaluationPage.reevaluationInfoText1')}</p>
                <Row justify="center">
                  <Col xxl={5} xl={5} lg={6} xs={24}>
                    <Button
                      loading={loading.download}
                      onClick={() => downloadPrescription(reevaluationData['medical_prescription_id'])}
                      className="cmt-button primary non-outline p-0"
                      type="primary"
                      size="large"
                      style={{
                        boxShadow: 'none',
                        fontFamily: 'MarkProBold',
                        fontSize: 20
                      }}
                    >
                      Descargar aquí
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col lg={24} xs={24} className="mt-30 mb-50">
                <p className="text-header text-center">{t('patientReevaluation.reevaluationPage.reevaluationInfoText2')}</p>
              </Col>
            </Row>
          </Col>
          {
            loading.fetch ?
              <Spin className="custom-spinner mt-40" />
              :
              reevaluationData && isReevaluationFinished &&
              <Fragment>
                <Col lg={24} xs={24}>
                  {
                    !isReevaluationFinished.is_finish &&
                    <ReevaluationFirstStep
                      loading={loading}
                      setLoading={setLoading}
                      reevaluationData={reevaluationData}
                      setReevaluationData={setReevaluationData}
                      medicalConsultationId={medicalConsultationId}
                    />
                  }

                </Col>
                <Col lg={24} xs={24}>
                  {
                    !isReevaluationFinished.is_finish &&
                    <ReevaluationSecondStep
                      reevaluationData={reevaluationData}
                    />
                  }
                </Col>
                <Col lg={24} xs={24}>
                  <ReevaluationThirdStep
                    isFinish={isReevaluationFinished.is_finish}
                  />
                </Col>
              </Fragment>
          }
        </Row>
      </Col>
    </Row>
  )
}

export default PatientReevaluationPage;
