import { Col, Radio, Row, Tooltip } from 'antd';
import React from 'react';
import { getNumberFormatted } from '../../../../helpers/handlers';
import { isMobile } from "react-device-detect"

const RadioContainers = ({ title, onChangeRadio, services, list, radioBatteryData }) => {
  return (
    <Col
      lg={11} md={16} sm={18} xs={23}
      className={"mt-lg-0 mt-md-20 mt-sm-20 mt-xs-20"}
    >
      <Row>
        <Col lg={22} xs={24}>
          <h3 className="mb-0">{title}</h3>
        </Col>
        <Col lg={22} xs={24}>
          <Radio.Group onChange={e => onChangeRadio(e.target.value)} value={radioBatteryData}>
            <Row gutter={24}>
              {
                services.map(service =>
                  <Col lg={24} xs={24} key={service.id} className="mt-20">
                    <Row>
                      <Radio.Button
                        disabled={list.some(item => item.id === service.id)}
                        value={service}
                        className='radio-item-button'
                      >
                        <Row justify='space-between'>
                          <Col style={{ fontSize: 13 }}>
                            {service.battery_name}
                          </Col>
                          <Col className='price-tag' style={{ fontSize: 13 }}>
                            {
                              getNumberFormatted({
                                countryCode: service.locate_code,
                                currencyCode: service.currency_code,
                                amount: service.value
                              })
                            }
                          </Col>
                        </Row>
                      </Radio.Button>
                      {
                        service.service_description &&
                        <Tooltip
                          title={
                            <Row>
                              <Col lg={24} xs={24}>
                                <Row align='middle'>
                                  <Col>
                                    <span className='icon-alert-rounded mr-10' />
                                  </Col>
                                  <Col>
                                    <p className='info-text mb-0'>Información</p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={24} xs={24}>
                                <p dangerouslySetInnerHTML={{ __html: service.service_description }} />
                              </Col>
                            </Row>
                          }
                          placement='right'
                          overlayClassName='service-description-tooltip'
                          autoAdjustOverflow
                          color="#FFFFFF"
                          trigger={isMobile ? 'click' : 'hover'}
                        >
                          <span className='icon-alert-rounded ml-10' style={{ fontSize: 20 }} />
                        </Tooltip>
                      }
                    </Row>
                  </Col>
                )
              }
            </Row>
          </Radio.Group>
        </Col>
      </Row>
    </Col>
  )
};

export default RadioContainers;