import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Tag, Tooltip } from 'antd'
import PatientForm from './patientForm'
import parsePhoneNumber from 'libphonenumber-js'
import { useTranslation } from "react-i18next"
import { isMobile } from "react-device-detect"
import { findIndex } from 'lodash';

const SelectExams = ({
  branchOffice,
  scheduleState,
  cartState,
  setCart,
  countryCode,
}) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(true);

  const validatePatients = () => {
    let result = true;

    result = cartState.patients.every(patient => patient.services.length > 0);

    result = result && cartState.patients.every(patient => {
      let subResult = true;

      if (patient.phone) {
        if (patient.phone.length > 5) {
          if (!parsePhoneNumber(patient.phone).isPossible()) {
            subResult = false;
          }
        } else {
          patient.phone = null;
        }
      }

      if (countryCode === 'PE') {
        subResult = subResult && Object.keys(patient).every((key) => (patient[key] || patient[key] === 0) || ['date', 'block', 'block_id'].includes(key));
      }

      return subResult;
    });


    return result;
  };

  const handleClose = (number, service_index) => {
    setCart(state => {
      const patients = [...state.patients];
      const [service] = patients[number].services.splice(service_index, 1);

      if (service.attached_services) {
        service.attached_services.forEach(attachedService => {
          const index = findIndex(patients[number].services, (item) => item.id === attachedService.id && item.type === attachedService.type);

          if (index !== -1) {
            const [deletedService] = patients[number].services.splice(index, 1);
            patients[number].amount -= deletedService.value;
            state.total_amount = state.total_amount - deletedService.value

            if (deletedService.attached_services && deletedService.attached_services.length > 0) {
              deletedService.attached_services.forEach(attachedServiceToDelete => {
                const index = findIndex(patients[number].services, (item) => item.id === attachedServiceToDelete.id && item.type === attachedServiceToDelete.type);
                if (index !== -1) {
                  const [attachedDeletedService] = patients[number].services.splice(index, 1);
                  patients[number].amount -= attachedDeletedService.value;
                  state.total_amount = state.total_amount - attachedDeletedService.value
                }
              })
            }
          }
        })
      }

      patients[number].amount -= service.value;
      return {
        ...state,
        patients,
        total_amount: state.total_amount - service.value,
      }
    });
  };


  const handleClone = (number) => {
    setCart(state => {
      const patients = [...state.patients];
      patients[number].services = [...patients[number - 1].services];
      patients[number].amount = patients[number - 1].amount;
      return {
        ...state,
        total_amount: state.total_amount + patients[number - 1].amount,
        patients,
      };
    });
  };

  const handleChangeStateNext = () => {
    if (validatePatients()) {
      scheduleState(2);
      if (window.hj) {
        window.hj('event', 'reservations-step3')
      }
    }
  };

  useEffect(() => {
    setDisabled(!validatePatients());
  }, [cartState]);


  return (
    <Row justify="center" className="select-exams-container">
      <Col lg={22} xs={22}>
        <Row justify="center" className="mt-50 mb-60">
          <Col lg={20} className="mb-10">
            <h2 className="text-center">
              {t('scheduleTime.selectExams.patientServicesTitle')}
            </h2>
          </Col>
          <Col lg={21}>
            <p
              className="disclaimer-text"
              dangerouslySetInnerHTML={{ __html: t('scheduleTime.selectExams.disclaimerText') }}
            />
          </Col>
          <Col lg={21}>
            {
              (cartState && cartState.patients) && cartState.patients.map(patient =>
                <Row
                  key={patient.number}
                  justify="space-around"
                  align="stretch"
                  className="patient-gray-row mt-10"
                >
                  <Col lg={{ order: 1, span: 4 }} xs={{ order: 1, span: 24 }}>
                    <Row>
                      <Col
                        lg={24} xs={24}
                        className='pl-lg-20 pl-md-15 pt-lg-15 pt-md-10 pt-xs-10'
                      >
                        <Row gutter={15}>
                          <Col className="patient-number-wrapper">
                            <p>{t('scheduleTime.selectExams.patientTitle')} {patient.number + 1}</p>
                          </Col>
                          <Col className="patient-icon-wrapper">
                            <span className='icon-system-user' />
                          </Col>
                        </Row>
                      </Col>
                      {
                        patient.services.length > 0 &&
                        <Col
                          lg={{ order: 3, span: 24 }}
                          xs={{ order: 2, span: 24 }}
                          className='pl-lg-20 pl-md-15 pt-lg-10'
                        >
                          <Row className="exam-gray-row">
                            <Col
                              lg={24} xs={24}
                              className='patient-number-wrapper'
                            >
                              <h4 className="mb-5">
                                {t('scheduleTime.selectExams.servicesTitle')}
                              </h4>
                            </Col>
                            <Col lg={24} xs={24} className="exams-tag-container">
                              <div className="exams-tag-wrapper pt-md-10 pt-sm-0 pt-xs-0 pb-10">
                                {
                                  patient.services.map((service, i) =>
                                    <Tag
                                      key={i}
                                      visible={true}
                                      closable={false}
                                      className="mb-5 exam-tag highlighter"
                                    >
                                      <Row align='middle' gutter={5}>
                                        <Col lg={17}>
                                          <p className='mb-0'>
                                            {service.battery_name}
                                          </p>
                                        </Col>
                                        <Col lg={4} className='icon-wrapper'>
                                          {
                                            service.service_description &&
                                            <Tooltip
                                              title={
                                                <div className="p-20">
                                                  <Row>
                                                    <Col lg={24} xs={24}>
                                                      <Row align='middle'>
                                                        <Col>
                                                          <span className='icon-alert-rounded mr-10' />
                                                        </Col>
                                                        <Col>
                                                          <p className='info-text mb-0'>Información</p>
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                    <Col lg={24} xs={24}>
                                                      <p dangerouslySetInnerHTML={{ __html: service.service_description }} />
                                                    </Col>
                                                  </Row>
                                                </div>
                                              }
                                              placement='right'
                                              overlayClassName='service-description-tooltip'
                                              autoAdjustOverflow
                                              color="#FFFFFF"
                                              trigger={isMobile ? 'click' : 'hover'}
                                            >
                                              <span className='icon-alert-rounded' />
                                            </Tooltip>
                                          }
                                        </Col>
                                        {
                                          !service.attached_service_id &&
                                          <Col lg={3} className='icon-wrapper'>
                                            <span className="icon-system-close" onClick={() => handleClose(patient.number, i)} />
                                          </Col>
                                        }
                                      </Row>
                                    </Tag>
                                  )
                                }
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      }
                      {
                        (patient.number > 0 && cartState.patients[patient.number - 1].services.length > 0 && patient.services.length === 0) &&
                        <Col lg={{ order: 4, span: 24 }} xs={{ order: 3, span: 24 }}>
                          <Row justify="space-around" className="exam-gray-row">
                            <Col lg={19}>
                              <Row>
                                <Col className="mt-xs-0">
                                  <Button
                                    className="cmt-button secondary outline primary repeat-exams-button"
                                    type='primary'
                                    onClick={() => handleClone(patient.number)}
                                  >
                                    {t('scheduleTime.selectExams.repeatExams')}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      }
                    </Row>
                  </Col>
                  <Col lg={{ order: 2 }} className='div-separator' />
                  <Col
                    lg={{ order: 3, span: 19 }}
                    xs={{ order: 4, span: 24 }}
                    className='patient-form-wrapper'
                  >
                    <PatientForm
                      patient={patient}
                      branchOffice={branchOffice}
                      setCart={setCart}
                      countryCode={countryCode}
                    />
                  </Col>
                </Row>
              )
            }
          </Col>
        </Row>
        <Row justify="end" className="next-button-container mb-40" >
          <Col lg={3} md={16} sm={23} xs={24}>
            <Button
              size="large"
              className="next-button reservations-step3"
              onClick={handleChangeStateNext}
              disabled={disabled}
              block
            >
              Siguiente
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

export default SelectExams;
