import React, { useState } from "react";
import { navigate } from "gatsby";
import "../css/login.less";
import { Row, Col, Layout, Form, Button, Input, message } from "antd";
import { usePermify } from '@permify/react-role';
import { isLoggedIn, login, setAuth } from "../services/auth";
import RecoveryPasswordForm from "./RecoveryPasswordForm";
import { useLocation } from "@reach/router";
import SEO from "./seo";
import { useTranslation } from "react-i18next";
import { validate } from '../../utils/captcha';

const { Content } = Layout;

const LoginForm = ({ checkIfUserIsLogged, recaptchaRef }) => {
  const [showLogin, setShowLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { setUser } = usePermify();

  const redirectValidator = (redirect) => {
    const url = new URL(redirect, window?.location?.origin);
    if (url.host === window?.location?.host) {
      return true;
    } else {
      console.error('Error: Intento de redireccionamiento a un sitio externo:', redirect);
      message.error({
        content: "Error: Intento de redireccionamiento a un sitio externo",
        style: {
          marginTop: "40vh",
        },
      });
      return false;
    }
  }

  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (params.get("redirect") && !redirectValidator(params.get("redirect"))) {
        return;
      }
      if (!(await validate(recaptchaRef))) {
        throw new Error('Captcha error')
      }
      const data = await login(values);
      if (data.code === 200 && isLoggedIn) {
        setAuth(data.user, setUser);
        if (checkIfUserIsLogged) {
          checkIfUserIsLogged();
        } else {
          if (params.get("redirect")) {
            navigate(params.get("redirect"));
            return;
          }
          navigate(-1);
          return;
        }
      } else {
        message.error({
          content: "Email o contraseña incorrectos. Inténtalo de nuevo.",
          style: {
            marginTop: "40vh",
          },
        });
      }
    } catch (err) {
      console.error(`Error en Login: ${err}`);
      message.error({
        content: `Error en Login: ${err}`,
        style: {
          marginTop: "40vh",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const schemaExtraData = [{
    "@type": "Organization",
    logo: "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    sameAs: [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador",
    ],
  }];

  return (
    <>
      {showLogin ? (
        <Content style={{ marginTop: -16 }}>
          <SEO
            title={t('seo.loginForm.title')}
            description={t('seo.loginForm.description')}
            dataLayer={{
              userId: "",
              pageId: "",
              pageType: "",
              category: "",
            }}
            schemaExtraData={schemaExtraData}
          />
          <Row justify="center" className="login-container pt-lg-40 mt-lg-0 mt-xs-40 pb-lg-40">
            <Col
              lg={10}
              md={24}
              sm={24}
              xs={24}
              className="login-container-element pt-lg-40"
            >
              <Row justify="center">
                <Col lg={22} md={12} sm={15} xs={22}>
                  <Row justify="center">
                    <Col lg={24} className="mt-lg-0 mt-md-20 mt-sm-20 mt-xs-20">
                      <h1 className="text-center">{t('login.title')}</h1>
                    </Col>
                  </Row>
                  {params.get("from") && (
                    <Row justify="center">
                      <Col xxl={19} lg={21} xs={24} className="alert-wrapper">
                        <Row gutter={10} align="middle">
                          <Col lg={1} xs={2} className="icon-wrapper">
                            <span className="icon-alert-square" />
                          </Col>
                          <Col lg={23} xs={22}>
                            <p>
                              A partir del 16 de noviembre, podrás ver y
                              descargar informes de resultados, solo estando
                              dentro del Perfil de Usuario - después de Iniciar
                              Sesión -. Por esto, solicitamos que actualices tu
                              perfil de usuario y contraseña. Agradecemos tu
                              comprensión y cooperación en este proceso. En el
                              caso de no tener una cuenta, solicitar resultados
                              al usuario que generó la reserva.
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  <Row justify="center">
                    <Col
                      lg={18}
                      md={24}
                      sm={24}
                      xs={24}
                      className="mt-50 pb-lg-50"
                    >
                      <Form form={form} onFinish={onFinish} size="large">
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, ingresa tu email.",
                            },
                          ]}
                        >
                          <Input
                            type="email"
                            placeholder="Correo electrónico"
                            size="large"
                            className="cmt-input"
                          />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Por favor, ingresa tu contraseña.",
                            },
                          ]}
                        >
                          <Input.Password
                            type="password"
                            placeholder="Contraseña"
                            size="large"
                            className="cmt-input"
                          />
                        </Form.Item>
                        <Row justify="center">
                          <Col lg={24}>
                            <h2 className="text-center">
                              <Button
                                className="cmt-button primary non-outline"
                                type="link"
                                onClick={() => {
                                  setShowLogin(false);
                                }}
                              >
                                ¿Olvidaste tu contraseña?
                              </Button>
                            </h2>
                          </Col>
                        </Row>
                        <Row justify="center">
                          <Col
                            lg={24}
                            xs={24}
                            className="mt-lg-20 mt-md-30 mt-sm-30 mt-xs-30"
                          >
                            <Form.Item>
                              <Button
                                loading={loading}
                                size="large"
                                className="cmt-button primary"
                                type="primary"
                                htmlType="submit"
                                block
                              >
                                Ingresar
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      ) : (
        <RecoveryPasswordForm
          email={form.getFieldValue("email")}
          setShowLogin={setShowLogin}
          recaptchaRef={recaptchaRef}
        />
      )}
    </>
  );
};

export default LoginForm;
