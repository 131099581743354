import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { HasAccess } from '@permify/react-role';
import PrivateLayout from '../components/PrivateLayout';
import ErrorPage from '../components/errorPage';
import { isLoggedIn } from '../services/auth';

const PrivateRoute = ({
  component: Component,
  location,
  requiredPermissions,
  ...rest
}) => {

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!isLoggedIn() && location.pathname !== '/app/login/') {
        navigate(`/app/login/?redirect=${location.pathname}`);
      }
    }
  }, [location.pathname]);

  if (typeof window !== 'undefined' && isLoggedIn()) {
    return (
      <PrivateLayout>
      {
        !requiredPermissions ?
          <Component {...rest} />
        :
          <HasAccess
            permissions={requiredPermissions}
            renderAuthFailed={
              <ErrorPage
                code={403}
              />
            }
          >
            <Component {...rest} />
          </HasAccess>
      }
      </PrivateLayout>
    );
  } else {
    return null;
  }
};

export default PrivateRoute;