import { Row, Col, Radio, Empty } from 'antd';
import moment from 'moment';

const TableExpirations = ({
  filters,
  tabName,
  tableData,
  pagination,
  expirationPage,
  setExpirationPage,
  fetchExpirations,
  handleTabPaneName,
}) => {

  const textInfo = (tabName) => {
    switch (tabName) {
      case 'critical':
        return 'Vencimiento en los siguientes 30 días';
      case 'warning':
        return 'Vencimiento entre 30 y 60 días';
      case 'normal':
        return 'Vencimiento entre 60 y 90 días';
      case 'expired':
        return 'Vencidos según fecha seleccionada';
      case 'observed':
        return 'Trabajadores con observaciones a levantar';
      default:
        return '';
    };
  };

  const formatDate = (date) => {
    return moment(date).format('DD-MM-YYYY');
  };

  const customTableHeader = () => {
    return (
      <Row className="cmt-custom-table-header">
        <Col
          lg={4}
          className="align-item-center-start p-10"
        >
          <h3>Nombre Trabajador</h3>
        </Col>
        <Col
          lg={2}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Edad
          </h3>
        </Col>
        <Col
          lg={3}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Ocupación
          </h3>
        </Col>
        <Col
          lg={3}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Tipo de Atención
          </h3>
        </Col>
        <Col
          lg={2}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Estado de Aptitud
          </h3>
        </Col>
        <Col
          lg={2}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Fecha Atención
          </h3>
        </Col>
        <Col
          lg={2}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Fecha Vencimiento
          </h3>
        </Col>
        <Col
          lg={6}
          className="align-item-center-start p-10"
        >
          <h3 className="text-center">
            Recomendaciones
          </h3>
        </Col>
      </Row>
    );
  };

  const customTableBody = (data, index) => {
    return (
      <Row
        key={index}
        className={`cmt-custom-table-body ${index % 2 !== 0 && 'white-background'}`}
      >
        <Col
          lg={4}
          xs={12}
          className="align-item-center p-10"
        >
          <h3 className="hide-desktop text-center">Nombre Trabajador</h3>
          <p className="mb-0">{data.patient_name}</p>
        </Col>
        <Col
          lg={2}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">Edad</h3>
          <p className='mb-0 text-center'>{data.patient_age}</p>
        </Col>
        <Col
          lg={3}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">Ocupación</h3>
          <p className='mb-0 text-center'>{data.patient_profession}</p>
        </Col>
        <Col
          lg={3}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">Tipo de Atención</h3>
          <p className='mb-0 text-center'>{data.evaluation_type}</p>
        </Col>
        <Col
          lg={2}
          xs={12}
          className="center-item p-0"
        >
          <h3 className="hide-desktop text-center">Estado de Aptitud</h3>
          <p className='mb-0 text-center'>{data.consultation_result}</p>
        </Col>
        <Col
          lg={2}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">Fecha Atención</h3>
          <p className='mb-0 text-center'>{formatDate(data.attention_date)}</p>
        </Col>
        <Col
          lg={2}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">Fecha Atención</h3>
          <p className='mb-0 text-center'>{formatDate(data.expiration_date)}</p>
        </Col>
        <Col
          lg={6}
          xs={12}
          className="align-item-center p-10"
        >
          <h3 className="hide-desktop text-center">Recomendaciones</h3>
          <p className='mb-0 text-center'>{data.indications}</p>
        </Col>
      </Row>
    );
  };

  const handlePagination = (direction, currentPage) => {
    const currentTabName = tabName;
    const value = direction === 'next' ? currentPage + 1 : currentPage - 1;
    setExpirationPage(expirationPage => ({ ...expirationPage, [currentTabName]: value }));
    fetchExpirations(filters, currentTabName, value);
  };


  return (
    <Row gutter={[0, 20]} className='mt-20 mb-100'>
      {
        tableData?.length > 0 ?
          <>
            <Col lg={24} xs={24}>
              <p className='text-info'>{textInfo(tabName)}</p>
            </Col>
            <Col lg={24} xs={24}>
              {
                customTableHeader()
              }
              {
                tableData.map((item, index) => {
                  return (
                    customTableBody(item, index)
                  )
                })
              }
            </Col>
            <Col lg={24} xs={24}>
              <Row align='middle' gutter={10} className='pagination-wrapper'>
                <Col>
                  <Radio.Group className="cmt-pagination">
                    <Radio.Button
                      value="prev"
                      disabled={expirationPage[tabName] === 1 || !pagination.prevPage}
                      onClick={(event) => handlePagination(event.target.value, expirationPage[tabName])}
                    >
                      Anterior
                    </Radio.Button>
                    <Radio.Button
                      value="next"
                      disabled={expirationPage[tabName] === pagination.lastPage || tableData.length < pagination.perPage}
                      onClick={(event) => handlePagination(event.target.value, expirationPage[tabName])}
                    >
                      Siguiente
                    </Radio.Button>
                  </Radio.Group>
                </Col>
                <Col>
                  <p className='mb-0'>{pagination.currentPage} de {pagination.lastPage}</p>
                </Col>
              </Row>
            </Col>
          </>
          :
          <Col lg={24} xs={24}>
            <Row justify='center' className='mt-40'>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`Sin datos sobre ${handleTabPaneName(tabName)}`}
              />
            </Row>
          </Col>
      }
    </Row>
  );
};

export default TableExpirations;