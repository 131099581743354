import { Row, Col, Tabs } from 'antd';
import ByBranchOfficeTable from './ByBranchOfficeTable';
import ByTypeOfCare from './ByTypeOfCare';
import AgeGenderChart from './AgeGenderChart';
import CIE10Reports from './CIE10Reports';

const { TabPane } = Tabs;

const MedicalCare = ({
  t,
  loading,
  filters,
  dataMedical,
  dataCIE10,
  cie10Page,
  setCIE10Page,
  fetchCIE10Reports,
  }) => {

  return (
    <Row className='medical-care-wrapper mt-50 mb-90' gutter={[0, 95]}>
      <Col lg={24} xs={24}>
        <Tabs className='cmt-tabs' defaultActiveKey='1'>
          <TabPane key='1' tab={<p className='mb-0'>General</p>}>
            <ByBranchOfficeTable
              t={t}
              loading={loading.byBranchOffice}
              data={dataMedical.byBranchOffice}
            />
            <AgeGenderChart
              loading={loading.byAgeGender}
              data={dataMedical}
            />
            <ByTypeOfCare
              loading={loading.byCareType}
              data={dataMedical.byCareType[0]}
            />
          </TabPane>
        </Tabs>
      </Col>
      <Col lg={24} xs={24} className='cie10-wrapper'>
        <h3 className="title">Reporte general CIE 10</h3>
        <CIE10Reports
          loading={loading}
          filters={filters}
          dataCie10={dataCIE10}
          cie10Page={cie10Page}
          setCIE10Page={setCIE10Page}
          fetchCIE10Reports={fetchCIE10Reports}
        />
      </Col>
    </Row>
  );
};

export default MedicalCare;