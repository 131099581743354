import { HasAccess } from '@permify/react-role';
import React, { useEffect, useState } from 'react';
import PrivateLayout from '../components/PrivateLayout';
import ErrorPage from '../components/errorPage';
import PublicClientsNavbar from '../components/publicClientsNavbar';
import { isLoggedIn, logout } from '../services/auth';
import { scheduleTimeService } from '../services/scheduleTime';

const ReservationRoute = ({ component: Component, requiredPermissions, location, ...rest }) => {
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;
  const [isLogged, setIsLogged] = useState(false);
  const [scheduleTimeState, setScheduleTimeState] = useState(0);
  const [branchOffice, setBranchOffice] = useState({});
  const [costCenter, setCostCenter] = useState();
  const [documentNumber, setDocumentNumber] = useState();
  const [documentType, setDocumentType] = useState(countryCode && countryCode.toUpperCase() === 'CL' ? 'rut' : 'ruc');
  const [phone, setPhone] = useState();
  const [cartState, setCart] = useState({
    total: 0,
    patients: [],
    total_amount: 0,
    currency_code: '',
    locate_code: '',
  });

  const isUserLoggedIn = async () => {
    try {
      if (isLoggedIn()) {
        await scheduleTimeService.checkAuth();
        setIsLogged(true);
      }
      return true;
    } catch (error) {
      console.error(error);
      logout();
      setIsLogged(false);
    }
    return false;
  };

  useEffect(() => {
    isUserLoggedIn();
  }, [isLogged])


  const childrenComponent = <Component {...rest}
    isUserLoggedIn={isUserLoggedIn}
    isLogged={isLogged}
    setIsLogged={setIsLogged}
    scheduleTimeState={scheduleTimeState}
    setScheduleTimeState={setScheduleTimeState}
    branchOffice={branchOffice}
    setBranchOffice={setBranchOffice}
    costCenter={costCenter}
    setCostCenter={setCostCenter}
    documentNumber={documentNumber}
    setDocumentNumber={setDocumentNumber}
    documentType={documentType}
    setDocumentType={setDocumentType}
    phone={phone}
    setPhone={setPhone}
    cartState={cartState}
    setCart={setCart}
    countryCode={countryCode}
  />;

  return <React.Fragment>
    {
      (!isLogged && !isLoggedIn()) ? (
        <PublicClientsNavbar page="reservation">
          {childrenComponent}
        </PublicClientsNavbar>
      ) : (
        <PrivateLayout page="reservation">
          <HasAccess
            permissions={requiredPermissions}
            renderAuthFailed={
              <ErrorPage
                code={403}
              />
            }
          >
            {childrenComponent}
          </HasAccess>
        </PrivateLayout>
      )
    }
  </React.Fragment>
}

export default ReservationRoute