import React, { useState, useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import "./publicClientsNavbar.less";
import { Row, Col, Layout, Affix, Popover, Button } from "antd";
import LogoCmtNaranjo2023 from "../images/LogoCmtNaranjo2023.svg";
import Info from "./Info";
import { handlers } from "../helpers/handlers";
import ChileFlag from 'cmt-ui-kit/src/images/ChileFlag.svg';
import PeruFlag from 'cmt-ui-kit/src/images/PeruFlag.svg';
import FooterPublicLayout from "./FooterPublicLayout";

const { Header, Content, Footer } = Layout;

const PublicClientsNavbar = ({ children, page }) => {
  const [affixActive, setAffixActive] = useState(false)
  const { isMobile } = handlers.useDeviceDetect();
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;
  const [currentCountry, setCurrentCountry] = useState({
    name: null,
    flag: null,
    url: null,
  });

  useEffect(() => {
    if (countryCode === 'CL') {
      setCurrentCountry(() => ({
        name: 'Chile',
        flag: ChileFlag,
        url: 'https://www.cmtsalud.cl/',
      }));
    } else {
      setCurrentCountry(() => ({
        name: 'Perú',
        flag: PeruFlag,
        url: 'https://www.cmtsalud.pe/',
      }));
    }
  }, [countryCode]);


  const countryPopoverContent = () => {
    const handleRedirect = (selectedCountryCode) => {
      if (countryCode !== selectedCountryCode) {
        window.location.replace(`https://www.cmtsalud.${selectedCountryCode.toLowerCase()}/`);
      }
    };

    return (
      <Row gutter={[0, 10]}>
        <Col lg={24}>
          <Button
            className='cmt-button disabled'
            type='primary'
            onClick={() => handleRedirect('CL')}
          >
            <img src={ChileFlag} alt={'Bandera Chile'} width='35' />
            &nbsp;Chile
          </Button>
        </Col>
        <Col lg={24}>
          <Button
            className='cmt-button disabled'
            type='primary'
            onClick={() => handleRedirect('PE')}
          >
            <img src={PeruFlag} alt={'Bandera Perú'} width='35' />
            &nbsp;Perú
          </Button>
        </Col>
      </Row>
    );
  };

  const handleAffix = (value) => {
    setAffixActive(value);
  };

  const dataQuery = useStaticQuery(
    graphql`
      query SettingsQuery {
        wpgraphql {
          settings {
            nodes {
              fields {
                country
                centralPhone
                centralEmail
                noticeEmail
                facebook
                youtube
                twitterX
                linkedin
                instagram
                collectionPayments
                frequentQuestions
                opinion
              }
            }
          }
        }
      }
    `
  );


  return (
    <Layout className={`layout-${page} affix-${affixActive}`}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Helmet>

      <Info
        data={dataQuery}
        device='device-desktop'
        isMobile={isMobile}
        splitPhone={handlers.splitPhone}
        countryCode={countryCode}
      />

      <Affix offsetTop={0} onChange={handleAffix}>
        <Header className="header-public-clients">
          <Row justify='center' align="middle" className='main-wrapper'>
            <Col xl={21} lg={22} xs={22}>
              <Row justify="space-between">
                <Col className="logo-container">
                  <Row align="middle">
                    <Col>
                      <Link to="/">
                        <img src={LogoCmtNaranjo2023} alt="CMT logo naranjo" />
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col className='country-wrapper'>
                {
                  currentCountry.name &&
                  <Popover
                    trigger='click'
                    placement='bottom'
                    overlayClassName='country-home-popover'
                    title='Selecciona país'
                    content={countryPopoverContent}
                  >
                    <Button className='cmt-button disabled' type='primary'>
                      <img
                        src={currentCountry.flag}
                        alt={currentCountry.name + 'bandera'}
                        width='35'
                      />
                      <strong>&nbsp;{currentCountry.name}</strong>
                      <span className='icon-system-chevron-down ml-5' />
                    </Button>
                  </Popover>
                }
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
      </Affix>
      <Content className="mt-20">
        <Row justify="center">
          <Col lg={24} xs={24}>
            {children}
          </Col>
        </Row>
      </Content>
      <FooterPublicLayout
        Link={Link}
        data={dataQuery && dataQuery}
        Footer={Footer}
        handlers={handlers}
        countryCode={countryCode}
      />
    </Layout>
  );
};

export default PublicClientsNavbar;
