import React from 'react';
import { Col, Steps } from "antd";
import { useTranslation } from "react-i18next";

const ScheduleSteps = ({ scheduleTimeState }) => {
  const { t } = useTranslation();

  return (
    <Col lg={15} md={24} sm={24} xs={24}>
      <Steps current={scheduleTimeState} progressDot direction="horizontal" responsive={false}>
        <Steps.Step title="Paso 1" description={t("scheduleTime.headerSteps.stepOne")} />
        <Steps.Step title="Paso 2" description={t("scheduleTime.headerSteps.stepTwo")} />
        <Steps.Step title="Paso 3" description="Fecha y hora" />
      </Steps>
    </Col>
  )
};

export default ScheduleSteps;