import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Drawer,
  Form,
  Input,
  DatePicker,
  Select,
  Tag,
  Modal,
  message,
} from "antd";
import Items from "./components/Items";
import { reservationService } from "../../../services/reservations";
import locale from "antd/es/date-picker/locale/es_ES";
import Joyride from "react-joyride";
import { isMobile } from "react-device-detect";
import { useMount } from "react-use";
import { useLocation } from "@reach/router";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Reservations = () => {
  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [branchOffice, setBranchOffice] = useState();
  const [company, setCompany] = useState();
  const [getBranchOfficesList, setGetBranchOfficesList] = useState();
  const [getCompaniesList, setGetCompaniesList] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startClientsTutorial, setStartClientsTutorial] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const countryCode = window.location.hostname === 'localhost'
    ? process.env.GATSBY_COUNTRY_VALUE
    : window.location.hostname.split('.').at(2).toUpperCase();

  let steps = [
    {
      target: ".next-reservations",
      placement: "bottom-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.reservations.stepOne.title')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.reservations.stepOne.content')}
        </p>
      ),
    },
    {
      target: ".next-detail-button",
      disableBeacon: true,
      hideCloseButton: true,
      placement: "bottom",
      title: <p className="joyride-title">{t('joyRide.reservations.stepTwo.title')}</p>,
      content: (
        <Fragment>
          <p className="joyride-content--title mb-5">{t('joyRide.reservations.stepTwo.detail')}</p>
          <p className="joyride-content">
            {t('joyRide.reservations.stepTwo.content')}
          </p>
        </Fragment>
      ),
    },
    {
      target: ".cancel-schedule",
      disableBeacon: true,
      hideCloseButton: true,
      placement: "bottom",
      title: <p className="joyride-title">{t('joyRide.reservations.stepThree.title')}</p>,
      content: (
        <Fragment>
          <p className="joyride-content--title mb-5">{t('joyRide.reservations.stepThree.detail')}</p>
          <p className="joyride-content">
            {t('joyRide.reservations.stepThree.content')}
          </p>
        </Fragment>
      ),
    },
    {
      target: ".pre-admission",
      disableBeacon: true,
      hideCloseButton: true,
      placement: "bottom",
      title: <p className="joyride-title">{t('joyRide.reservations.stepFour.title')}</p>,
      content: (
        <Fragment>
          <p className="joyride-content--title mb-5">{t('joyRide.reservations.stepFour.detail')}</p>
          <p className="joyride-content">
            {t('joyRide.reservations.stepFour.content')}
          </p>
        </Fragment>
      ),
    },
    {
      target: ".attended-reservations--title",
      placement: "bottom-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.reservations.stepFive.title')}</p>,
      content: (
        <Fragment>
          <p className="joyride-content">
            {t('joyRide.reservations.stepFive.content')}
          </p>
        </Fragment>
      ),
    },
    {
      target: ".canceled-reservations--title",
      placement: "bottom-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.reservations.stepSix.title')}</p>,
      content: (
        <Fragment>
          <p className="joyride-content">
            {t('joyRide.reservations.stepSix.content')}
          </p>
        </Fragment>
      ),
    },
    {
      target: ".search-reservation-button-wrapper",
      placement: "right",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.reservations.stepSeven.title')}</p>,
      content: (
        <Fragment>
          <p className="joyride-content">
            {t('joyRide.reservations.stepSeven.content')}
          </p>
        </Fragment>
      ),
    },
  ];

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const [form] = Form.useForm();

  const fetchBranchOffices = async (params) => {
    try {
      const response = await reservationService.getBranchOffices(params);
      if (response) {
        setGetBranchOfficesList(response);
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: `Ha ocurrido un error al obtener las ${t('reservations.list.branchOffice')}. ${error}`,
        style: {
          marginTop: '40vh'
        }
      })
    }
  };

  const fetchCompanies = async () => {
    try {
      const response = await reservationService.getCompanies();
      if (response && response.length > 0) {
        fetchBranchOffices({ companies: response.map(company => company.id) });
        setGetCompaniesList(response);
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: `Ha ocurrido un error al obtener las compañias. ${error}`,
        style: {
          marginTop: '40vh'
        }
      })
    }
  };

  useEffect(() => {
    fetchCompanies();
    const params = new URLSearchParams(location.search);
    if (params.get("from") === "login") {
      setVisible(true);
    }
  }, []);

  useMount(() => {
    const checkTutorialState = localStorage.getItem(
      "reservationTutorialDisabled"
    );

    if (checkTutorialState === "true") {
      setIsModalVisible(false);
    } else {
      if (!isMobile) {
        setIsModalVisible(true);
      }
    }
  });

  const onFinish = (values) => {
    setQuery(values.search);

    if (values.dates) {
      setStartDate(values.dates[0].format("YYYY-MM-DD"));
      setEndDate(values.dates[1].format("YYYY-MM-DD"));
    }

    setBranchOffice(values.branchOffice);
    setCompany(values.company);
    onClose();
  };

  const findSelectedBranchOffice = () => {
    const officeId = branchOffice;
    const findOffice = getBranchOfficesList.findIndex(
      (item) => item.id === officeId
    );
    const filterOffice = getBranchOfficesList[findOffice].name;
    return filterOffice;
  };

  const findSelectedCompany = () => {
    const companyId = company;
    const findCompany = getCompaniesList.findIndex(
      (item) => item.id === companyId
    );
    const filterCompany = getCompaniesList[findCompany].business_name;
    return filterCompany;
  };

  const removeReservationNumberFilter = () => {
    form.resetFields(["search"]);
    setQuery();
  };

  const removeDateFilter = () => {
    form.resetFields(["dates"]);
    setStartDate();
    setEndDate();
  };

  const removeBranchOfficeFilter = () => {
    form.resetFields(["branchOffice"]);
    setBranchOffice();
  };

  const removeCompanyFilter = () => {
    form.resetFields(["company"]);
    setCompany();
  };

  const closeModal = () => {
    setIsModalVisible(false);
    localStorage.setItem("reservationTutorialDisabled", true);
  };

  const startTutorial = () => {
    setStartClientsTutorial(true);
    setIsModalVisible(false);
  };

  const joyrideCallback = (data) => {
    const { action, index } = data;

    if (action === "skip" || action === "reset") {
      localStorage.setItem("reservationTutorialDisabled", true);
    }

    if (action === 'update') {
      const className = {
        0: 'next-reservations',
        1: 'next-detail-button',
        2: 'cancel-schedule',
        3: 'pre-admission',
        4: 'attended-reservations--title',
        5: 'canceled-reservations--title',
        6: 'search-reservation-button-wrapper',
      };
      window.scroll({ top: 0 });
      const element = document.getElementById(className[index]);
      if (element) {
        element.classList.add("animate__animated", "animate__headShake");
      }
    }
  };


  return (
    <Row
      justify="center"
      className="next-reservations-container"
      style={{ minHeight: "120vh" }}
    >
      <Joyride
        callback={joyrideCallback}
        run={startClientsTutorial}
        steps={steps}
        continuous
        showSkipButton
        disableOverlay
        disableScrolling
        styles={{
          options: {
            overlay: { height: "100vh" },
          },
        }}
        locale={{
          skip: "Omitir",
          next: "Continuar",
          last: "Finalizar",
          back: "Atrás",
        }}
      />
      <Col lg={22} md={18} sm={19} xs={22}>
        <Row
          justify="start"
          align="middle"
          className="mb-lg-60 mb-md-30 mb-sm-30 mb-xs-30"
        >
          <Col
            lg={4}
            xs={24}
            className="search-reservation-button-wrapper"
            id="search-reservation-button-wrapper"
          >
            <Button
              className="search-reservation-button"
              size="large"
              onClick={showDrawer}
              block
            >
              <span className="icon-system-search mr-10" />
              {t('reservations.list.searchReservations')}
            </Button>
          </Col>
          {query || branchOffice || startDate || endDate || company ? (
            <Col lg={19} xs={24} className="current-search-wrapper">
              <Row>
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <h4 className="ml-lg-20 ml-xs-0 mb-0 mt-lg-0 mt-md-15 mt-xs-15">
                    Filtros aplicados:
                  </h4>
                </Col>
                <Col
                  lg={18}
                  className="filter-tags-wrapper ml-lg-15 ml-xs-0 mt-lg-0 mt-md-10 mt-xs-10"
                >
                  {query && (
                    <Tag closable onClose={removeReservationNumberFilter}>
                      {query}
                    </Tag>
                  )}
                  {branchOffice && (
                    <Tag closable onClose={removeBranchOfficeFilter}>
                      {findSelectedBranchOffice()}
                    </Tag>
                  )}
                  {startDate && endDate && (
                    <Tag closable onClose={removeDateFilter}>
                      {startDate} - {endDate}
                    </Tag>
                  )}
                  {company && (
                    <Tag closable onClose={removeCompanyFilter}>
                      {findSelectedCompany()}
                    </Tag>
                  )}
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
        <Drawer
          placement="left"
          onClose={onClose}
          open={visible}
          closable={true}
          width="372"
          className="reservations-drawer"
        >
          <Row
            justify="center"
            align="middle"
            className="drawer-container mt-40"
          >
            <Col lg={17} md={15} sm={15} xs={21}>
              <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item label="Buscar" name="search">
                  <Input
                    placeholder={`N° de reserva o ${countryCode === 'CL' ? 'RUT' : 'DNI o carnet de extranjería'} del paciente`}
                    size="large"
                    className="mb-15"
                  />
                </Form.Item>
                <Form.Item label="Fecha" name="dates">
                  <RangePicker
                    locale={locale}
                    className="date-picker mb-15"
                    style={{ width: "100%" }}
                    placeholder={["Desde", "Hasta"]}
                    picker="date"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label={t('reservations.list.branchOffice')}
                  name="branchOffice"
                >
                  <Select
                    placeholder={`Seleccionar ${t('reservations.list.branchOffice').toLocaleLowerCase()}`}
                    style={{ width: "100%" }}
                    className="branch-office-select mb-15"
                    size="large"
                  >
                    {getBranchOfficesList &&
                      getBranchOfficesList.map((branchOffice, i) => {
                        return (
                          <Option value={branchOffice.id} key={i}>
                            {branchOffice.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item label="Compañía" name="company">
                  <Select
                    placeholder="Seleccionar compañía"
                    className="company-select mb-15"
                    size="large"
                  >
                    {getCompaniesList &&
                      getCompaniesList.map((company, i) => {
                        return (
                          <Option value={company.id} key={i}>
                            {company.business_name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Row justify="center">
                  <Col lg={20} xs={24} className="mt-15">
                    <Form.Item>
                      <Button
                        className="search-reservation-button"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        <span className="icon-system-search mr-10" />
                        {t('reservations.list.searchReservations')}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Drawer>
        <Row justify="start">
          <Col lg={24} xs={24}>
            <Row justify="start">
              <Col lg={24} xs={24} id="next-reservations">
                <h1 className="next-reservations">{t('reservations.list.nextReservationsTitle')}</h1>
              </Col>
            </Row>
            <div>
              <Items
                startDate={startDate}
                endDate={endDate}
                branchOffice={branchOffice}
                company={company}
                query={query}
                type={"nextReservation"}
              />
            </div>
          </Col>
        </Row>
        <Row justify="start" className="pt-50">
          <Col lg={24} xs={24}>
            <Row justify="start">
              <Col
                lg={24}
                xs={24}
                id="attended-reservations--title"
                className="attended-reservations"
              >
                <h2 className="attended-reservations--title">
                  {t('reservations.list.lastReservationsTitle')}
                </h2>
              </Col>
            </Row>
            <div>
              <Items
                startDate={startDate}
                endDate={endDate}
                branchOffice={branchOffice}
                company={company}
                query={query}
                type={"lastReservation"}
              />
            </div>
          </Col>
        </Row>
        <Row justify="start" className="pt-50 pb-100">
          <Col lg={24} xs={24} className="canceled-reservations">
            <Row justify="start">
              <Col lg={22} xs={24}>
                <h3
                  id="canceled-reservations--title"
                  className="canceled-reservations--title"
                >
                  {t('reservations.list.canceledReservationsTitle')}
                </h3>
              </Col>
            </Row>
            <div>
              <Items
                startDate={startDate}
                endDate={endDate}
                branchOffice={branchOffice}
                company={company}
                query={query}
                type={"canceledReservation"}
              />
            </div>
          </Col>
        </Row>
      </Col>
      <Modal
        open={isModalVisible}
        footer={false}
        onCancel={closeModal}
        width={900}
        closable={false}
        className="tutorial-modal"
      >
        <Row justify="center" className="mt-30 mb-30">
          <Col>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="65.232"
              height="67.972"
              viewBox="0 0 65.232 67.972"
            >
              <g
                id="Grupo_104"
                data-name="Grupo 104"
                transform="translate(-1428.735 -485.908)"
              >
                <rect
                  id="Rectángulo_103"
                  data-name="Rectángulo 103"
                  width="58.091"
                  height="48.616"
                  transform="translate(1428.735 505.264)"
                  fill="#edab94"
                />
                <path
                  id="Trazado_82"
                  data-name="Trazado 82"
                  d="M1483.759,496.958v1.983a6.5,6.5,0,1,1-13,0v-1.983h-11.67v1.983a6.5,6.5,0,1,1-13.006,0v-1.983h-10.206v48.616h58.093V496.958Z"
                  fill="#dc582a"
                />
                <path
                  id="Trazado_83"
                  data-name="Trazado 83"
                  d="M1454.6,498.273a2.094,2.094,0,1,1-4.189,0V488.005a2.094,2.094,0,1,1,4.189,0Z"
                  fill="#dc582a"
                />
                <path
                  id="Trazado_84"
                  data-name="Trazado 84"
                  d="M1479.257,498.273a2.094,2.094,0,1,1-4.188,0V488.005a2.094,2.094,0,1,1,4.188,0Z"
                  fill="#dc582a"
                />
              </g>
            </svg>
          </Col>
          <Col lg={24} xs={24} className="mt-30">
            <h1 className="text-center">{t('joyRide.reservations.modal.title')}</h1>
            <p className="text-center">
              {t('joyRide.reservations.modal.content')}
            </p>
          </Col>
          <Col lg={24} xs={24} className="mt-30">
            <Row justify="center">
              <Col className="mr-30">
                <Button
                  className="cmt-button primary outline"
                  type="primary"
                  onClick={closeModal}
                  size="large"
                >
                  Omitir tutorial
                </Button>
              </Col>
              <Col>
                <Button
                  className="cmt-button primary"
                  type="primary"
                  onClick={startTutorial}
                  size="large"
                >
                  ¡Muéstrame!
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
};

export default Reservations;
