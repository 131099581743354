import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from "react-i18next"

const Help = ({ phone }) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={`help-wrapper help-is-${show}`} onClick={() => setShow(!show) }>
      <Row>
        <Col lg={24}>
          <span className="help-icon">?</span>{t('scheduleTime.help.needHelpText')} {show && <span className="help-phone">
            {t('scheduleTime.help.callUsText')}
            <strong><a href={`tel:${phone}`}>{phone}</a></strong>
          </span>}
        </Col>
      </Row>
    </div>
  )
};

export default Help