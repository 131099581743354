import React from 'react'
import { Row, Col, Modal, Form } from 'antd'
import CostsCenterForm from './CostsCenterForm'

const NewCostCenterModal = ({
  newCostCenterModalVisible,
  setNewCostCenterModalVisible,
  saveCostCenter,
  loading,
  countryCode,
}) => {
  const [form] = Form.useForm()

  const closeModal = () => {
    setNewCostCenterModalVisible(false)
  }

  return (
    <Modal
      open={newCostCenterModalVisible}
      onCancel={closeModal}
      footer={false}
      centered
      width={`85vw`}
      className="new-cost-center-modal"
    >
      <Row justify='center'>
        <Col lg={24} xs={24}>
          <CostsCenterForm
            form={form}
            loading={loading}
            saveCostCenter={saveCostCenter}
            formAction="create"
            countryCode={countryCode}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default NewCostCenterModal