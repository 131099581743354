import { message } from 'antd';
import { useCallback, useState, useEffect } from 'react';
import { navigate } from "gatsby";
import { format } from 'rut.js';
import {
  getUserInviteService,
  getUserProfileData,
  setUserPassword,
  inviteNewUsers,
  saveUserProfileService,
  createUserProfileService,
} from '../../../../services/profilesAndCostCenters';
import {
  getUserName,
  getEmail,
} from '../../../../services/auth';

const useUser = ({ token }) => {
  const [loading, setLoading] = useState({
    createUser: false,
    saveUserPassword: false,
    getUserInvite: false,
    fetch: false,
    saveUser: false,
  });
  const [user, setUser] = useState({
    name: getUserName(),
    email: getEmail(),
  });
  const [formUser, setFormUser] = useState({});
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;

  const getUserInvite = useCallback(async () => {
    try {
      setLoading(loading => { return { ...loading, getUserInvite: true } });
      const response = await getUserInviteService(token);
      response.extra_data = JSON.parse(response.extra_data);
      setFormUser(formUser => ({
        ...formUser,
        ...response,
      }));
    } catch (error) {
      console.error(error);
      message.error({
        content: "Ha ocurrido un error. Inténtalo nuevamente",
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setLoading(loading => { return { ...loading, getUserInvite: false } });
    }
  }, [token]);

  const createUser = async (values) => {
    try {
      setLoading(loading => { return { ...loading, createUser: true } });
      if (!formUser.password && !formUser.user_id) {
        message.error({
          content: `Por favor configurar contraseña.`,
          style: {
            marginTop: '35vh',
          },
        });
        return;
      }
      const response = await createUserProfileService({
        first_name: values.firstName,
        last_name: values.lastName,
        document_type: values.documentType,
        document_number: values.documentType === 'rut'
          ? format(values.documentNumber, { dots: false })
          : values.documentNumber,
        phone: values.phone,
        email: values.email,
        active: values.active,
        company_id: formUser.company_id,
        password: formUser.password,
        password_confirmation: formUser.password_confirmation,
        country_code: process.env.GATSBY_COUNTRY_VALUE,
        invite_id: formUser.invite_id,
      }, token);
      if (response === true) {
        message.success({
          content: "Usuario creado exitosamente",
          style: {
            marginTop: '35vh',
          },
        });
        navigate('/app/login?redirect=/app/reservas');
      } else {
        throw new Error((response && response.message) || response);
      }
    } catch (error) {
      message.error({
        content: `Error interno. ${error}`,
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setLoading(loading => { return { ...loading, createUser: false } })
    }
  };

  const saveUserPassword = async (values) => {
    setFormUser(formUser => ({
      ...formUser,
      password: values.password,
      password_confirmation: values.passwordConfirmation,
    }))
  };

  const fetchUserData = useCallback(async () => {
    setLoading(loading => { return { ...loading, fetch: true } });
    try {
      const response = await getUserProfileData({ email: user.email });
      if (response) {
        setFormUser({
          id: response.id && response.id,
          firstName: response.first_name && response.first_name,
          lastName: response.last_name && response.last_name,
          phone: response.phone,
          documentNumber: response.document_number,
          documentType: response.document_type,
          email: response.email,
          active: !!response.active,
          company_id: response.extra_data && response.extra_data[0].company_id,
          canReserve: response.extra_data && !!response.extra_data[0].extra_data.can_reserve,
          canViewStatistics: response.extra_data && !!response.extra_data[0].extra_data.can_view_statistics,
          canDownloadResults: response.extra_data && !!response.extra_data[0].extra_data.can_download_results,
          canViewInvoices: response.extra_data && !!response.extra_data[0].extra_data.can_view_invoices,
          medicalAdministation: response.extra_data && !!response.extra_data[0].extra_data.medical_administration,
          companies: response.companies && response.companies.join(', '),
        });
      }
    } catch (error) {
      message.error({
        content: 'Ha ocurrido un error al obtener los datos. Inténtalo de nuevo',
        style: {
          marginTop: '35vh',
        },
      });
      console.error(`Error en fetch Datos usuario: ${error}`);
    } finally {
      setLoading(loading => { return { ...loading, fetch: false } });
    }
  }, []);

  const saveUser = async (values) => {
    try {
      setLoading(loading => ({ ...loading, saveUser: true }))
      let response = false;

      if (values.documentType && values.documentNumber) {
        response = await saveUserProfileService({
          id: formUser.id,
          first_name: values.firstName,
          last_name: values.lastName,
          document_type: values.documentType,
          document_number: values.documentType === 'rut'
            ? format(values.documentNumber, { dots: false })
            : values.documentNumber,
          phone: values.phone,
          email: values.email,
          active: formUser.id ? values.active : true,
          company_id: formUser.company_id,
          country_code: process.env.GATSBY_COUNTRY_VALUE,
        });
      } else if (values.password) {
        response = await setUserPassword({
          password: values.password,
          password_confirmation: values.passwordConfirmation,
        }, formUser.id);
      } else {
        response = await inviteNewUsers({
          country_code: process.env.GATSBY_COUNTRY_VALUE && process.env.GATSBY_COUNTRY_VALUE.toLowerCase(),
          company: user.extra_data[0].company_id,
          users: values.users.map(user => ({
            email: user.email,
            extra_data: {
              can_download_results: user.canDownloadResults,
              can_reserve: user.canReserve,
              can_view_invoices: user.canViewInvoices,
              medical_administration: user.medicalAdministation,
              can_view_statistics: user.canViewStatistics,
            },
          })),
        });
      }

      if (response === true) {
        message.success({
          content: "Datos guardados exitosamente",
          style: {
            marginTop: '35vh'
          }
        })
        fetchUserData()
      } else {
        throw new Error();
      }
    } catch (err) {
      message.error({
        content: `Ha ocurrido un error. Inténtalo nuevamente`,
        style: {
          marginTop: '35vh'
        }
      })
      console.error(`Error interno ${err}`)
    } finally {
      setLoading(loading => ({ ...loading, saveUser: false }));
    }
  };

  useEffect(() => {
    if (token) {
      getUserInvite();
    } else {
      fetchUserData();
    }
  }, [token]);

  return {
    user,
    saveUserPassword,
    loading,
    createUser,
    countryCode,
    getUserInvite,
    formUser,
    fetchUserData,
    saveUser,
  };
};

export default useUser;
