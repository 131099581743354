import { message } from 'antd';
import { useEffect, useState, useCallback } from 'react';
import { format } from 'rut.js';
import {
  getCompanies,
  getCompaniesCostCenters,
  getCompaniesUsers,
  saveCostCenterService,
  saveUsersService,
  inviteNewUsers,
  setUserPassword,
  billingDataService,
  saveBillingDataService,
} from '../../../../services/profilesAndCostCenters';
import {
  getUserName,
  getEmail,
} from '../../../../services/auth';

const useCompanies = () => {
  const [loading, setLoading] = useState({
    companies: false,
    costCenters: false,
    saveCostCenters: false,
    billingData: false,
    users: false,
    saveUser: false,
    saveBillingData: false,
  });
  const [user, setUser] = useState({
    name: getUserName(),
    email: getEmail(),
  })
  const [companiesList, setCompaniesList] = useState([]);
  const [costCentersList, setCostCentersList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [currentCompany, setCurrentCompany] = useState();
  const [billingData, setBillingData] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [isSuperUser, setSuperUser] = useState();

  const fetchCompaniesList = useCallback(async () => {
    let _companyList = [];
    let _currentCompany;
    try {
      setLoading(loading => ({ ...loading, companies: true }));
      const response = await getCompanies({ userEmail: user.email });
      if (response && response.length > 0) {
        _companyList = response;
        _currentCompany = response[0];
      }
    } catch (error) {
      console.error(`Error en Fetch el listado de compañías: ${error}`);
      message.error({
        content: `Error al traer el listado de compañías`,
        style: {
          marginTop: '40vh',
        },
      });
    } finally {
      setCompaniesList(_companyList);
      setCurrentCompany(_currentCompany);
      setLoading(loading => ({ ...loading, companies: false }));
    }
  }, []);

  const fetchCostCenters = useCallback(async () => {
    let _response = [];
    try {
      setLoading(loading => ({ ...loading, costCenters: true }));
      const response = await getCompaniesCostCenters({ companyId: currentCompany.id });
      if (response && response.length > 0) {
        _response = response;
      }
    } catch (error) {
      console.error(`Error en Fetch el listado de centro de costos: ${error}`);
      message.error({
        content: `Error al traer el listado de centro de costos`,
        style: {
          marginTop: '40vh',
        },
      });
    } finally {
      setCostCentersList(_response);
      setLoading(loading => ({ ...loading, costCenters: false }));
    }
  }, [currentCompany]);

  const fetchCompanyUsers = useCallback(async () => {
    let _response;
    try {
      setLoading(loading => ({ ...loading, users: true }));
      const response = await getCompaniesUsers({ companyId: currentCompany.id });
      if (response && response.length > 0) {
        _response = response;
      }
    } catch (error) {
      console.error(`Error en Fetch listado de usuarios: ${error}`);
      message.error({
        content: `Error al traer el listado de usuarios`,
        style: {
          marginTop: '40vh',
        },
      });
    } finally {
      setUsersList(_response);
      setLoading(loading => ({ ...loading, users: false }));
    }
  }, [currentCompany]);

  const fetchBillingData = useCallback(async () => {
    let _response;
    try {
      setLoading(loading => ({ ...loading, billingData: true }));
      const response = await billingDataService({ companyId: currentCompany.id });
      if (response) {
        _response = response;
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: `Error al traer los datos de facturación`,
        style: {
          marginTop: '40vh',
        },
      });
    } finally {
      setBillingData(_response);
      setLoading(loading => ({ ...loading, billingData: true }));
    }
  }, [currentCompany]);

  const saveCostCenter = async (values) => {
    try {
      setLoading(loading => ({ ...loading, saveCostCenters: true }));

      const response = await saveCostCenterService({
        id: values.id,
        name: values.name,
        code: values.code,
        active: values.active,
        manager: values.responsibleFullName,
        phone: values.phone,
        email: values.email,
        companyId: currentCompany.id,
      });

      if (response && response.length > 0 && response[0].id) {
        message.success({
          content: "Datos guardados exitosamente",
          style: {
            marginTop: '35vh'
          }
        });
        fetchCostCenters();
      } else {
        throw new Error();
      }
    } catch (err) {
      message.error({
        content: `Ha ocurrido un error. Inténtalo nuevamente`,
        style: {
          marginTop: '35vh'
        }
      });
      console.error(`Error interno ${err}`);
    } finally {
      setLoading(loading => ({ ...loading, saveCostCenters: false }));
    }
  };

  const saveCostCenterActive = async (values, costCenter) => {
    try {
      setLoading(loading => ({ ...loading, saveCostCenters: true }));

      const response = await saveCostCenterService({
        ...costCenter,
        active: values,
        companyId: currentCompany.id,
      });

      if (response && response.length > 0 && response[0].id) {
        message.success({
          content: "Datos guardados exitosamente",
          style: {
            marginTop: '35vh'
          }
        });
        fetchCostCenters();
      } else {
        throw new Error();
      }
    } catch (err) {
      message.error({
        content: `Ha ocurrido un error. Inténtalo nuevamente`,
        style: {
          marginTop: '35vh'
        }
      });
      console.error(`Error interno ${err}`);
    } finally {
      setLoading(loading => ({ ...loading, saveCostCenters: false }));
    }
  };

  const saveUser = async (values) => {
    try {
      setLoading(loading => ({ ...loading, saveUser: true }))
      let response = false;

      if (values.documentType && values.documentNumber) {
        response = await saveUsersService({
          id: currentUser.id,
          first_name: values.firstName,
          last_name: values.lastName,
          document_type: values.documentType,
          document_number: values.documentType === 'rut'
            ? format(values.documentNumber, { dots: false })
            : values.documentNumber,
          phone: values.phone,
          email: values.email,
          active: values.active,
          company_id: currentCompany.id,
          country_code: process.env.GATSBY_COUNTRY_VALUE,
          extra_data: {
            can_view_statistics: !!values.canViewStatistics,
            can_download_results: !!values.canDownloadResults,
            can_reserve: !!values.canReserve,
            can_view_invoices: !!values.canViewInvoices,
            medical_administration: !!values.medicalAdministation,
          }
        });
      } else if (values.password) {
        response = await setUserPassword({
          password: values.password,
          password_confirmation: values.passwordConfirmation,
        }, currentUser.id);
      } else {
        response = await inviteNewUsers({
          country_code: process.env.GATSBY_COUNTRY_VALUE && process.env.GATSBY_COUNTRY_VALUE.toLowerCase(),
          company_id: currentCompany.id,
          users: values.users.map(user => ({
            email: user.email,
            extra_data: {
              can_download_results: user.canDownloadResults,
              can_reserve: user.canReserve,
              can_view_invoices: user.canViewInvoices,
              can_view_statistics: user.canViewStatistics,
              medical_administration: user.medicalAdministation,
            },
          })),
        });
      }

      if (response === true) {
        message.success({
          content: "Datos guardados exitosamente",
          style: {
            marginTop: '35vh'
          }
        })
        fetchCompanyUsers()
      } else {
        throw new Error();
      }
    } catch (err) {
      message.error({
        content: `Ha ocurrido un error. Inténtalo nuevamente`,
        style: {
          marginTop: '35vh'
        }
      })
      console.error(`Error interno ${err}`)
    } finally {
      setLoading(loading => ({ ...loading, saveUser: false }));
    }
  };

  const saveUserActive = async (values, user) => {
    try {
      setLoading(loading => ({ ...loading, saveUser: true }));
      const response = await saveUsersService({
        ...user,
        active: values,
        country_code: process.env.GATSBY_COUNTRY_VALUE,
        company_id: currentCompany.id,
      });
      if (response === true) {
        message.success({
          content: "Datos guardados exitosamente",
          style: {
            marginTop: '35vh'
          }
        })
        fetchCompanyUsers()
      } else {
        throw new Error();
      }
    } catch (err) {
      message.error({
        content: `Ha ocurrido un error. Inténtalo nuevamente`,
        style: {
          marginTop: '35vh'
        }
      })
      console.error(`Error interno ${err}`)
    } finally {
      setLoading(loading => ({ ...loading, saveUser: false }));
    }
  };

  const permissionsSave = async (values, user) => {
    try {
      setLoading(loading => ({ ...loading, saveUser: true }));
      const response = await saveUsersService({
        ...user,
        company_id: currentCompany.id,
        extra_data: {
          can_view_statistics: !!values.canViewStatistics,
          can_download_results: !!values.canDownloadResults,
          can_reserve: !!values.canReserve,
          can_view_invoices: !!values.canViewInvoices,
          medical_administration: !!values.medicalAdministation,
        }
      });
      if (response === true) {
        message.success({
          content: "Datos guardados exitosamente",
          style: {
            marginTop: '35vh'
          }
        })
        fetchCompanyUsers()
      } else {
        throw new Error();
      }
    } catch (err) {
      message.error({
        content: `Ha ocurrido un error. Inténtalo nuevamente`,
        style: {
          marginTop: '35vh'
        }
      })
      console.error(`Error interno ${err}`)
    } finally {
      setLoading(loading => ({ ...loading, saveUser: false }));
    }
  };

  const saveBillingData = async (values) => {
    try {
      setLoading(loading => ({ ...loading, saveBillingData: true }));
      const response = await saveBillingDataService({
        ...(billingData && { id: billingData.id }),
        name: values.name,
        phone: values.phone,
        email: values.email,
        companyId: currentCompany.id,
      });
      if (response && response[0] && response[0] > 0) {
        message.success({
          content: "Datos guardados exitosamente",
          style: {
            marginTop: '35vh'
          }
        })
        fetchBillingData();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error)
      message.error({
        content: `Ha ocurrido un error. Inténtalo nuevamente`,
        style: {
          marginTop: '35vh'
        }
      })
    } finally {
      setLoading(loading => ({ ...loading, saveBillingData: false }));
    }
  }

  useEffect(() => {
    fetchCompaniesList();
  }, []);

  useEffect(() => {
    if (currentCompany && currentCompany.id) {
      fetchCostCenters();
      fetchCompanyUsers();
      fetchBillingData();
      setSuperUser(currentCompany.super_user);
      setUser(user => ({ ...user, isSuperUser: currentCompany.super_user }))
    }
  }, [fetchCostCenters, fetchCompanyUsers, fetchBillingData])

  return {
    loading,
    user,
    companiesList,
    costCentersList,
    usersList,
    currentCompany,
    setCurrentCompany,
    saveCostCenter,
    saveUser,
    currentUser,
    setCurrentUser,
    permissionsSave,
    saveUserActive,
    billingData,
    saveBillingData,
    isSuperUser,
    saveCostCenterActive,
  };
};

export default useCompanies;
