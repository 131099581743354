import React from "react";
import "../css/login.less";
import { Row, Col, Layout, Form, Button, Input } from "antd";

const { Content } = Layout;

const ChangePassword = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <Content style={{ marginTop: -18 }}>
      <Row justify="center" className="registration-form-container">
        <Col
          lg={10}
          className="registration-form-container-element pt-lg-40 pb-lg-200"
        >
          <Row justify="center">
            <Col lg={24}>
              <h1 className="text-center">Cambio de contraseña</h1>
            </Col>
          </Row>
          <Row justify="center">
            <Col lg={18} className="mt-lg-70 pb-lg-110">
              <Form form={form} onFinish={onFinish} size="large" className="cmt-form">
                <Form.Item>
                  <Input
                    type="password"
                    placeholder="Contraseña actual"
                    size="large"
                    className="cmt-input"
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    type="password"
                    placeholder="Contraseña nueva"
                    size="large"
                    className="cmt-input"
                  />
                </Form.Item>
                <Form.Item>
                  <Input
                    type="password"
                    placeholder="Repetir contraseña nueva"
                    size="large"
                    className="cmt-input"
                  />
                </Form.Item>
                <Row justify="center">
                  <Col lg={24} className="mt-lg-20 pb-lg-200">
                    <Form.Item>
                      <Button
                        className="change-password-button"
                        size="large"
                        block
                      >
                        Cambiar contraseña
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Content>
  );
};

export default ChangePassword;

