import { Row, Col, Radio, Empty, Button } from 'antd';
import useXlsGenerator from '../hooks/useXlsGenerator';

const TableCIE10 = ({
  filters,
  tabName,
  tableData,
  pagination,
  cie10Page,
  setCIE10Page,
  fetchCIE10Reports,
  handleTabPaneName,
}) => {
  const {
    loadingXls,
    setGetJSON,
  } = useXlsGenerator(filters);

  const customTableHeader = () => {
    return (
      <Row className="cmt-custom-table-header">
        <Col
          lg={6}
          className="align-item-center-start p-10"
        >
          <h3>CIE10</h3>
        </Col>
        <Col
          lg={10}
          className="align-item-center-start p-10"
        >
          <h3 className="text-center">
            Diagnóstico
          </h3>
        </Col>
        <Col
          lg={8}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Cantidad de atenciones
          </h3>
        </Col>
      </Row>
    );
  };

  const customTableBody = (data, index) => {
    return (
      <Row
        key={index}
        className={`cmt-custom-table-body ${index % 2 !== 0 && 'white-background'}`}
      >
        <Col
          lg={{ order: 1, span: 6 }}
          xs={{ order: 1, span: 12 }}
          className="align-item-center p-10"
        >
          <h3 className="hide-desktop text-center">CIE10</h3>
          <p className="mb-0">{data.code}</p>
        </Col>
        <Col
          lg={{ order: 2, span: 10 }}
          xs={{ order: 3, span: 24 }}
          className="align-item-center p-10"
        >
          <h3 className="hide-desktop text-center">Diagnóstico</h3>
          <p className='mb-0'>{data.description}</p>
        </Col>
        <Col
          lg={{ order: 3, span: 8 }}
          xs={{ order: 2, span: 12 }}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">Cantidad de atenciones</h3>
          <p className='mb-0 text-center'>{data.attention_amount}</p>
        </Col>
      </Row>
    );
  };

  const handlePagination = (direction, currentPage) => {
    const currentTabName = tabName;
    const value = direction === 'next' ? currentPage + 1 : currentPage - 1;
    setCIE10Page(cie10Page => ({ ...cie10Page, [currentTabName]: value }));
    fetchCIE10Reports(filters, currentTabName, value);
  };

  const downloadXlsFile = () => {
    setGetJSON(getJSON => ({ ...getJSON, cie10: true }));
  };


  return (
    <Row gutter={[0, 20]} className='mt-30 table-wrapper'>
      {
        tableData?.length > 0 ?
          <>
            <Col lg={24} xs={24}>
              <Row justify='end' gutter={[0, 30]}>
                <Col xxl={5} xl={6} lg={7} xs={24}>
                  <Button
                    className='cmt-button secondary outline'
                    type='primary'
                    size='large'
                    loading={loadingXls.cie10}
                    disabled={loadingXls.cie10}
                    onClick={downloadXlsFile}
                  >
                    <span className='icon-system-download mr-10' />
                    Descargar Reporte CIE10 .xlsx
                  </Button>
                </Col>
                <Col lg={24} xs={24} className='mt-lg-0 mt-md-30 mt-sm-30 mt-xs-30'>
                  <h4 className='mb-0'>Top 10</h4>
                </Col>
              </Row>
            </Col>
            <Col lg={24} xs={24}>
              {
                customTableHeader()
              }
              {
                tableData.map((item, index) => {
                  return (
                    customTableBody(item, index)
                  )
                })
              }
            </Col>
            <Col lg={24} xs={24} className='mt-lg-20 mt-md-30 mt-sm-30 mt-xs-30'>
              <Row align='middle' gutter={10} className='pagination-wrapper'>
                <Col>
                  <Radio.Group className="cmt-pagination">
                    <Radio.Button
                      value="prev"
                      disabled={cie10Page[tabName] === 1 || !pagination.prevPage}
                      onClick={(event) => handlePagination(event.target.value, cie10Page[tabName])}
                    >
                      Anterior
                    </Radio.Button>
                    <Radio.Button
                      value="next"
                      disabled={cie10Page[tabName] === pagination.lastPage || tableData.length < pagination.perPage}
                      onClick={(event) => handlePagination(event.target.value, cie10Page[tabName])}
                    >
                      Siguiente
                    </Radio.Button>
                  </Radio.Group>
                </Col>
                <Col>
                  <p className='mb-0'>{pagination.currentPage} de {pagination.lastPage}</p>
                </Col>
              </Row>
            </Col>
          </>
          :
          <Col lg={24} xs={24}>
            <Row justify='center' className='mt-40'>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={`Sin datos sobre ${handleTabPaneName(tabName)}`}
              />
            </Row>
          </Col>
      }
    </Row>
  );
};

export default TableCIE10;