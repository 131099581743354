import React, { useEffect, useState } from "react"
import { useParams } from '@reach/router'
import { Link, navigate } from 'gatsby'
import {
  Row,
  Col,
  Spin,
  Button
} from "antd"
import { reevaluationService } from "../../../services/reevaluation"
import moment from "moment"
import { useTranslation } from "react-i18next"


const PatientReevaluationInfo = () => {
  const [loading, setLoading] = useState({ fetch: false, download: false });
  const [reevaluationSteps, setReevaluationSteps] = useState();
  const { medicalConsultationId } = useParams()
  const { t } = useTranslation()


  const fetchReevaluationSteps = async (params) => {
    setLoading(loading => { return { ...loading, fetch: true } })
    const response = await reevaluationService.getPatientSteps(params);

    const extra_data = response.extra_data;

    if (Array.isArray(extra_data)) {
      for (let data of extra_data) {
        if (hasDocuments(data.documents)) {
          navigate(`/app/reevaluacion/${medicalConsultationId}`)
        }
      }
    }

    setReevaluationSteps(response)
    setLoading(loading => { return { ...loading, fetch: false } })
  }

  const hasDocuments = (documents) => {
    return documents.filter(document => document.exam_document_id != null).length > 0
  }

  useEffect(() => {
    fetchReevaluationSteps({ medical_consultation_detail_id: medicalConsultationId })
  }, [medicalConsultationId])


  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY")
  }

  const documentItem = () => {
    return (
      <Col lg={24} xs={24}>
        <p className="bold orange sub-title mb-5">{t('patientReevaluation.reevaluationInfo.documentTitle')}</p>
        <p className="mb-0 description" dangerouslySetInnerHTML={{ __html: t('patientReevaluation.reevaluationInfo.documentText') }} />
      </Col>
    )
  }

  const branchOfficeItem = () => {
    return (
      <Col lg={24} xs={24}>
        <p className="bold orange sub-title mb-5">{t('patientReevaluation.reevaluationInfo.branchOfficeTitle')}</p>
        <p className="description mb-5">{t('patientReevaluation.reevaluationInfo.branchOfficeText1')} {formatDate(reevaluationSteps['date'])} {t('patientReevaluation.reevaluationInfo.branchOfficeText2')}</p>
        <p className="orange description mb-0">{t('patientReevaluation.reevaluationInfo.branchOfficeText3')}</p>
      </Col>
    )
  }

  const evaluationItem = () => {
    return (
      <Col lg={24} xs={24}>
        <p className="bold orange sub-title mb-5">{t('patientReevaluation.reevaluationInfo.evaluationTitle')}</p>
        <p className="description mb-0">{t('patientReevaluation.reevaluationInfo.evaluationText')}</p>
      </Col>
    )
  }

  const confirmationItem = () => {
    return (
      <Col lg={24} xs={24}>
        <p className="bold orange sub-title mb-5">{t('patientReevaluation.reevaluationInfo.confirmationTitle')}</p>
        <p className="mb-0 description">{t('patientReevaluation.reevaluationInfo.confirmationText')}</p>
      </Col>
    )
  }


  return (
    <Row justify="center" className="reevaluation-info-wrapper pt-30 pb-80">
      {
        loading.fetch ?
          <Spin style={{ marginTop: '35vh' }} />
          :
          reevaluationSteps &&
          <Col xxl={17} lg={18} xs={24}>
            <Row justify="center" className="header mb-30">
              <Col lg={24} xs={22}>
                <Row justify="space-between">
                  <Col>
                    <p>{reevaluationSteps['full_name']}</p>
                  </Col>
                  <Col>
                    <p className="orange-text">Reserva n° {reevaluationSteps['reservation_number']}</p>
                  </Col>
                </Row>
                <Row>
                  <h1 className="mt-lg-0 mt-xs-20 mb-lg-0 mb-xs-30">{t('patientReevaluation.reevaluationInfo.welcomeTitle')}</h1>
                </Row>
                <Row>
                  <p className="mb-0">{t('patientReevaluation.reevaluationInfo.welcomeText1')}</p>
                  <p dangerouslySetInnerHTML={{ __html: t('patientReevaluation.reevaluationInfo.welcomeText2') }} />
                  <p className="mt-20">{t('patientReevaluation.reevaluationInfo.welcomeText3')}</p>
                </Row>
              </Col>
            </Row>
            <Row justify="center" className="body">
              <Col lg={24} xs={24}>
                <Row justify="center" gutter={[0, 30]} className="mb-30">
                  <Col lg={24} xs={24} className="gray-item pt-10 pb-10">
                    <h2 className="text-center mb-0">{t('patientReevaluation.reevaluationInfo.reevaluationTitle')}</h2>
                  </Col>
                  <Col lg={24} xs={22}>
                    <p className="text-center sub-title mb-0" dangerouslySetInnerHTML={{ __html: t('patientReevaluation.reevaluationInfo.reevaluationText') }} />
                  </Col>
                </Row>
                <Row justify="center">
                  <Col lg={24} xs={24} className="gray-item pt-30 pb-30 pl-lg-80 pr-lg-80 pl-md-20 pr-md-20 pl-sm-20 pr-sm-20 pl-xs-20 pr-xs-20">
                    <Row>
                      <Col lg={1} xs={2} className="dot-wrapper">
                        <div className="dot-icon" />
                      </Col>
                      <Col lg={23} xs={22}>
                        <Row>
                          <p className="orange bold mb-0">Paso 1</p>
                          {
                            reevaluationSteps['documents'] ?
                              documentItem()
                              :
                              branchOfficeItem()
                          }
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={24} xs={24} className="gray-item pt-30 pb-30 pl-lg-80 pr-lg-80 pl-md-20 pr-md-20 pl-sm-20 pr-sm-20 pl-xs-20 pr-xs-20 mt-15">
                    <Row>
                      <Col lg={1} xs={2} className="dot-wrapper">
                        <div className="dot-icon" />
                      </Col>
                      <Col lg={23} xs={22}>
                        <Row>
                          <p className="orange bold mb-0">Paso 2</p>
                          {
                            reevaluationSteps['assistance_date'] ?
                              branchOfficeItem()
                              :
                              evaluationItem()
                          }
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={24} xs={24} className="gray-item pt-30 pb-30 pl-lg-80 pr-lg-80 pl-md-20 pr-md-20 pl-sm-20 pr-sm-20 pl-xs-20 pr-xs-20 mt-15">
                    <Row>
                      <Col lg={1} xs={2} className="dot-wrapper">
                        <div className="dot-icon" />
                      </Col>
                      <Col lg={23} xs={22}>
                        <Row>
                          <p className="orange bold mb-0">Paso 3</p>
                          {
                            confirmationItem()
                          }
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={24} xs={24} className="mt-50">
                    <Row justify="center">
                      <Col xxl={6} xl={6} lg={7} xs={24}>
                        <Link>
                          <Button
                            className="cmt-button primary"
                            type="primary"
                            size="large"
                            onClick={() => {
                              navigate(`/app/reevaluacion/${medicalConsultationId}`)
                            }}
                          >
                            Iniciar Mi Reevaluación
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
      }
    </Row>
  )
}

export default PatientReevaluationInfo