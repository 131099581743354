import React, { useState } from 'react';
import {
  Row,
  Col,
  Table,
  Button,
} from 'antd'
import DetailsModal from './DetailsModal';
import InviteNewUserModal from './InviteNewUserModal';
import PermissionForm from './PermissionForm';
import ActiveConfirm from './ActiveConfirm';

const AllowedUsers = ({
  isSuperUser,
  currentCompany,
  users,
  saveUser,
  permissionsSave,
  loading,
  t,
  countryCode,
  currentUser,
  setCurrentUser,
  saveUserActive,
  email,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [newUserModalVisible, setNewUserModalVisible] = useState(false);

  const showModal = (userData) => {
    setCurrentUser(userData)
    setModalVisible(true)
  };

  const showNewUserModal = () => {
    setNewUserModalVisible(true)
  };

  const columns = [
    {
      key: 0,
      title: 'Usuario',
      dataIndex: 'first_name',
      width: '35%',
      render: (first_name, record) => {
        return <p className='mb-0'>{first_name} {record.last_name}</p>
      }
    },
    {
      key: 1,
      title: 'Correo electrónico',
      dataIndex: 'email',
      width: '25%',
      render: (email) => {
        return <p className='mb-0'>{email}</p>
      }
    },
    {
      key: 2,
      title: 'Permisos',
      dataIndex: 'extra_data',
      align: 'center',
      width: '15%',
      render: (extra_data, user) => {
        return (
          <PermissionForm
            extra_data={extra_data}
            user={user}
            permissionsSave={permissionsSave}
            loading={loading}
            disabled={!isSuperUser || user.email === email || user.email.includes('@cmtsalud.')}
          />)
      }
    },
    {
      key: 3,
      title: 'Activo',
      dataIndex: 'active',
      align: 'center',
      width: '15%',
      render: (active, user) => {
        return (
          <ActiveConfirm
            active={active}
            object={user}
            saveObject={saveUserActive}
            loading={loading}
            disabled={!isSuperUser || user.email === email || user.email.includes('@cmtsalud.')}
            context='user'
            t={t}
          />
        )
      }
    },
    {
      key: 4,
      title: '',
      dataIndex: 'id',
      align: 'center',
      width: '10%',
      render: (id, record) => {
        return (
          <Button
            onClick={() => showModal(record)}
            className='cmt-button secondary outline'
            type='primary'
            size='small'
          >
            Ver más
          </Button>
        )
      }
    }
  ]

  return (
    <Row justify='center' className='company-profile__allowed-users pt-60'>
      <Col lg={24} xs={24}>
        <h2 className='mb-215'>Usuarios de tu equipo</h2>
      </Col>
      <Col lg={24} xs={24}>
        <Table
          rowKey="id"
          className='cmt-table company-profile-table'
          columns={columns}
          dataSource={users && users}
          pagination={false}
          locale={{ emptyText: "No hay usuarios con acceso" }}
        />
      </Col>
      {
        isSuperUser &&
        <Col lg={24} xs={24} className="mt-30">
          <Row justify='end'>
            <Col xl={5} lg={7} xs={24}>
              <Button
                onClick={showNewUserModal}
                className='cmt-button secondary'
                type='primary'
              >
                <i className='icon-system-plus mr-10' />
                Nuevo usuario
              </Button>
            </Col>
          </Row>
        </Col>
      }

      <DetailsModal
        isSuperUser={isSuperUser}
        email={email}
        currentUser={currentUser}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        saveUser={saveUser}
        loading={loading}
        t={t}
        countryCode={countryCode}
      />

      <InviteNewUserModal
        currentCompany={currentCompany}
        newUserModalVisible={newUserModalVisible}
        setNewUserModalVisible={setNewUserModalVisible}
        saveUser={saveUser}
        loading={loading}
      />

    </Row>
  )
}

export default AllowedUsers