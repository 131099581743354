import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Form, Tag, Radio } from 'antd'
import { DocumentCard, PhoneInput } from 'cmt-ui-kit';
import evaluationTypes from '../../../../../utils/evaluationType.json';

const { format } = require('rut.js')

const WithPatientForm = ({
  index,
  patient,
  initializeForm,
  countryCode,
  t,
}) => {
  const [form] = Form.useForm()
  const [documentType, setDocumentType] = useState(countryCode && countryCode.toUpperCase() === 'CL' ? 'rut' : 'dni');

  useEffect(() => {
    let data = {
      code: patient.code,
      services: patient.services
    }
    if (patient.patient) {
      if (countryCode === 'CL') {
        patient.patient.document_number = patient.patient.document_type === 'rut' ?
          format(patient.patient.document_number) : patient.patient.document_number;
      }

      data = {
        ...data,
        patientId: patient.patient.id,
        firstName: patient.patient.firstName,
        lastName: patient.patient.lastName,
        email: patient.patient.email,
        phone: patient.patient.phone || form.getFieldValue('phone'),
        documentType: patient.patient.document_type,
        documentNumber: patient.patient.document_number,
        evaluation_type: patient.patient.evaluation_type,
        profession: patient.patient.profession,
        external_id: patient.patient.external_id,
      };
    } else {
      form.setFieldsValue({ documentType });
    }
    form.setFieldsValue(data);
    initializeForm(form);
  }, [])

  return (
    <Col lg={24} xs={24} className="gray-patients-row mb-10 pt-20 pb-lg-20 pb-xs-0 pl-lg-30 pl-md-15 pl-xs-15 pr-lg-30 pr-md-15 pr-xs-15">
      <Form form={form}>
        <Row gutter={20} className="mb-10">
          <Col lg={{ order: 1, span: 4 }} xs={{ order: 1, span: 24 }}>
            <Row>
              <Col lg={{ order: 1, span: 17 }} md={{ order: 2, span: 17 }} xs={{ order: 2, span: 17 }}>
                <p>{t('preAdmission.withPatients.patient')} {index + 1}</p>
              </Col>
              <Col lg={{ order: 1, span: 5 }} md={{ order: 1, span: 1 }} xs={{ order: 1, span: 2 }}>
                <span className='icon-system-user' />
              </Col>
            </Row>
          </Col>
          <Col lg={{ order: 2, span: 14 }} xs={{ order: 3, span: 24 }}>
            <Row gutter={20}>
              <Col lg={12} xs={24}>
                <DocumentCard
                  countryCode={countryCode}
                  setDocumentType={setDocumentType}
                  form={form}
                  type='person'
                />
                <Form.Item name="code" hidden>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item name="id" hidden>
                  <Input type="hidden" />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <PhoneInput.ReactPhone
                  countryCode={countryCode}
                  form={form}
                  optional={true}
                />
              </Col>
              {
                countryCode === 'PE' &&
                <>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      form={form}
                      name='firstName'
                      required={true}
                    >
                      <Input
                        placeholder='Nombres'
                        id='firstName'
                        className='cmt-input'
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      form={form}
                      name='lastName'
                      required={true}
                    >
                      <Input
                        placeholder='Apellidos'
                        id='lastName'
                        className='cmt-input'
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      form={form}
                      name='email'
                    >
                      <Input
                        placeholder='Correo electrónico'
                        id='email'
                        className='cmt-input'
                      />
                    </Form.Item>
                  </Col>
                </>
              }
              <Col lg={12} xs={24}>
                <Form.Item
                  form={form}
                  name='profession'
                >
                  <Input
                    placeholder='Cargo'
                    id='profession'
                    className='cmt-input'
                  />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  form={form}
                  name='external_id'
                >
                  <Input
                    placeholder='ID externo'
                    id='external_id'
                    className='cmt-input'
                  />
                </Form.Item>
              </Col>
            </Row>
            {
              countryCode === 'PE' &&
              <Row gutter={[0, 10]}>
                <Col lg={24} xs={24} className="mb-10">
                  <Form.Item name='evaluation_type'>
                    <Radio.Group
                      id='evaluation_type'
                      className='cmt-radio'
                    >
                      {
                        evaluationTypes.map((type) =>
                          <Radio value={type.value}>{type.text}</Radio>
                        )
                      }
                    </Radio.Group>

                  </Form.Item>
                </Col>
              </Row>
            }
          </Col>
          <Col lg={{ order: 3, span: 24 }} xs={{ order: 2, span: 24 }} className="mb-lg-0 mb-xs-10">
            <Row>
              <Col lg={4} className="exams-title mr-10">
                <h4>{t('preAdmission.withPatients.servicesToPerform')}</h4>
              </Col>
              <Col lg={19} xs={24} className="exams-tag-container">
                <div className="exams-tag-wrapper pb-10">
                  {
                    patient?.services.map((service, index) => {
                      return <Tag key={index} className="mb-5">{service.service_name}</Tag>
                    })
                  }
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

export default WithPatientForm;