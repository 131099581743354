import React, { useState, useEffect } from 'react'
import { Spin, Row, Col, Button, Empty, message } from 'antd';
import { getNumberFormatted } from '../../../../helpers/handlers';
import { billingService } from '../../../../services/billing';
import ModalDetail from '../components/Modal';
import moment from 'moment';
import { useTranslation } from "react-i18next";

const Items = ({ number, startDate, endDate, company }) => {
  const [loading, setLoading] = useState(false);
  const [billings, setBillings] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [bill, setBill] = useState({});
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const showDetail = (localBill) => {
    setBill(localBill);
    setVisibleModal(true);
  };

  const closeModal = () => {
    setVisibleModal(false);
  };

  const handleLoadMore = async () => {
    const newPage = page + 1;
    const filters = getFilters(newPage);
    await fetchBill({ ...filters });
  };

  useEffect(() => {
    const page = 1;
    const filters = getFilters(page);
    fetchBill({ ...filters });
  }, [number, startDate, endDate, company]);

  const getFilters = (newPage) => {
    const data = { id: company, page: newPage };
    const params = {
      ...(page && { page }),
      ...(number && { number }),
      ...((startDate && endDate) && { startDate, endDate }),
      ...data,
    };
    setPage(newPage);
    return Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined)
    );
  };

  const fetchBill = async (filters) => {
    setLoading(true);
    try {
      const response = await billingService.getBills(filters);
      if (response?.pagination?.total > 0) {
        setShowLoadMore((response.pagination.currentPage * 10) < response.pagination.total);
        if (filters.page === 1) {
          setBillings(response.data);
        } else {
          setBillings(billings.concat(response.data));
        }
      } else {
        setBillings([]);
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: `Ha ocurrido un error al obtener las facturas. ${error}`,
        style: {
          marginTop: '40vh'
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DD");
  };

  return (
    <Spin className="cmt-spin" spinning={loading}>
      {
        billings?.length ?
          <div className="mb-60">
            <Row className="titles-desktop-container">
              <Col lg={5}>
                <h4 className="mb-0">{t('billing.items.billNumberTitle')}</h4>
              </Col>
              <Col lg={5}>
                <h4 className="mb-0">{t('billing.items.dateTitle')}</h4>
              </Col>
              <Col lg={5}>
                <h4 className="mb-0">{t('billing.items.reservationTitle')}</h4>
              </Col>
              <Col lg={5}>
                <h4 className="mb-0">{t('billing.items.totalValueTitle')}</h4>
              </Col>
            </Row>
            {
              billings.map((bill, index) => {
                return (
                  <Row
                    gutter={[0, 15]}
                    key={index}
                    className="gray-row mt-10 pt-lg-10 pt-md-15 pt-xs-15 pb-lg-10 pb-md-20 pb-xs-20 pr-md-15 pr-xs-15 pl-xs-15 pl-md-15"
                  >
                    <Col lg={5} xs={24}>
                      <Row>
                        <Col xs={24} className="titles-mobile-wrapper">
                          <h4 className="mb-0">{t('billing.items.billNumberTitle')}</h4>
                        </Col>
                        <Col lg={24} xs={24}>
                          <p className="mb-0 ml-lg-15 ml-xs-0 mt-5">
                            {bill.invoice_number}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5} xs={24}>
                      <Row>
                        <Col xs={24} className="titles-mobile-wrapper">
                          <h4 className="mb-0">{t('billing.items.dateTitle')}</h4>
                        </Col>
                        <Col lg={24} xs={24}>
                          <p className="pt- mb-0 mt-5">{formatDate(bill.date)}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5} xs={24}>
                      <Row>
                        <Col xs={24} className="titles-mobile-wrapper">
                          <h4 className="mb-0">{t('billing.items.reservationTitle')}</h4>
                        </Col>
                        <Col lg={24} xs={24}>
                          <p className="mb-0 mt-5">{bill.code}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={5} xs={24}>
                      <Row>
                        <Col xs={24} className="titles-mobile-wrapper">
                          <h4 className="mb-0">{t('billing.items.totalValueTitle')}</h4>
                        </Col>
                        <Col lg={24} xs={24}>
                          <p className="mb-0 mt-5">
                            {
                              (bill?.currency?.locate_code && bill?.currency?.code && bill?.total_amount) &&
                                getNumberFormatted({
                                  countryCode: bill.currency.locate_code,
                                  currencyCode: bill.currency.code,
                                  amount: bill.total_amount,
                                })
                            }
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} xs={24}>
                      <Button
                        id={index === 0 && "first-button"}
                        className={`cmt-button see-detail-button mt-lg-0 mt-xs-10 ${index === 0 && "first-button"
                          }`}
                        onClick={() => showDetail(bill)}
                        block
                      >
                        Ver detalle
                      </Button>
                    </Col>
                  </Row>
                );
              })}

            {showLoadMore && (
              <Row justify="center" className="mt-30">
                <Col>
                  <Button onClick={handleLoadMore} type="primary">
                    Cargar más
                  </Button>
                </Col>
              </Row>
            )}

            {
              bill && (
                <ModalDetail
                  bill={bill}
                  visible={visibleModal}
                  handleCloseModal={closeModal}
                />
              )
            }

          </div>
          : (
            <Row className="mt-40" justify="center">
              <Col lg={24}>
                <Empty description="Sin datos de facturación" />
              </Col>
            </Row>
          )
      }
    </Spin>
  );
};

export default Items;
