import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Row, Col, Button, Modal, Spin, message } from 'antd';
import LogoNaranjo2023 from "cmt-ui-kit/src/images/LogoNaranjo2023.svg";
import { billingService } from '../../../../services/billing';
import { getNumberFormatted } from '../../../../helpers/handlers';
import { useTranslation } from "react-i18next";

const ModalDetail = ({ bill, visible, handleCloseModal }) => {
  const [loading, setLoading] = useState({
    detail: false,
    paid: false,
  });
  const [detail, setDetail] = useState();
  const [isPaid, setIsPaid] = useState();
  const { t } = useTranslation();
  const countryCode = process.env.GATSBY_COUNTRY_CODE;

  useEffect(() => {
    if (bill?.invoice_number) {
      fetchBillDetail();
      checkPaid();
    }
  }, [visible]);

  const fetchBillDetail = useCallback(async () => {
    setLoading(loading => ({ ...loading, detail: true }));
    try {
      const response = await billingService.billingDetail({
        number: bill.invoice_number,
      });
      setDetail(response);
    } catch (error) {
      console.error('Error en Fetch Bill Detail', error);
      message.error({
        content: 'Error al obtener datos de Facturación',
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setLoading(loading => ({ ...loading, detail: false }));
    }
  }, [bill.invoice_number]);

  const checkPaid = useCallback(async () => {
    try {
      setLoading(loading => ({ ...loading, paid: true }));
      const response = await billingService.checkPaid({
        number: bill.invoice_number,
      });
      setIsPaid(response.paid);
    } catch (error) {
      console.error('Error en checkPaid', error);
      message.error({
        content: 'Error al obtener estado Facturación',
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setLoading(loading => ({ ...loading, paid: false }));
    }
  }, [bill.invoice_number]);

  const closeModal = () => {
    handleCloseModal();
  };


  return (
    <Modal
      title={<img src={LogoNaranjo2023} width="100" />}
      className="modal-billing-detail"
      visible={visible}
      onCancel={closeModal}
      width={800}
      footer={
        <Row justify="center" className="mt-20 mb-20">
          {/* <Col lg={{ order: 1, span: 24 }} xs={{ order: 2, span: 24 }} className="send-document-title-wrapper mt-lg-0 mt-md-40 mt-xs-40">
                <h3 className="text-left">Enviar documento por correo electrónico</h3>
            </Col>
            <Col lg={{ order: 2, span: 16 }} xs={{ order: 3, span: 24 }}>
                <Row>
                    <Col lg={13} xs={24}>
                        <Input placeholder="ejemplo@ejemplo.com" />
                    </Col>
                    <Col lg={5} xs={24}>
                        <Button className="ml-lg-15 ml-xs-0 mt-lg-0 mt-md-15 mt-xs-15 send-button" block>Enviar</Button>
                    </Col>
                </Row>
            </Col> */}
          <Col lg={{ order: 3, span: 8 }} xs={{ order: 1, span: 24 }}>
            <Row justify="end">
              <Col lg={24} xs={24}>
                <a href={bill.url} target="_blank">
                  <Button className="download-document-button" block>
                    Descargar documento original
                  </Button>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      <Spin spinning={loading.detail || loading.paid} className='cmt-spin'>
        <Row justify="center" className="modal-wrapper">
          <Col lg={24} xs={24}>
            <Row justify="center">
              <Col lg={23} xs={22} className="mt-20 mb-lg-0 mb-xs-20">
                <Row justify="space-around">
                  <Col lg={19} xs={15}>
                    <h4>{t('billing.modalDetail.billNumberTitle')} {bill.invoice_number}</h4>
                  </Col>
                  <Col lg={5} xs={9}>
                    <h4 className="text-right ml-20">
                      {moment(bill.date).format("DD-MM-YYYY")}
                    </h4>
                  </Col>
                </Row>
              </Col>
              <Col lg={23} xs={22} className="mb-15">
                <Row>
                  <Col
                    lg={{ order: 1, span: 12 }}
                    xs={{ order: 1, span: 24 }}
                  >
                    <Row>
                      <Col lg={24}>
                        <h5 className="mr-5 mb-0">{t('billing.modalDetail.companyTitle')}</h5>
                      </Col>
                      <Col lg={24}>
                        <p className="mb-10">{bill.company}</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={{ order: 3, span: 6 }} xs={{ order: 5, span: 24 }}>
                    <Row
                      justify="space-between"
                      className="billing-state-title"
                    >
                      <Col lg={24}>
                        <h5 className="mr-5 mb-0">{t('billing.modalDetail.billingState')}</h5>
                      </Col>
                      {isPaid ? (
                        <Col lg={24}>
                          <p className="bill-state mb-10">{t('billing.modalDetail.paidText')}</p>
                        </Col>
                      ) : (
                        <Col lg={24}>
                          <p className="bill-state waiting mb-10">
                            {t('billing.modalDetail.notPaidText')}
                          </p>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              justify="center"
              className="gray-billing-container pt-lg-20 pt-md-20 pt-sm-20 pt-xs-20 pb-lg-20 pb-xs-0"
            >
              <Col lg={23} xs={22}>
                <Row>
                  <Col lg={24} xs={24}>
                    <p>{t('billing.modalDetail.billingDetailTitle')}</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={11} md={11} sm={11} xs={11}>
                    <h5>{t('billing.modalDetail.servicesTitle')}</h5>
                  </Col>
                  <Col lg={9} md={9} sm={9} xs={8}>
                    <h5>{t('billing.modalDetail.quantityTitle')}</h5>
                  </Col>
                  <Col lg={4} md={4} sm={4} xs={5}>
                    <h5 className="text-right">{t('billing.modalDetail.valueTitle')}</h5>
                  </Col>
                </Row>
              </Col>
              <Col lg={24} xs={24}>
                <div className="spreader-bar" />
              </Col>
              <Col lg={23} xs={22}>
                {
                  detail?.map((data) => {
                    return (
                      <Row className="mt-15" key={data.id}>
                        <Col lg={12} md={12} sm={12} xs={13}>
                          <p>{data.product}</p>
                        </Col>
                        <Col lg={8} md={8} sm={8} xs={6}>
                          <p>{data.quantity}</p>
                        </Col>
                        <Col lg={4} md={4} sm={4} xs={5}>
                          <p style={{ textAlign: "right" }}>
                            {
                              data?.amount &&
                              getNumberFormatted({
                                countryCode,
                                currencyCode: countryCode === 'es-CL' ? 'CLP' : 'PEN',
                                amount: data.amount,
                              })
                            }
                          </p>
                        </Col>
                      </Row>
                    );
                  })}

                <Row justify="end" className="total-price mt-15 mb-10">
                  <Col lg={4} md={5} sm={6} xs={10}>
                    <p>{t('billing.modalDetail.totalValueTitle')}</p>
                  </Col>
                  <Col lg={5} md={4} sm={4} xs={8}>
                    <p className="ml-lg-10" style={{ textAlign: 'right' }}>
                      {bill?.currency && getNumberFormatted({
                        countryCode: bill.currency.locate_code,
                        currencyCode: bill.currency.code,
                        amount: bill.total_amount
                      })}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default ModalDetail;
