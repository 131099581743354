import React, { useState } from 'react'
import { Row, Col, Modal, Form, Button, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import PasswordStrengthBar from 'react-password-strength-bar'

const SetPasswordModal = ({
  modalVisible,
  setModalVisible,
  savePassword,
}) => {
  const [form] = Form.useForm()
  const [password, setPassword] = useState('')
  const [passwordStrength, setPasswordStrength] = useState()
  const { t } = useTranslation();

  const closeModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const onFinish = (values) => {
    if (form.validateFields()) {
      savePassword(values);
      closeModal();
    }
  }

  const validatePassword = (_, value) => {
    if (passwordStrength <= 1) {
      return Promise.reject('Contraseña muy débil');
    }
    return Promise.resolve();
  };

  return (
    <Modal
      open={modalVisible}
      onCancel={closeModal}
      footer={false}
      centered
      width={`85vw`}
      className="set-passpord-modal"
    >
      <Row className='pl-lg-60 pr-lg-60 pt-lg-30 pb-lg-30'>
        <Col lg={24} xs={24}>
          <h2 className='mb-20'>Configurar contraseña</h2>
        </Col>
        <Col lg={24} xs={24}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            size="large"
          >
            <Row gutter={[0, 30]} justify='space-between'>
              <Col lg={11} xs={24}>
                <Form.Item
                  extra={t('registrationInReservation.passwordPolicy')}
                  name="password"
                  label="Contraseña"
                  className='cmt-form'
                  rules={[{
                    required: true,
                    message: 'Debes ingresar tu contraseña'
                  },
                  {
                    validator: validatePassword,
                  }]}
                >
                  <Input
                    type="password"
                    placeholder="Contraseña nueva"
                    className='cmt-input'
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </Form.Item>
                <PasswordStrengthBar
                  password={password}
                  style={{
                    width: '100%',
                    display: 'none'
                  }}
                  onChangeScore={(score) => {
                    setPasswordStrength(score);
                  }}
                  className="password-strength-bar"
                  shortScoreWord="Muy débil"
                  minLength={8}
                  scoreWords={[
                    "Muy débil",
                    "Débil",
                    "Regular",
                    "Buena",
                    "Fuerte",
                  ]}
                />
              </Col>
              <Col lg={11} xs={24}>
                <Form.Item
                  name="passwordConfirmation"
                  label="Repita contraseña"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Debes repetir la contraseña'
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && getFieldValue('password') === value) {
                          return Promise.resolve()
                        }
                        return Promise.reject(new Error('Las contraseñas deben coincidir'))
                      }
                    })
                  ]}
                >
                  <Input
                    type="password"
                    placeholder="Contraseña nueva"
                    className='cmt-input'
                  />
                </Form.Item>
              </Col>
              <Col lg={24} xs={24}>
                <Row justify='center'>
                  <Col xl={5} lg={7} xs={24}>
                    <Button
                      htmlType='submit'
                      className='cmt-button primary'
                      type='primary'
                    >
                      Guardar contraseña
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default SetPasswordModal;