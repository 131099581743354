import React, { useEffect, useState } from 'react';
import { Row, Col, Popover, Button, Menu, Drawer, Avatar } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import ChileFlag from 'cmt-ui-kit/src/images/ChileFlag.svg';
import PeruFlag from 'cmt-ui-kit/src/images/PeruFlag.svg';
import { Link } from 'gatsby';
import Info from './Info';

const { SubMenu } = Menu;

const MobilePrivateLayout = ({
  t,
  data,
  handlers,
  dataName,
  userData,
  HasAccess,
  countryCode,
  handleLogout,
  companyUser,
}) => {
  const [visible, setVisible] = useState(false);
  const [currentCountry, setCurrentCountry] = useState({
    name: null,
    flag: null,
    url: null,
  });

  useEffect(() => {
    if (countryCode === 'CL') {
      setCurrentCountry(() => ({
        name: 'Chile',
        flag: ChileFlag,
        url: 'https://www.cmtsalud.cl/',
      }));
    } else {
      setCurrentCountry(() => ({
        name: 'Perú',
        flag: PeruFlag,
        url: 'https://www.cmtsalud.pe/',
      }));
    }
  }, [countryCode]);

  const countryPopoverContent = () => {
    const handleRedirect = (selectedCountryCode) => {
      if (countryCode !== selectedCountryCode) {
        window.location.replace(`https://www.cmtsalud.${selectedCountryCode.toLowerCase()}/`);
      }
    };

    return (
      <Row gutter={[0, 10]}>
        <Col lg={24}>
          <Button
            className='cmt-button disabled'
            type='primary'
            onClick={() => handleRedirect('CL')}
          >
            <img src={ChileFlag} alt={'Bandera Chile'} width='35' />
            &nbsp;Chile
          </Button>
        </Col>
        <Col lg={24}>
          <Button
            className='cmt-button disabled'
            type='primary'
            onClick={() => handleRedirect('PE')}
          >
            <img src={PeruFlag} alt={'Bandera Perú'} width='35' />
            &nbsp;Perú
          </Button>
        </Col>
      </Row>
    );
  };

  const handleDrawerVisibility = () => {
    setVisible(!visible);
  };


  return (
    <Row justify='end' align="middle" className="menu-mobile" gutter={23}>
      <Col className='country-wrapper'>
        {
          currentCountry.name &&
          <Popover
            trigger='click'
            placement='bottom'
            overlayClassName='country-home-popover'
            title='Selecciona país'
            content={countryPopoverContent}
          >
            <Button className='cmt-button disabled' type='primary'>
              <img
                src={currentCountry.flag}
                alt={currentCountry.name + 'bandera'}
                width='35'
              />
              <strong>&nbsp;{currentCountry.name}</strong>
              <span className='icon-system-chevron-down ml-5' />
            </Button>
          </Popover>
        }
      </Col>

      <Col className='menu-wrapper'>
        <Button
          className="menu-mobile-button"
          shape="circle"
          onClick={handleDrawerVisibility}
          icon={!visible ? <MenuOutlined /> : <span className='icon-system-close' />}
        />
      </Col>

      <Drawer
        open={visible}
        onClose={handleDrawerVisibility}
        placement="right"
        closable={false}
        className="private-layout-drawer"
      >
        <Row className='drawer-container'>
          <Col xs={24} className='menu-mobile-wrapper'>
            <Menu mode="inline">
              <Menu.Item key="1">
                <Link to="/app/" activeClassName="active">
                  Inicio
                </Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/app/reservas/" activeClassName="active">
                  Mis reservas
                </Link>
              </Menu.Item>
              <HasAccess
                permissions="can_view_statistics"
              >
                <Menu.Item key="3">
                  <Link to="/app/estadisticas/" activeClassName="active">
                    Estadísticas
                  </Link>
                </Menu.Item>
              </HasAccess>
              <HasAccess
                permissions="can_view_invoices"
              >
                <Menu.Item key="4">
                  <Link to="/app/facturacion/" activeClassName="active">
                    Facturación
                  </Link>
                </Menu.Item>
              </HasAccess>
              <HasAccess
                permissions="medical_administration"
              >
                <Menu.Item key="5">
                  <Link to="/app/medico/" activeClassName="active">
                    Médico
                  </Link>
                </Menu.Item>
              </HasAccess>
              <HasAccess
                permissions="can_reserve"
              >
                <Menu.Item key="6">
                  <Link to="/app/reservar/" activeClassName="active">
                    <strong>{t('navbar.scheduleTime')}</strong>
                  </Link>
                </Menu.Item>
              </HasAccess>
              <SubMenu
                key="6"
                title={
                  <Row className="user-wrapper">
                    <Col className="mr-10">
                      <p className="mb-0">{dataName}</p>
                    </Col>
                    <Col>
                      <Avatar>{userData}</Avatar>
                    </Col>
                  </Row>
                }
              >
                <Menu.Item key="sub1">
                  <Link to="/app/perfil-usuario/" activeClassName="active">
                    Perfil usuario
                  </Link>
                </Menu.Item>
                {
                  (companyUser && companyUser.length > 0)
                  && <Menu.Item key="sub2">
                    <Link to="/app/perfil-empresa/" activeClassName="active">
                      Perfil empresa
                    </Link>
                  </Menu.Item>
                }
                <Menu.Item key="sub3">
                  <p aria-label="Cerrar sesión" onClick={handleLogout}>
                    Cerrar sesión
                  </p>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
          <Col xs={24} className='info-mobile-wrapper'>
            <Info device='device-mobile' data={data} splitPhone={handlers.splitPhone} countryCode={countryCode} />
          </Col>
        </Row>
      </Drawer>
    </Row>
  );
};

export default MobilePrivateLayout;
