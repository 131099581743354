import { useState } from 'react';
import { Row, Col, Form } from 'antd';
import MedicalCare from './components/MedicalCare';
import Expirations from './components/Expirations';
import useGeneral from './hooks/useGeneral';
import Header from './components/Header';
import { useTranslation } from 'react-i18next';

const MedicalMonitoring = () => {
  const [currentPage, setCurrentPage] = useState('medical_care');
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const {
    loading,
    companies,
    branchOffices,
    filters,
    setFilters,
    expirationPage,
    setExpirationPage,
    cie10Page,
    setCIE10Page,
    dataMedical,
    dataExpirations,
    dataCIE10Reports,
    fetchExpirations,
    fetchCIE10Reports,
  } = useGeneral(form);


  return (
    <Row className='medical-main-container mt-40'>
      <Col lg={24} xs={24}>
        <Row justify='center'>
          <Col xl={21} lg={22} xs={22}>
            <Header
              t={t}
              form={form}
              loading={loading}
              companies={companies}
              branchOffices={branchOffices}
              filters={filters}
              setFilters={setFilters}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setExpirationPage={setExpirationPage}
            />
            {
              currentPage === 'medical_care' ?
                <MedicalCare
                  t={t}
                  loading={loading}
                  filters={filters}
                  dataMedical={dataMedical}
                  dataCIE10={dataCIE10Reports}
                  cie10Page={cie10Page}
                  setCIE10Page={setCIE10Page}
                  fetchCIE10Reports={fetchCIE10Reports}
                />
              :
                <Expirations
                  loading={loading}
                  data={dataExpirations}
                  filters={filters}
                  expirationPage={expirationPage}
                  setExpirationPage={setExpirationPage}
                  fetchExpirations={fetchExpirations}
                />
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MedicalMonitoring;