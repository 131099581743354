import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../config';

const Captcha = ({ recaptchaRef }) => {

  return (
    <ReCAPTCHA
      ref={recaptchaRef}
      size="invisible"
      sitekey={config.captcha_key}
    />
  )
}

export default Captcha