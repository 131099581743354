import config from "../config";

const reservationDetail = async (params) => {
  const action = params.from === "public" ? "publicPreAdmissions" : "preAdmissions"
  const url = new URL(`${config.client_api}/${action}/getReservation`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
  const dataResponse = response.json()
  return dataResponse
}

const reservationPatients = async (params) => {
  const action = params.from === "public" ? "publicPreAdmissions" : "preAdmissions"
  const url = new URL(`${config.client_api}/${action}/getPatients`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
  const dataResponse = response.json()
  return dataResponse
}

const reservationServices = async (params) => {
  const action = params.from === "public" ? "publicPreAdmissions" : "preAdmissions"
  const url = new URL(`${config.client_api}/${action}/getServices`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
  const dataResponse = response.json()
  return dataResponse
}

const createPreAdmission = async (params, data) => {
  const action = params.from === "public" ? "publicPreAdmissions" : "preAdmissions"
  const url = `${config.client_api}/${action}/createPreAdmissions?from=${params.from}`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(data)
  })
  const dataResponse = response.json()
  return dataResponse
}

export const preAdmissionService = {
  reservationPatients,
  reservationDetail,
  createPreAdmission,
  reservationServices
}