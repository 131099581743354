import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { Row, Col, Spin } from "antd";
import { reduce, map, partition, pickBy, maxBy } from "lodash";
import { dashboardService } from "../../../../services/dashboard";
import { useTranslation } from "react-i18next";

const dateFormat = "YYYY-MM-DD";

const BatteriesList = ({ form }) => {
  const [batteries, setBatteries] = useState();
  const [batteriesChart, setBatteriesChart] = useState();
  const [loading, setLoading] = useState(false);
  const pieChartColors = [
    "#8D7299",
    "#E78B6A",
    "#6FB9A7",
    "#E09396",
    "#80B8DE",
    "#F5D764",
    "#59867A",
    "#AF5253",
    "#638EAB",
    "#5E4D66",
    "#9197DF",
    "#AFC4BF",
  ];
  const { t } = useTranslation();

  const getFilters = () => {
    const params = {
      startDate: form.getFieldValue("dates")[0].format(dateFormat),
      endDate: form.getFieldValue("dates")[1].format(dateFormat),
      companyId: form.getFieldValue("company"),
    };
    return Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined)
    );
  };


  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - 23 - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const getOthers = (items) => {
    const object = { name: "Otros", total: 0, approved: 0, disapproved: 0 };
    for (let item of items) {
      object["total"] += item.total;
      object["approved"] += item.approved;
      object["disapproved"] += item.disapproved;
    }

    return object;
  };

  const getPareto = (array) => {

    let total = reduce(map(array, "total"), (sum, n) => {
      return sum + n;
    });
    
    let items = map(array, (item, index) => {
      var percentage = (item.total * 100) / total;
      item["percentage"] =
      index === 0 ? percentage : array[index - 1].percentage + percentage;
      return item;
    });
    
    let data = partition(items, (item) => {
      return item.percentage < 80;
    });

    if (data[1].length) {
      data[0].push(data[1][0])
    }

    const otherPrestations = getOthers(data[1]);

    if (array.length > 1 && otherPrestations["total"] > 0) {
      data[0].push(otherPrestations);
    }

    setBatteries(data[0]);
    setBatteriesChart(data[0]);
  };

  const getBatteryInformation = async () => {
    setLoading(true);
    const filters = getFilters();
    const response = await dashboardService.byBattery(filters);
    let list = response.map((item) => {
      item["total"] =
        parseInt(item["approved"]) + parseInt(item["disapproved"]);
      return item;
    });
    list = list.filter((item) => item.total > 0);
    getPareto(list);
    setLoading(false);
  };

  const approvedPercentage = (data) => {
    const total = parseInt(data.approved) + parseInt(data.disapproved);
    return Math.round((parseInt(data.approved) / total) * 100);
  };

  const disapprovedPercentage = (data) => {
    const total = parseInt(data.approved) + parseInt(data.disapproved);
    return Math.round((parseInt(data.disapproved) / total) * 100);
  };

  const batteriesMessage = () => {
    const maxPrestations = maxBy(batteries, (battery) => {
      if (battery?.total) {
        return parseInt(battery.total);
      }
    });

    const maxAssistance = maxBy(batteries, (battery) => {
      if (battery?.percentage) {
        return approvedPercentage(battery);
      }
    });

    if (maxPrestations?.name === maxAssistance?.name) {
      return `La prestación más realizada fue la de ${maxPrestations.name} y además fue la prestación que más aprobados obtuvo.`;
    }

    return `La prestación más realizada fue la de ${maxPrestations.name}. ${maxAssistance.name} fue la prestación que más aprobados obtuvo.`;
  };

  useEffect(() => {
    getBatteryInformation();
  }, [
    form.getFieldValue("company"),
    form.getFieldValue("dates")[0].format(dateFormat),
    form.getFieldValue("dates")[1].format(dateFormat),
  ]);

  return (
    <Spin spinning={loading} className="cmt-spin">
      <Row className="batteries-information mb-lg-70 mb-md-40 mb-xs-40">
        <Col lg={24} xs={24} className="mb-lg-15 mb-md-5 mb-xs-5">
          <h2 id="services-info" className="services-info">
            {t('dashboard.batteriesList.infoByServices')}
          </h2>
        </Col>
        {
          batteries?.length > 0 && (
            <Col lg={24} xs={24} className="batteries-notice">
              <h3 className="pt-20 pb-15 ml-lg-40 ml-md-15 ml-xs-15 mr-lg-15 mr-md-10 mr-xs-10">
                {batteriesMessage()}
              </h3>
            </Col>
          )
        }
        <Col
          xl={17}
          lg={18}
          xs={24}
          className="data-table mt-lg-50 mt-md-20 mt-xs-20"
        >
          <Row justify="center" className="data-titles-desktop-wrapper">
            <Col lg={10}>
              <h3 className="ml-10 mb-15">{t('dashboard.batteriesList.serviceTitle')}</h3>
            </Col>
            <Col lg={4}>
              <h3 className="text-center mb-15">{t('dashboard.batteriesList.totalTitle')}</h3>
            </Col>
            <Col lg={4}>
              <h3 className="text-center mb-15">{t('dashboard.batteriesList.approvedTitle')}</h3>
            </Col>
            <Col lg={4}>
              <h3 className="text-center mb-15">{t('dashboard.batteriesList.notApprovedTitle')}</h3>
            </Col>
          </Row>
          {
            batteries?.map((data, index) => {
              if (data) {
                return (
                  <Row
                    justify="center"
                    key={index}
                    className={`gray-content-mobile-wrapper mb-md-15 mb-xs-15 ${
                      index % 2 === 0 && "gray-row"
                    }`}
                  >
                    <Col
                      xs={24}
                      className="branch-office-content pt-md-20 pt-xs-20 pb-md-20 pb-xs-20 pl-md-15 pl-xs-15 pr-md-15 pr-xs-15"
                    >
                      <Row>
                        <Col lg={11} xs={14} className="mb-md-20 mb-xs-20">
                          <Row justify="center">
                            <Col xs={24} className="title-mobile-wrapper">
                              <h3>{t('dashboard.batteriesList.serviceTitle')}</h3>
                            </Col>
                            <Col lg={24} xs={24} className="data-wrapper">
                              <p className="ml-lg-30 ml-xs-0 mb-0 pt-lg-15 pb-lg-15">
                                {data.name}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} xs={10}>
                          <Row justify="center">
                            <Col xs={24} className="title-mobile-wrapper">
                              <h3>{t('dashboard.batteriesList.totalTitle')}</h3>
                            </Col>
                            <Col lg={24} xs={24} className="data-wrapper">
                              <p className="text-center mb-0 pt-lg-15 pb-lg-15">
                                {parseInt(data.total)}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} xs={14}>
                          <Row justify="center">
                            <Col xs={24} className="title-mobile-wrapper">
                              <h3>{t('dashboard.batteriesList.approvedTitle')}</h3>
                            </Col>
                            <Col lg={24} xs={24} className="data-wrapper">
                              <p className="text-center mb-0 pt-lg-15 pb-lg-15">
                                {approvedPercentage(data)}%
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={4} xs={10}>
                          <Row justify="center">
                            <Col xs={24} className="title-mobile-wrapper">
                              <h3>{t('dashboard.batteriesList.notApprovedTitle')}</h3>
                            </Col>
                            <Col lg={24} xs={24} className="data-wrapper">
                              <p className="text-center mb-0 pt-lg-15 pb-lg-15">
                                {disapprovedPercentage(data)}%
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )
              } else {
                return null
              }
            })
          }
        </Col>
        <Col
          lg={17}
          xs={24}
          className="pie-chart-wrapper mt-lg-50 mt-md-30 mt-xs-30"
        >
          <Row justify="space-around" align="middle">
            <Col lg={10} xs={24}>
              <PieChart className="pie-chart" width={300} height={300}>
              {
                batteriesChart && (
                  <Pie
                    data={batteriesChart}
                    cx={150}
                    cy={140}
                    innerRadius={65}
                    outerRadius={110}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#8884d8"
                    paddingAngle={2}
                    dataKey="total"
                  >
                  {
                    batteriesChart.map((data, index) => {
                      if (data) {
                        return (
                          <Cell
                            key={`cell-${index}`}
                            fill={pieChartColors[index % pieChartColors.length]}
                          />
                        )
                      } else {
                        return null
                      }
                    })
                  }
                  </Pie>
                )}
                <Tooltip />
              </PieChart>
            </Col>
            <Col lg={11} xs={22}>
            {
              batteriesChart?.map((data, index) => {
                if (data) {
                  return (
                    <Row justify="center" key={index} className="mb-xs-5">
                      <Col
                        lg={2}
                        xs={2}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          className="chart-dot"
                          style={{
                            backgroundColor: `${
                              pieChartColors[index % pieChartColors.length]
                            }`,
                          }}
                        />
                      </Col>
                      <Col lg={22} xs={22}>
                        <p>{data.name}</p>
                      </Col>
                    </Row>
                  )
                } else {
                  return null
                }
              })
            }
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default BatteriesList;
