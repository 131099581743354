import { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { medicalService } from '../../../../services/medical';
import { message } from 'antd';

const useGeneral = (form) => {
  const [companies, setCompanies] = useState([]);
  const [branchOffices, setBranchOffices] = useState([]);
  const [loading, setLoading] = useState({
    byBranchOffice: false,
    byAgeGender: false,
    byCareType: false,
    branchOffices: false,
    companies: false,
    expirationsTable: false,
    cie10Reports: false,
  });
  const [filters, setFilters] = useState({
    startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  });
  const [dataMedical, setDataMedical] = useState({
    byBranchOffice: [],
    mostApprovedRange: false,
    byAgeGender: [],
    byCareType: [],
  });
  const [dataExpirations, setDataExpirations] = useState({
    critical: false,
    warning: false,
    normal: false,
    expired: false,
    observed: false,
  });
  const [expirationPage, setExpirationPage] = useState({
    critical: 1,
    warning: 1,
    normal: 1,
    expired: 1,
    observed: 1,
  });
  const [dataCIE10Reports, setDataCIE10Reports] = useState({
    frequent: false,
  });
  const [cie10Page, setCIE10Page] = useState({
    frequent: 1,
  });


  const fetchBranchOffices = useCallback(async (params) => {
    setLoading(loading => ({ ...loading, branchOffices: true }));
    try {
      const response = await medicalService.getBranchOffices(params);
      if (response) {
        setBranchOffices(response);
        setFilters({ ...filters, branchOfficeId: response[0].id });
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error('Error en fetchBranchOffices', error);
      message.error({
        content: 'Ha ocurrido un error al obtener las sedes',
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setLoading(loading => ({ ...loading, branchOffices: false }));
    }
  }, [branchOffices]);

  const fetchCompanies = useCallback(async () => {
    setLoading(loading => ({ ...loading, companies: true }));

    try {
      const response = await medicalService.getCompanies({ 'permission': 'medical_administration' });
      
      if (response?.length > 0) {
        fetchBranchOffices({ companies: response.map(company => company.id) });
        setCompanies(response);
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error('Error en fetchCompanies', error);
      message.error({
        content: 'Ha ocurrido un error al obtener las empresas',
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setLoading(loading => ({ ...loading, companies: false }));
    }
  }, []);

  const fetchByBranchOffice = useCallback(async (params) => {
    setLoading(loading => ({ ...loading, byBranchOffice: true }));
    try {
      const response = await medicalService.getByBranchOffice(params);
      
      if (response?.length > 0) {
        setDataMedical(dataMedical => ({ ...dataMedical, byBranchOffice: response }));
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error('Error en fetchByBranchOffice', error);
      message.error({
        content: 'Ha ocurrido un error al obtener datos por sedes',
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setLoading(loading => ({ ...loading, byBranchOffice: false }));
    }
  }, [filters]);

  const fetchByTypeOfCare = useCallback(async (params) => {
    setLoading(loading => ({ ...loading, byCareType: true }));
    try {
      const response = await medicalService.getByTypeOfCare(params);

      if (response?.length > 0) {
        setDataMedical(dataMedical => ({ ...dataMedical, byCareType: response }));
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error('Error en fetchByTypeOfCare', error);
      message.error({
        content: 'Ha ocurrido un error al obtener datos por tipo de atención',
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setLoading(loading => ({ ...loading, byCareType: false }));
    }
  }, [filters]);

  const fetchByAgeAndGender = useCallback(async (params) => {
    setLoading(loading => ({ ...loading, byAgeGender: true }));
    try {
      const response = await medicalService.getAgeGenderRange(params);

      if (response?.length > 0) {
        const approved = response.filter(item => item.type === "approved")[0];
        const approvedResults = approved.result;
        let maxTotal = 0;
        let maxRange = '';

        for (const range in approvedResults) {
          const { male, female } = approvedResults[range];
          const total = male + female;

          if (total > maxTotal) {
            maxTotal = total;
            maxRange = range;
          }
        }

        let chartData = [];

        Object.keys(response[0].result).forEach(ageRange => {
          const rangeData = { range: ageRange };

          response.forEach(item => {
            const type = item.type;
            const sum = item.result[ageRange].male + item.result[ageRange].female;
            rangeData[type] = sum;
            rangeData[`${type}-male`] = item.result[ageRange].male;
            rangeData[`${type}-female`] = item.result[ageRange].female;
          });

          chartData.push(rangeData);
        });

        setDataMedical(dataMedical => ({ ...dataMedical, mostApprovedRange: maxRange, byAgeGender: chartData }));
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error('Error en fetchByAgeAndGender', error);
      message.error({
        content: 'Ha ocurrido un error al obtener datos de aptitud por rango de edad y género',
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setLoading(loading => ({ ...loading, byAgeGender: false }));
    }
  }, [filters]);


  const fetchExpirations = useCallback(async (filters, currentTabName, paginationValue) => {
    setLoading(loading => ({ ...loading, expirationsTable: true }));

    let types = ['critical', 'warning', 'normal', 'expired', 'observed'];

    if (currentTabName) {
      types = [currentTabName];
    }

    const responses = await Promise.all(types.map(async (type) => {
      try {
        const response = await medicalService.getMedicalExpirations({
          ...filters,
          type,
          page: paginationValue || expirationPage[type],
        });

        if (response?.data) {
          setDataExpirations(dataExpirations => ({ ...dataExpirations, [type]: response }));
        } else {
          throw new Error(response);
        }
      } catch (error) {
        console.error(`Error en fetchExpirations para tipo ${type}`, error);
        message.error({
          content: 'Ha ocurrido un error al obtener datos de Vencimientos y observados',
          style: {
            marginTop: '35vh',
          },
        });
      } finally {
        setLoading(loading => ({ ...loading, expirationsTable: false }));
      }
    }));
  }, [filters]);


  const fetchCIE10Reports = useCallback(async (filters, currentTabName, paginationValue) => {
    setLoading(loading => ({ ...loading, cie10Reports: true }));

    let types = ['frequent'];

    if (currentTabName) {
      types = [currentTabName];
    }

    const responses = await Promise.all(types.map(async (type) => {
      try {
        const response = await medicalService.getCIE10Reports({
          ...filters,
          type,
          page: paginationValue || cie10Page[type],
        });

        if (response?.data) {
          setDataCIE10Reports(dataCIE10Reports => ({ ...dataCIE10Reports, [type]: response }));
        } else {
          throw new Error(response);
        }
      } catch (error) {
        console.error(`Error en fetchCIE10Reports para tipo ${type}`, error);
        message.error({
          content: 'Ha ocurrido un error al obtener datos de Reporte General CIE 10',
          style: {
            marginTop: '35vh',
          },
        });
      } finally {
        setLoading(loading => ({ ...loading, cie10Reports: false }));
      }
    }));
  }, [filters]);


  const handleFormFilters = (filters) => {
    form.setFieldsValue({
      query: filters.query,
      range: [moment(filters.startDate), moment(filters.endDate)],
      branchOfficeId: filters.branchOfficeId,
      companyId: filters.companyId,
    });
  };

  const handleFetchs = (filters) => {
    if (branchOffices.length > 0 && filters.companyId && filters.branchOfficeId) {
      fetchByBranchOffice(filters);
      fetchByAgeAndGender(filters);
      fetchByTypeOfCare(filters);
      fetchExpirations(filters);
      fetchCIE10Reports(filters);
    }
  };


  useEffect(() => {
    handleFetchs(filters);
    handleFormFilters(filters);
  }, [filters]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);


  return {
    loading,
    companies,
    branchOffices,
    filters,
    setFilters,
    expirationPage,
    setExpirationPage,
    cie10Page,
    setCIE10Page,
    dataMedical,
    dataExpirations,
    dataCIE10Reports,
    fetchExpirations,
    fetchCIE10Reports,
  };
};

export default useGeneral;