import React, { Fragment } from 'react'
import { Row, Col, Button, Form, Input, Tooltip, Radio } from 'antd'
import { PhoneInput } from 'cmt-ui-kit';

const CostsCenterForm = ({
  form,
  loading,
  closeModal,
  formAction,
  currentCostCenter,
  showNewCostCenterModalVisible,
  saveCostCenter,
  countryCode,
}) => {

  const handleNewCostCenter = () => {
    closeModal();
    showNewCostCenterModalVisible();
  };

  const tooltipContent = (
    <Row>
      <Col lg={24} xs={24}>
        <Row>
          <Col>
            <span className='icon-alert-rounded' />
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <h3 className='mb-0'>Edición</h3>
          </Col>
        </Row>
      </Col>
      <Col lg={24} xs={24}>
        <p className='mb-0'>
          Si necesitas crear un Nuevo Centro de Costo
          <a
            className='ml-5'
            onClick={handleNewCostCenter}
            aria-label='abrir'
            style={{ textDecoration: 'underline' }}
          >
            haz click aquí
          </a>.
        </p>
      </Col>
    </Row>
  )

  return (
    <Fragment>
      <Form
        form={form}
        onFinish={saveCostCenter}
        layout="vertical"
        size="large"
        className='cmt-form'
      >
        <Row justify='center'>
          <Col
            lg={24}
            xs={24}
            className="user-header-wrapper mt-20 mb-20 pt-40 pb-10"
          >
            <Row>
              <Col lg={24} xs={24}>
                {
                  formAction === "edit" &&
                  <Fragment>
                    <p className='text-center bold-primary mb-0'>Centro de costo</p>
                    <h1 className='text-center mb-0'>{currentCostCenter.name} {currentCostCenter.code}</h1>
                  </Fragment>
                }
                {
                  formAction === "create" &&
                  <h1 className='text-center mb-0'>Crear centro de costo</h1>
                }
              </Col>
              <Col lg={24} xs={24}>
                <Row justify='center' gutter={10}>
                  <Col style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='mb-0'>Centro de costo activo:</p>
                  </Col>
                  <Col>
                    <Form.Item
                      name="id"
                      hidden
                    >
                      <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                      name="active"
                      rules={[{
                        required: true,
                        message: 'Debes seleccionar actividad'
                      }]}
                      className='mb-0'
                    >
                      <Radio.Group className='cmt-radio'>
                        <Radio value={true}>Sí</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col lg={20} xs={22}>
            <Row justify='center'>
              <Col lg={24} xs={24}>
                <h2 className='mb-15'>Datos generales</h2>
              </Col>
              <Col lg={12} xs={24}>
                <Row>
                  <Col lg={21} xs={21}>
                    <Form.Item
                      name="name"
                      label="Nombre de centro de costo"
                      rules={[{
                        required: true,
                        message: "Nombre de centro de costo es requerido"
                      }]}
                    >
                      <Input
                        className='cmt-input'
                        placeholder='Ingrese nombre de centro de costo'
                        disabled={formAction === "edit"}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={3} xs={3} className="icon-container">
                    {
                      formAction === "edit" &&
                      <Tooltip
                        title={tooltipContent}
                        overlayClassName="info-tooltip"
                        color="#223A70"
                        placement='right'
                      >
                        <span className='icon-alert-rounded' />
                      </Tooltip>
                    }
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xs={24}>
                <Row>
                  <Col lg={21} xs={21}>
                    <Form.Item
                      name="code"
                      label="Código de centro de costo"
                      rules={[{
                        required: true,
                        message: "Código de centro de costo es requerido"
                      }]}
                    >
                      <Input
                        className='cmt-input'
                        placeholder='Ingrese código de centro de costo'
                        disabled={formAction === "edit"}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={3} xs={3} className="icon-container">
                    {
                      formAction === "edit" &&
                      <Tooltip
                        title={tooltipContent}
                        overlayClassName="info-tooltip"
                        color="#223A70"
                        placement='right'
                      >
                        <span className='icon-alert-rounded' />
                      </Tooltip>
                    }
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xs={24}>
                <Row>
                  <Col lg={21} xs={24}>
                    <Form.Item
                      name="responsibleFullName"
                      label="Nombre completo responsable centro de costo"
                      rules={[{
                        required: true,
                        message: "Nombre completo del responsable es requerido"
                      }]}
                    >
                      <Input className='cmt-input' placeholder='Ingrese nombre completo del responsable' />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xs={24}>
                <Row>
                  <Col lg={21} xs={21}>
                    <Form.Item
                      name="email"
                      label="Correo electrónico responsable centro de costo"
                      rules={[{
                        required: true,
                        message: "Correo electrónico del responsable es requerido"
                      }]}
                    >
                      <Input
                        className='cmt-input'
                        placeholder='Ingrese correo electrónico del responsable'
                        disabled={formAction === "edit"}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={3} xs={3} className="icon-container">
                    {
                      formAction === "edit" &&
                      <Tooltip
                        title={tooltipContent}
                        overlayClassName="info-tooltip"
                        color="#223A70"
                        placement='right'
                      >
                        <span className='icon-alert-rounded' />
                      </Tooltip>
                    }
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xs={24}>
                <Row>
                  <Col lg={21} xs={24}>
                    <PhoneInput.ReactPhone
                      countryCode={countryCode}
                      form={form}
                      labelText='Teléfono responsable centro de costo'
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={12} xs={24} />
              <Col lg={24} xs={24}>
                <Row justify='center' className='mt-20'>
                  <Col xl={5} lg={6} xs={24}>
                    <Button
                      loading={loading}
                      htmlType='submit'
                      className='cmt-button primary'
                      type='primary'
                    >
                      {
                        formAction === "edit" ? "Guardar cambios" : "Crear centro de costo"
                      }
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Fragment>
  )
}

export default CostsCenterForm