import { usePermify } from '@permify/react-role';
import { Button, Col, Form, Input, Row, message } from "antd";
import { DocumentCard, PhoneInput } from 'cmt-ui-kit';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PasswordStrengthBar from "react-password-strength-bar";
import "react-phone-input-2/lib/style.css";
import "../css/scheduleTimePrivate.less";
import { setAuth } from "../services/auth";
import { scheduleTimeService } from "../services/scheduleTime";

const { format } = require("rut.js");

const RegistrationInReservation = ({
  checkIfUserIsLogged,
  setType,
  countryCode,
}) => {
  const [form] = Form.useForm();
  const [password, setPassword] = useState('');
  const [passwordStrength, setPasswordStrength] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [documentType, setDocumentType] = useState();
  const { setUser } = usePermify();

  useEffect(() => {
    if (countryCode === 'CL') {
      form.setFieldsValue({ documentType: 'rut' });
      setDocumentType('rut');
    } else {
      form.setFieldsValue({ documentType: 'dni' });
      setDocumentType('dni');
    }
  }, []);

  const onFinish = async (values) => {
    try {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\s\S]*[#?!@$%^&*-]).{8,}$/;
      if (!regex.test(values.password)) {
        throw new Error('Invalid password')
      }
      setLoading(true);
      values.document_number = values.documentType === 'rut' ? format(values.documentNumber, { dots: false }) : values.documentNumber;
      values.document_type = values.documentType;
      values.phone = `+${values.phone}`;

      delete values.documentNumber;
      delete values.documentType;
      const data = await scheduleTimeService.registration(values, countryCode);

      if (data?.code === 200) {
        setAuth(data.data, setUser);
        checkIfUserIsLogged();
      } else {
        if (data.data?.email) {
          message.error({
            content: `Error en Correo: ${data.data.email[0]}`,
            style: {
              marginTop: '45vh',
            },
          });
          console.error(`Error en Correo: ${data.data.email[0]}`);
        } else if (data.data?.message) {
          message.error({
            content: data.data.message,
            style: {
              marginTop: '45vh',
            },
          });
          console.error(data.data.message);
        } else {
          message.error({
            content: 'Error interno',
            style: {
              marginTop: '45vh',
            },
          });

          console.error('Error interno');
        }
      }
    } catch (error) {
      message.error({
        content: `Error: ${error}`,
        style: {
          marginTop: '45vh',
        },
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const validatePassword = (_, value) => {
    if (passwordStrength <= 1) {
      return Promise.reject('Contraseña muy débil');
    }
    return Promise.resolve();
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Row justify="center" className="registration-reservation-container mt-30">
        <Col lg={22} xs={22}>
          <Row justify="center">
            <Col
              lg={20}
              xs={20}
              className="registration-form-wrapper pt-30 pb-30 pl-30 pr-30"
            >
              <Row justify="center">
                <Col lg={24} xs={24}>
                  <h2 className="mb-30">
                    {t('registrationInReservation.title')}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={22} xs={22} className="mb-20 mb-xs-0">
                  <Row justify="space-between" gutter={30}>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "Debes ingresar tu nombre",
                          },
                        ]}
                      >
                        <Input className="cmt-input" placeholder="Nombre" />
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "Debes ingresar tu apellido",
                          },
                        ]}
                      >
                        <Input className="cmt-input" placeholder="Apellido" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={22} xs={22} className="mb-20 mb-xs-0">
                  <Row justify="space-between" gutter={30}>
                    <Col lg={12} xs={24}>
                      <DocumentCard
                        form={form}
                        type='person'
                        countryCode={countryCode}
                        documentType={documentType}
                        setDocumentType={setDocumentType}
                      />
                    </Col>
                    <Col lg={12} xs={24}>
                      <PhoneInput.ReactPhone
                        countryCode={countryCode}
                        form={form}
                        labelText=''
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={22} xs={22} className="mb-20">
                  <Row justify="space-between" gutter={30}>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        name="email"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Debes ingresar tu correo electrónico",
                          },
                        ]}
                      >
                        <Input className="cmt-input" type="email" placeholder="Correo electrónico" />
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        hasFeedback
                        name="emailConfirmation"
                        rules={[
                          {
                            required: true,
                            message: "Debes confirmar tu correo electrónico",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("email") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Los emails que introducidos no coinciden."
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input
                          className="cmt-input"
                          type="email"
                          placeholder="Repetir correo electrónico"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row justify="center">
                <Col lg={24} xs={24}>
                  <h3 className="mb-20">
                    {t('registrationInReservation.generatePasswordText')}
                  </h3>
                </Col>
              </Row>
              <Row>
                <Col lg={22} xs={22}>
                  <Row justify="space-between" gutter={30}>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        extra={t('registrationInReservation.passwordPolicy')}
                        hasFeedback
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Debes ingresar tu contraseña",
                          },
                          {
                            validator: validatePassword,
                          },
                        ]}
                      >
                        <Input.Password
                          className="cmt-input"
                          type="password"
                          placeholder="Contraseña"
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </Form.Item>
                      <PasswordStrengthBar
                        password={password}
                        onChangeScore={(score) => {
                          setPasswordStrength(score);
                        }}
                        className="password-strength-bar"
                        shortScoreWord="Muy débil"
                        minLength={8}
                        scoreWords={[
                          "Muy débil",
                          "Débil",
                          "Regular",
                          "Buena",
                          "Fuerte",
                        ]}
                      />
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        hasFeedback
                        name="passwordConfirmation"
                        rules={[
                          {
                            required: true,
                            message: "Debes confirmar la contraseña",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "Las contraseñas introducidas no coinciden."
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          className="cmt-input"
                          type="password"
                          placeholder="Repetir contraseña"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center" className="mt-30 mb-70">
            <Col lg={17} xs={22}>
              <Row justify="space-between" gutter={[30, 15]}>
                <Col lg={12} xs={24}>
                  <Button
                    loading={loading}
                    style={{ paddingRight: 0, paddingLeft: 0 }}
                    htmlType="submit"
                    className="cmt-button primary"
                    size="large"
                    block
                  >
                    {t('registrationInReservation.registerUserButton')}
                  </Button>
                </Col>
                <Col lg={12} xs={24}>
                  <Button
                    className="cmt-button primary outline"
                    size="large"
                    onClick={() => {
                      setType("login");
                    }}
                    block
                  >
                    {t('registrationInReservation.loginButton')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default RegistrationInReservation;
