import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, List, Card, Spin, message } from 'antd';
import { useParams } from '@reach/router';
import { preAdmissionService } from '../../../services/preAdmission';

const MainView = ({ from }) => {
  const [patients, setPatients] = useState();
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const fetchReservedData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await preAdmissionService.reservationPatients({ number: params.id, from });
      setPatients(response);
    } catch (error) {
      console.error(`Error al traer datos del paciente: ${error}`);
      message.error({
        content: `Error al traer datos del paciente: ${error}`,
        style: {
          marginTop: '45vh',
        },
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchReservedData();
  }, [fetchReservedData]);


  return (
    <Spin spinning={loading} className='cmt-spin'>
      <Row justify="center mt-20">
        <Col lg={12} md={22} xs={22}>
          <h2>Pre Admisión</h2>
        </Col>
      </Row>
      <Row justify="center">
        <Col lg={12} md={22} xs={22}>
          {
            patients && patients.map((patient, index) => {
              return (
                <Card
                  key={index}
                  style={{ width: '100%' }}
                  className="mb-20"
                  title={
                    <strong>{patient.patient.firstName} {patient.patient.lastName}
                    </strong>
                  }
                >
                  <Row>
                    <Col lg={24} xs={24}>
                      <List style={{ width: '100%' }}>
                        {
                          patient.services.map((service, inx) => {
                            return <List.Item key={inx}>{service.service_name}</List.Item>
                          })
                        }
                      </List>
                    </Col>
                  </Row>
                </Card>
              )
            })
          }
        </Col>
      </Row>
    </Spin>
  );
};

export default MainView;