import { Row, Col, Spin, Tabs } from 'antd';
import TableCIE10 from './TableCIE10';

const { TabPane } = Tabs;

const CIE10Reports = ({
  loading,
  filters,
  dataCie10,
  cie10Page,
  setCIE10Page,
  fetchCIE10Reports,
  }) => {

  const handleTabPaneName = (keyName) => {
    switch (keyName) {
      case 'frequent':
        return 'Diagnóstico frecuente';
      default:
        return '';
    };
  };


  return (
    <Spin className='cmt-spin' spinning={loading.cie10Reports}>
      <Row justify='center'>
        <Col lg={24} xs={24}>
          <Tabs className='cmt-tabs' defaultActiveKey={1}>
          {
            Object.keys(dataCie10).map((key, index) => {
              if (key) {
                return (
                  <TabPane
                    key={index + 1}
                    tab={<p className='mb-0'>{handleTabPaneName(key)}</p>}
                  >
                    <TableCIE10
                      filters={filters}
                      tabName={key}
                      tableData={dataCie10[key].data}
                      pagination={dataCie10[key].pagination}
                      cie10Page={cie10Page}
                      setCIE10Page={setCIE10Page}
                      fetchCIE10Reports={fetchCIE10Reports}
                      handleTabPaneName={handleTabPaneName}
                    />
                  </TabPane>
                )
              } else {
                return null
              }
            })
          }
          </Tabs>
        </Col>
      </Row>
    </Spin>
  );
};

export default CIE10Reports;