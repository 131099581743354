import React, { useState, useEffect } from "react";
import { Row, Col, Collapse, Spin } from "antd";
import { dashboardService } from "../../../../services/dashboard";
import { isMobile } from "react-device-detect";
import { maxBy } from "lodash";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;
const dateFormat = "YYYY-MM-DD";

const BranchOfficeList = ({ form }) => {
  const [branches, setBranches] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const getFilters = () => {
    const params = {
      startDate: form.getFieldValue("dates")[0].format(dateFormat),
      endDate: form.getFieldValue("dates")[1].format(dateFormat),
      companyId: form.getFieldValue("company"),
    };
    return Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined)
    );
  };

  const getBranchOfficeInformation = async () => {
    setLoading(true);
    const filters = getFilters();
    const response = await dashboardService.byBranchOffice(filters);
    setBranches(response);
    setLoading(false);
  };


  const asistance = (data) => {
    return Math.round(
      (parseInt(data.prestations) * 100) / parseInt(data.reserved)
    );
  };

  const branchMessage = () => {
    const maxPrestations = maxBy(branches, (branch) => {
      return parseInt(branch.prestations);
    });

    const maxAssistance = maxBy(branches, (branch) => {
      return asistance(branch);
    });

    if (!maxPrestations || !maxAssistance) {
      return "";
    }

    if (maxPrestations.branch_name === maxAssistance.branch_name) {
      return `${maxPrestations.branch_name} es la sucursal que más prestaciones atendió para su empresa. Siendo también la sucursal con mejor asistencia.`;
    } else {
      return `${maxPrestations.branch_name} es la sucursal que más prestaciones atendió para su empresa. La mejor asistencia se dio en la sucursal de ${maxAssistance.branch_name}.`;
    }
  };

  useEffect(() => {
    getBranchOfficeInformation();
  }, [
    form.getFieldValue("company"),
    form.getFieldValue("dates")[0].format(dateFormat),
    form.getFieldValue("dates")[1].format(dateFormat),
  ]);

  return (
    <Spin spinning={loading}>
      {branches && branches.length > 0 && (
        <Row
          justify="start"
          className="branch-office-general-information mb-lg-70 mb-md-40 mb-xs-40"
        >
          <Col lg={24} xs={24} className="mb-lg-15 mb-md-5 mb-xs-5">
            <h2 id="branch-office-info" className="branch-office-info">
              {t('dashboard.branchOfficeList.infoByBranchOfficeTitle')}
            </h2>
          </Col>

          {branches && branches.length > 1 && (
            <Col lg={24} xs={24} className="branch-office-notice">
              <h3 className="pt-20 pb-15 ml-lg-40 ml-md-15 ml-xs-15 mr-lg-15 mr-md-10 mr-xs-10">
                {branchMessage()}
              </h3>
            </Col>
          )}

          {isMobile ? (
            <Col xs={24} className="branch-office-collapse-wrapper mt-20">
              <Collapse
                className="branch-office-collapse"
                expandIconPosition="end"
                ghost
              >
                {branches &&
                  branches.map((data, index) => {
                    return (
                      <Panel
                        header={
                          <Row justify="start" className="branch-office-title">
                            <Col xs={24}>
                              <h2>{t('dashboard.branchOfficeList.branchOfficesTitle')}</h2>
                            </Col>
                            <Col xs={24}>
                              <p>{data.branch_name}</p>
                            </Col>
                          </Row>
                        }
                        key={index}
                      >
                        <Row className="branch-office-data-wrapper">
                          <Col xs={8}>
                            <Row justify="center">
                              <Col xs={24}>
                                <h3>{t('dashboard.branchOfficeList.patientsTitle')}</h3>
                              </Col>
                              <Col xs={24}>
                                <p>{data.patients}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={8}>
                            <Row justify="center">
                              <Col xs={24}>
                                <h3>{t('dashboard.branchOfficeList.servicesTitle')}</h3>
                              </Col>
                              <Col xs={24}>
                                <p>{data.prestations}</p>
                              </Col>
                            </Row>
                          </Col>
                          {/* <Col xs={12}>
                                                               <Row justify="center">
                                                                  <Col xs={24}>
                                                                     <h3>Actividad</h3>
                                                                  </Col>
                                                                  <Col xs={24}>
                                                                     <p>$ {formatToLocaleString(data.amount)}</p>
                                                                  </Col>
                                                               </Row>
                                                         </Col> */}
                          <Col xs={8}>
                            <Row justify="center">
                              <Col xs={24}>
                                <h3>{t('dashboard.branchOfficeList.attendanceTitle')}</h3>
                              </Col>
                              <Col xs={24}>
                                <p>{asistance(data)} %</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Panel>
                    );
                  })}
              </Collapse>
            </Col>
          ) : (
            <Col xl={17} lg={18} className="data-table mt-50">
              <Row justify="start">
                <Col lg={4}>
                  <h3 className="ml-30 mb-15">{t('dashboard.branchOfficeList.branchOfficesTitle')}</h3>
                </Col>
                <Col lg={4}>
                  <h3 className="text-center mb-15">{t('dashboard.branchOfficeList.patientsTitle')}</h3>
                </Col>
                <Col lg={6}>
                  <h3 className="text-center mb-15">{t('dashboard.branchOfficeList.reservedServicesTitle')}</h3>
                </Col>
                <Col lg={6}>
                  <h3 className="text-center mb-15">{t('dashboard.branchOfficeList.completedServicesTitle')}</h3>
                </Col>
                <Col lg={4}>
                  <h3 className="text-center mb-15">{t('dashboard.branchOfficeList.attendanceTitle')}</h3>
                </Col>
              </Row>
              {branches &&
                branches.map((data, index) => {
                  return (
                    <Row
                      justify="start"
                      key={index}
                      className={index % 2 === 0 ? "gray-row" : null}
                    >
                      <Col lg={4}>
                        <p className="ml-30 mb-0 pt-15 pb-15">
                          {data.branch_name}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <p className="text-center mb-0 pt-15 pb-15">
                          {data.patients}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <p className="text-center mb-0 pt-15 pb-15">
                          {data.reserved}
                        </p>
                      </Col>
                      <Col lg={6}>
                        <p className="text-center mb-0 pt-15 pb-15">
                          {data.prestations}
                        </p>
                      </Col>
                      <Col lg={4}>
                        <p className="text-center mb-0 pt-15 pb-15">
                          {asistance(data)} %
                        </p>
                      </Col>
                    </Row>
                  );
                })}
            </Col>
          )}
        </Row>
      )}
    </Spin>
  );
};

export default BranchOfficeList;
