import React from 'react'
import {
  Row,
  Col,
  Button
} from "antd"
import moment from 'moment'
import { useTranslation } from "react-i18next"
import { Link } from 'gatsby'

const ReevaluationSecondStep = ({ reevaluationData }) => {
  const { t } = useTranslation()

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY')
  }

  return (
    <Row justify="center">
      <Col lg={24} xs={24} className="header-wrapper pt-20 pb-20 mt-60">
        <h2 className="text-center mb-5">{t('patientReevaluation.stepsInfo.stepTwoTitle')}</h2>
        {
          reevaluationData['presence_required'] ?
            <p className="text-center mb-0">{t('patientReevaluation.stepsInfo.branchOfficeText')}</p>
            :
            <p className="text-center mb-0">{t('patientReevaluation.stepsInfo.evaluationText')}</p>
        }
      </Col>
      <Col lg={24} xs={24} className="body-wrapper mt-50">
        {
          reevaluationData['presence_required'] ?
            <Row justify="center">
              <Col xxl={10} xl={12} lg={10} xs={22} className="branch-office-wrapper">
                <p className="bold text-center">{t('patientReevaluation.stepsInfo.presenceInfoText1')} {formatDate(reevaluationData['final_date'])} {t('patientReevaluation.stepsInfo.presenceInfoText2')}</p>
                <p className="text-center mb-30">{t('patientReevaluation.stepsInfo.reminderInfo1')} <strong>{reevaluationData['reservation_number']}</strong> y <strong>{t('patientReevaluation.stepsInfo.reminderInfo2')}</strong></p>
                <Row justify="center" align="middle" className="mb-10">
                  <Col>
                    <i className="icon-system-placeholder mr-5" />
                  </Col>
                  <Col>
                    <p className="bold primary mt-5 mb-0">{t('patientReevaluation.stepsInfo.yourBranchOfficeText')} {reevaluationData['branch_office_name']}</p>
                  </Col>
                  <Col span={24}>
                    <p className="text-center primary small mt-20 mb-0">{reevaluationData['branch_office_address']}</p>
                  </Col>
                  <Col span={24}>
                    <p className="text-center primary small mt-5 mb-0">{reevaluationData['branch_office_phone']}</p>
                  </Col>
                  <Col span={24}>
                    <p className="text-center small mt-50 mb-0">{t('patientReevaluation.stepsInfo.openingHoursText')}</p>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col span={24}>
                    <Link
                      to={`/${t('navbar.branchOffices').toLocaleLowerCase()}/`}
                    >
                      <Button
                        className="cmt-button primary non-outline"
                        type="primary"
                        style={{ boxShadow: 'none', fontSize: 14 }}
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                      >
                        <i className="icon-system-plus mr-5" />
                        {t('navbar.branchOffices').toUpperCase()}
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
            :
            <Row justify="center">
              <Col xxl={9} xl={10} lg={10} xs={22} className="evaluation-wrapper mt-20 mb-40">
                <p className="bold text-center">{t('patientReevaluation.stepsInfo.doctorEvaluationText')}</p>
              </Col>
            </Row>
        }
      </Col>
    </Row>
  )
}

export default ReevaluationSecondStep