import { captchaValdiator } from '../src/services/general';
export const validate = async (recaptchaRef) => {
  try {
    const token = await recaptchaRef?.current?.executeAsync();
    const captchaResult = await captchaValdiator({
      response: token,
    });

    return captchaResult.success;
  } catch (error) {
    console.error(error);
    return false;
  }
};
