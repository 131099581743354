import { message } from "antd";
import { uploadAssetS3 } from '../src/services/general';
import { uploadAsset } from '../src/services/reevaluation';

const allowedContentTypes = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'application/dicom',
];

export const uploadAssetController = async (params) => {
  try {
    let fileType = params.file.type;
    let file = params.file;

    if (!fileType && params.file.name.includes('.dcm')) {
      fileType = 'application/dicom';

      const newFile = new Blob([params.file], { type: fileType });
      file = newFile;
    }

    if (file && allowedContentTypes.includes(fileType)) {
      const response = await uploadAsset({
        field: params.object_field,
        id: params.object_id,
        type: params.object_type,
        content_type: fileType,
        name: params.file.name,
        size: params.file.size,
      });
      return uploadAssetS3(file, response);
    }
  } catch (error) {
    throw error;
  }
}
