import React, { useState } from 'react'
import RegistrationInReservation from '../../../../components/RegistrationInReservation';
import LoginForm from '../../../../components/LoginForm';
import Captcha from '../../../../components/Captcha';

const AuthMiddleware = ({ checkIfUserIsLogged }) => {
  const [type, setType] = useState('register');
  const recaptchaRef = React.createRef();
  return (
    <>
      <Captcha
        recaptchaRef={recaptchaRef}
      />
      {
        type === 'register'
          ? <RegistrationInReservation
            checkIfUserIsLogged={checkIfUserIsLogged}
            setType={setType}
            countryCode={process.env.GATSBY_COUNTRY_VALUE}
          />
          : <LoginForm
            checkIfUserIsLogged={checkIfUserIsLogged}
            recaptchaRef={recaptchaRef}
          />
      }
    </>
  )
};

export default AuthMiddleware;