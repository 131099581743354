import { Row, Col, Spin, Empty } from 'antd';

const ByBranchOfficeTable = ({ t, loading, data }) => {
  const customTableHeader = () => {
    return (
      <Row className="cmt-custom-table-header mb-5">
        <Col
          lg={6}
          className="align-item-center-start p-10"
        >
          <h3>{t('medicalMain.branchOfficeTable.branchOffice')}</h3>
        </Col>
        <Col
          lg={4}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Cantidad total de atenciones
          </h3>
        </Col>
        <Col
          lg={4}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Aptos
          </h3>
        </Col>
        <Col
          lg={4}
          className="center-item p-10"
        >
          <h3 className="text-center">
            No Aptos
          </h3>
        </Col>
        <Col
          lg={3}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Aptos con restricciones
          </h3>
        </Col>
        <Col
          lg={3}
          className="center-item p-10 left-separation"
        >
          <h3 className="text-center">
            Observados
          </h3>
        </Col>
      </Row>
    );
  };

  const customTableBody = (data, index) => {
    return (
      <Row className="cmt-custom-table-body" key={index}>
        <Col
          lg={6}
          xs={12}
          className="align-item-center p-10"
        >
          <h3 className="hide-desktop text-center">Sede</h3>
          <p className="mb-0">{data.branch_office_name}</p>
        </Col>
        <Col
          lg={4}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">
            Cantidad total de atenciones
          </h3>
          <p className='mb-0 text-center'>
            {data.total_attendance}
          </p>
        </Col>
        <Col
          lg={4}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">
            Aptos
          </h3>
          <p className='mb-0 text-center'>
            {data.approved}
          </p>
        </Col>
        <Col
          lg={4}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">
            No Aptos
          </h3>
          <p className='mb-0 text-center'>
            {data.reproved}
          </p>
        </Col>
        <Col
          lg={3}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">
            Aptos con restricciones
          </h3>
          <p className='mb-0 text-center'>
            {data.approved_restrictions}
          </p>
        </Col>
        <Col
          lg={3}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">
            Observados
          </h3>
          <p className='mb-0 text-center'>
            {data.observed}
          </p>
        </Col>
      </Row>
    );
  };


  return (
    <Spin className='cmt-spin' spinning={loading}>
      <Row className='by-office-table-text mt-30'>
        <Col lg={24} xs={24}>
          <h2>Total de atenciones por {t('medicalMain.branchOfficeTable.branchOffice').toLowerCase()}</h2>
          <p>Datos de total histórico</p>
        </Col>
      </Row>
      {
        data.length > 0 ?
          <>
            {
              customTableHeader()
            }
            {
              data.map((item, index) => {
                return customTableBody(item, index)
              })
            }
          </>
          :
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Sin datos de Total de atenciones por sede"
          />
      }
    </Spin>
  );
};

export default ByBranchOfficeTable;