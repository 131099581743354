import React, { useState } from 'react'
import { MailFilled } from '@ant-design/icons';
import { Row, Col, Form, Button, Input, message } from 'antd'
import '../css/login.less'
import { recoveryPassword } from '../services/auth'
import { validate } from '../../utils/captcha'


const RecoveryPasswordForm = ({ email, setShowLogin, recaptchaRef }) => {
  const [form] = Form.useForm()
  const [isSuccess, setIsSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const onFinish = async (values) => {
    try {
      setLoading(true)
      if (!(await validate(recaptchaRef))) {
        throw new Error('Captcha error');
      }
      values['redirect_url'] = `${process.env.GATSBY_ADMIN_URL}/passwords/new`
      const response = await recoveryPassword(values)

      if (response.success) {
        setIsSuccess(true)
      } else {
        setIsSuccess(false)
        message.error(response.errors[0])
      }
    } catch (err) {
      console.error(err)
      setIsSuccess(false)
      message.error("error interno")
    } finally {
      setLoading(false)
    }
  }

  if (isSuccess) {
    return (
      <Row justify="center" className="recovery-password-container success">
        <Col
          lg={10}
          md={24}
          sm={24}
          xs={24}
          className="recovery-password-container-element pt-lg-40 pt-xs-40 p-xs-20"
          style={{ height: 'calc(100vh - 120px)' }}
        >
          <Row justify="center">
            <Col lg={20}>
              <div className="text-center">
                <MailFilled />
              </div>
              <h1 className="text-center">¡Ya casi está listo!</h1>
              <p>
                Si el correo electrónico que has introducido es correcto, te hemos enviado un correo con un enlace para que puedas cambiar tu contraseña.
              </p>
              <p>
                (Si no lo encuentras entre tus recibidos, ¡no olvides revisar en spam!)
              </p>
              <div className="text-center">
                <Button type="link" onClick={() => setShowLogin(true)}>Volver al login</Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <Row justify="center" className="recovery-password-container pt-40">
      <Col
        lg={10}
        md={24}
        sm={24}
        xs={24}
        className="recovery-password-container-element pt-lg-40 pt-xs-40"
        style={{ height: 'calc(100vh - 120px)' }}
      >
        <Row justify="center">
          <Col lg={24}>
            <Row justify="center">
              <Col lg={24}>
                <h1 className="text-center">¿Te has olvidado tu contraseña?</h1>
              </Col>
            </Row>
            <Row justify="center">
              <Col lg={18} className="mt-lg-70 pb-lg-110">
                <Form
                  form={form}
                  onFinish={onFinish}
                  size="large"
                  className='cmt-form'
                >
                  <Row justify="center">
                    <Col lg={24} className="mb-15">
                      <h3>Escribe tu correo electrónico para recuperarla.</h3>
                    </Col>
                  </Row>
                  <Form.Item className='cmt-input' name="email">
                    <Input value={email} placeholder="Correo electrónico" size="large" />
                  </Form.Item>
                  <Row justify="center">
                    <Col lg={24} className="mt-lg-20 pb-lg-200">
                      <Form.Item>
                        <Button loading={loading} htmlType="submit" size="large" className="recovery-password-button" block>
                          Recuperar contraseña
                        </Button>
                      </Form.Item>
                      <Button type="link" onClick={() => setShowLogin(true)}>Volver</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default RecoveryPasswordForm