import React, { useState, useEffect } from 'react';
import { Row, Col, Button, message } from 'antd';
import moment from 'moment';
import { find } from 'lodash';
import WithoutPatientForm from './WithoutPatientForm';
import { preAdmissionService } from '../../../../services/preAdmission';
import { useTranslation } from "react-i18next";
import { navigate } from 'gatsby';

const { format } = require('rut.js')

const WithoutPatients = ({ reservedData, services, from, countryCode }) => {
  const [patients, setPatients] = useState();
  const [formList, setFormList] = useState([]);
  const { t } = useTranslation();

  const createPatients = () => {
    const patientsList = [];
    for (let i = 0; i < reservedData.total_patients; i++) {
      patientsList.push({ patientIndex: i + 1 });
    }
    setPatients(patientsList);
  };

  useEffect(() => {
    createPatients();
  }, []);

  const initializeForm = (form) => {
    setFormList((forms) => [...forms, form]);
  };

  const cleanData = (values) => {
    if (Array.isArray(values['services'])) {
      values['services'] = values['services'].map((item) => {
        return find(services, {
          id: item,
        });
      });
    }
    if (countryCode === 'CL') {
      values['documentNumber'] = values['documentType'] === 'rut' ? format(values['documentNumber'], { dots: false }) : values['documentNumber'];
    }
    if (values['phone'].length > 4) {
      values['phone'] = `+${values['phone']}`;
    } else {
      values['phone'] = null;
    }

    return values;
  };

  const handleSubmit = async () => {
    const patients = [];
    for (let form of formList) {
      const isValidated = await form.validateFields();
      if (!isValidated) {
        return;
      }

      const values = form.getFieldsValue();
      const data = cleanData(values);
      patients.push(data);
    }

    try {
      await preAdmissionService.createPreAdmission(
        { from },
        {
          reservation_id: reservedData.reservation_id,
          patients: patients,
        }
      );
      message.success({
        content: 'Los datos se han guardado exitosamente.',
        style: {
          marginTop: '40vh',
        },
      });
    } catch (err) {
      console.error(`Error al enviar formulario de pre-admisión: ${err}`);
      message.error({
        content: `Error al enviar formulario de pre-admisión: ${err}`,
        style: {
          marginTop: '40vh',
        },
      });
    }
  };

  const formatDate = (date) => {
    return moment(date, 'YYYY-MM-DDTHH:mm:ssZ').format('DD-MM-YYYY');
  };

  return (
    <Row justify="center" className="without-patients-container">
      <Col lg={19} xs={22}>
        <Row justify="center" className="title-wrapper mt-20">
          <Col lg={24} xs={24}>
            <Row>
              <Col xl={3} lg={4} xs={24}>
                <Button
                  className="cmt-button primary non-outline"
                  type="primary"
                  onClick={() => navigate('/app/reservas/')}
                >
                  <i className='icon-system-chevron-left' />
                  Volver
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg={24} xs={24}>
            <h1 className="text-center">{t('preAdmission.withPatients.preAdmissionTitle')}</h1>
          </Col>
        </Row>
        {
          reservedData &&
          <React.Fragment>
            <Row justify="start" className="mt-15">
              <Col
                lg={20}
                xs={24}
                className="gray-reservation-data-row pt-lg-15 pt-md-15 pt-xs-20 pb-lg-0 pb-xs-10"
              >
                <Row justify="center">
                  <Col lg={7} xs={22}>
                    <h2 className="ml-lg-20 ml-xs-0 mb-lg-10 mb-xs-15">
                      {t('preAdmission.withPatients.reservationNumberTitle')} {reservedData.reservation_number}
                    </h2>
                  </Col>
                  <Col lg={8} xs={22}>
                    <Row justify="start">
                      <Col>
                        <h3 className="mr-10">{t('preAdmission.withPatients.dateTitle')}</h3>
                      </Col>
                      <Col>
                        <p>
                          {formatDate(reservedData.date.reservation_date)} -&nbsp;
                          {reservedData.date.block} hs
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={9} xs={22}>
                    <Row justify="start">
                      <Col>
                        <h3 className="mr-10">{t('preAdmission.withPatients.branchOfficeTitle')}</h3>
                      </Col>
                      <Col>
                        <p>{reservedData.branch_office}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              justify="center"
              className="mt-lg-40 mt-md-30 mt-xs-30 mb-10"
            >
              <Col lg={24} xs={24}>
                <h4>
                  {t('preAdmission.withPatients.completeDataTitle')}
                </h4>
              </Col>
            </Row>
            <Row justify="center" className="mb-40">
              {patients &&
                patients.map((patient) => {
                  return (
                    <WithoutPatientForm
                      key={patient.patientIndex}
                      services={services}
                      index={patient.patientIndex}
                      initializeForm={initializeForm}
                      countryCode={countryCode}
                    />
                  );
                })}
            </Row>
            <Row justify="center" className="mb-80">
              <Col lg={4} xs={24}>
                <Button onClick={handleSubmit} size="large" block>
                  Guardar datos
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        }
      </Col>
    </Row>
  );
};

export default WithoutPatients;
