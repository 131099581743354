import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationCL from './es-CL/translation.json';
import translationPE from './es-PE/translation.json';

const resources = {
  cl: {
    translation: translationCL,
  },
  pe: {
    translation: translationPE,
  },
};


i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'cl',
    lng: `${process.env.GATSBY_COUNTRY_VALUE}` === 'CL' ? 'cl' : 'pe',
    interpolation: {
      escapeValue: false,
    },
  });


export default i18n;
