import React, { useState, useEffect } from 'react'
import { Row, Col, Button, Modal, Tag, Spin, Input, Divider, message } from 'antd'
import { ExclamationCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { scheduleTimeService } from '../../../../services/scheduleTime'
import RadioContainers from './RadioContainers'
import { useTranslation } from "react-i18next"
import { getNumberFormatted } from '../../../../helpers/handlers';
import { filter, find } from 'lodash'

const ModalExams = ({
  branchOffice,
  selectedPatient,
  open,
  handleCloseModal,
  setCart,
  countryCode,
}) => {
  const [showRestOfExams, setShowRestOfExams] = useState(false);
  const [radioBatteryData, setRadioBatteryData] = useState();
  const [batteryList, setBatteries] = useState([]);
  const [searchText, setSearchText] = useState();
  const [examsDetails, setExamsDetails] = useState([]);
  const [restExamsDetails, setRestExamsDetails] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const { t } = useTranslation();


  const fetchExams = async () => {
    try {
      const response = await scheduleTimeService.examsDetails({ branchOffice: branchOffice.id });
      setExamsDetails(
        response.map(item => ({
          ...item,
          battery_name: item['battery_name'].replace(/- Piloto/g, '')
        })
        ));
    } catch (error) {
      console.error(error);
      message.error({
        content: `Error al obtener baterías y exámenes`,
        style: {
          marginTop: '45vh'
        }
      })
    }
  };

  const fetchRestOfExams = async () => {
    try {
      const response = await scheduleTimeService.restExamsDetails({ branchOffice: branchOffice.id });
      setRestExamsDetails(response);
    } catch (error) {
      console.error(error);
      message.error({
        content: `Error al obtener otras prestaciones`,
        style: {
          marginTop: '45vh'
        }
      })
    }
  };

  useEffect(() => {
    fetchExams();
    fetchRestOfExams();
  }, []);

  useEffect(() => {
    if (selectedPatient.services.length > 0) setBatteries(selectedPatient.services);
    if (!open) setRadioBatteryData(null);
  }, [open])

  const handleDeleteExam = (exam) => {
    const examsToDelete = [];
    const examToDelete = find(batteryList, { type: exam.type, id: exam.id });

    const attachedExams = filter(examToDelete.attached_services, {
      serviceable_id: exam.id,
      serviceable_type: exam.type,
    });

    attachedExams.forEach((item) => {
      const examToDelete = find(batteryList, { id: item.id, type: item.type });
      if (examToDelete) {
        examsToDelete.push(item)
        if (examToDelete.attached_services && examToDelete.attached_services.length > 0) {
          examToDelete.attached_services.forEach((attachedExamToDelete) => {
            if (!find(examToDelete, { id: attachedExamToDelete.id, type: attachedExamToDelete.type })) {
              examsToDelete.push(attachedExamToDelete);
            }
          })
        }
      }
    })

    examsToDelete.push(exam);
    setBatteries(batteryList.filter(item => !examsToDelete.some(exam => exam.id === item.id && exam.type === item.type)));
  }

  useEffect(() => {
    if (serviceList && serviceList.length > 0) {
      const allServices = serviceList.map(service => {
        if (!find(batteryList, { id: service.id })) {
          return service
        }
      }).filter(Boolean);

      allServices.push(...serviceList.map(fatherService => fatherService.attached_services?.map(service => {
        if (!find(batteryList, { id: service.id, type: service.type })) {
          return {
            battery_name: service.name,
            currency_code: service.currency_code,
            currency_id: service.currency_id,
            department_id: service.department_id,
            id: service.id,
            type: service.type,
            attached_service_id: service.attached_service_id,
            attached_services: find(examsDetails, { id: service.id, type: service.type }) ? find(examsDetails, { id: service.id, type: service.type }).attached_services : undefined,
            serviceable_id: service.serviceable_id,
            service_type: service.serviceable_type,
            value: service.value,
          }
        }
      })).flat(1).filter(Boolean));

      allServices.push(...examsDetails.map(exam => exam.attached_services?.map(service => {
        if (
          find(allServices, { id: exam.id })
          && !find(allServices, { id: service.id, type: service.type })
          && !find(batteryList, { id: service.id, type: service.type })
        ) {
          return {
            battery_name: service.name,
            currency_code: service.currency_code,
            currency_id: service.currency_id,
            department_id: service.department_id,
            id: service.id,
            type: service.type,
            attached_service_id: service.attached_service_id,
            attached_services: find(examsDetails, { id: service.id, type: service.type }) ? find(examsDetails, { id: service.id, type: service.type }).attached_services : undefined,
            serviceable_id: service.serviceable_id,
            serviceable_type: service.serviceable_type,
            value: service.value,
          }
        }
      })).flat(1).filter(Boolean));

      setBatteries([...batteryList, ...allServices]);
      setServiceList([]);
    }
  }, [serviceList, batteryList, examsDetails]);

  const handleFinalizeWorker = () => {
    const batteries = [...batteryList];
    setCart(state => {
      const patients = [...state.patients];
      const amount = batteries.reduce((acc, service) => acc + service.value, 0);
      const total_amount = state.total_amount + amount - state.patients[selectedPatient.number].services.reduce((acc, service) => acc + service.value, 0);
      const currency_code = batteries[0].currency_code;
      const locate_code = batteries[0].locate_code;
      patients[selectedPatient.number].services = batteries;
      patients[selectedPatient.number].amount = amount;
      return {
        ...state,
        total_amount,
        patients,
        currency_code,
        locate_code,
      }
    });
    setBatteries([]);
    closeModal();
  }

  const showMorePrestations = async () => {
    if (!showRestOfExams) {
      setShowRestOfExams(true)
    } else {
      setShowRestOfExams(false)
    }
  }

  const closeModal = () => {
    setSearchText(null);
    handleCloseModal();
  }

  const handleSearchText = (event) => {
    setSearchText(event.target.value);
    setShowRestOfExams(false);
  }

  const filterList = (type) =>
    [...examsDetails, ...restExamsDetails].filter(item =>
      item.battery_name.toLowerCase().includes(searchText.toLowerCase())
      && item.type === type
    );


  return (
    <Modal
      className="modal-select-exams"
      open={open}
      onCancel={closeModal}
      footer={false}
      closable={false}
      centered
      width={1200}
    >
      <Spin
        className='cmt-spin'
        spinning={examsDetails.length === 0 && restExamsDetails.length === 0}
      >
        <Row justify="center" className="exams-selection-modal-form">
          <Col lg={24} xs={24}>
            <Row className="data-patient-top pt-15 pb-15">
              <Col>
                <span className='icon-system-user ml-20' />
              </Col>
              <Col>
                <p className="patient-number mb-0 ml-15 mr-15">
                  {t('scheduleTime.modalExams.patient')} {selectedPatient.number + 1}
                </p>
              </Col>
              {
                (selectedPatient.firstName || selectedPatient.lastName) &&
                <Col>
                  <p className="patient-data mb-0">{selectedPatient.firstName} {selectedPatient.lastName}</p>
                </Col>
              }
            </Row>
            <Row justify="center" className="battery-wrapper-parent pb-lg-0 pb-md-40 pb-sm-40 pb-xs-40">
              <Col lg={22} xs={22}>
                <Row justify="center" className="battery-wrapper pb-40">
                  <Col lg={22} xs={23} className="mt-30 mb-15">
                    <Row>
                      <Col lg={6} xs={24}>
                        <Input
                          className="cmt-input-icon"
                          value={searchText}
                          onChange={handleSearchText}
                          prefix={<SearchOutlined />}
                          placeholder={t("scheduleTime.modalExams.placeholderButton")}
                        />
                      </Col>
                    </Row>
                  </Col>
                  {
                    countryCode === 'PE' &&
                    <Col lg={22} xs={23} className='mt-30'>
                      <p className='text-helper'>Seleccione la prestación a realizar (deslice el mouse sobre el ícono de exclamación para ver el detalle de cada batería o examen)</p>
                    </Col>
                  }
                  <RadioContainers
                    title={'Baterías'}
                    onChangeRadio={setRadioBatteryData}
                    services={
                      searchText
                        ? filterList('Battery')
                        : examsDetails.filter(service => service.type === 'Battery')
                    }
                    list={batteryList}
                    radioBatteryData={radioBatteryData}
                  />
                  <RadioContainers
                    title={'Exámenes'}
                    onChangeRadio={setRadioBatteryData}
                    services={
                      searchText
                        ? filterList('Exam')
                        : examsDetails.filter(service => service.type === 'Exam')
                    }
                    list={batteryList}
                    radioBatteryData={radioBatteryData}
                  />
                </Row>
                {
                  !searchText &&
                  <>
                    <Row justify="end" className="see-more-batteries pb-20">
                      <Col lg={5} xs={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <a onClick={showMorePrestations}>
                          {showRestOfExams ? <h3>Ver menos</h3> : <h3>{t('scheduleTime.modalExams.seeMoreServices')}</h3>}
                        </a>
                      </Col>
                    </Row>
                    {
                      showRestOfExams && restExamsDetails &&
                      <Row justify="center" className="battery-wrapper pt-lg-0 pb-40">
                        <Col lg={22} md={16} sm={18} xs={23}>
                          <h3 className="battery-wrapper_title">{t('scheduleTime.modalExams.otherExamsTitle')}</h3>
                        </Col>
                        <RadioContainers
                          title={'Baterías'}
                          onChangeRadio={setRadioBatteryData}
                          services={restExamsDetails.filter(service => service.type === 'Battery')}
                          list={batteryList}
                        />
                        <RadioContainers
                          title={'Exámenes'}
                          onChangeRadio={setRadioBatteryData}
                          services={restExamsDetails.filter(service => service.type === 'Exam')}
                          list={batteryList}
                        />
                      </Row>
                    }
                  </>
                }
              </Col>
            </Row>
            <Row
              justify="center"
              className={`battery-details ${batteryList.length > 0 && "has-selected"} pb-20`}
            >
              <Col lg={21} md={24} sm={24} xs={24}>
                {
                  radioBatteryData &&
                  <Row justify="space-between" className="add-exam-wrapper pt-20">
                    <Col lg={12} xs={15}>
                      <h2>{radioBatteryData.battery_name}</h2>
                      {
                        (radioBatteryData.attached_services && radioBatteryData.attached_services.length) > 0 &&
                        <p><ExclamationCircleOutlined /> Esta batería incluye los siguientes exámenes:</p> &&
                        radioBatteryData.attached_services.map((service) => {
                          return (
                            <p key={service.id}>
                              {service.name}
                            </p>
                          )
                        })
                      }
                    </Col>
                    <Col lg={6} xs={7}>
                      <h3>Valor: {
                        getNumberFormatted({
                          countryCode: radioBatteryData.locate_code,
                          currencyCode: radioBatteryData.currency_code,
                          amount: radioBatteryData.value
                        })
                      }</h3>
                    </Col>
                    <Col lg={6} md={10} sm={15} xs={20}>
                      <Button
                        className='outline'
                        size="large"
                        onClick={() => {
                          setServiceList(list => [...list, radioBatteryData]);
                          setRadioBatteryData(null);
                        }}
                        icon={<PlusOutlined />}
                        block
                      >
                        {t('scheduleTime.modalExams.addServiceButton')}
                      </Button>
                    </Col>
                  </Row>
                }
                {
                  batteryList.length > 0 &&
                  <Row
                    justify="center"
                    align="middle"
                    className="finalize-worker-wrapper mb-lg-0 mb-md-15 mb-sm-15 mb-xs-15 mt-10"
                  >
                    {
                      radioBatteryData &&
                      <Divider />
                    }
                    <Col lg={24} md={22} sm={22} xs={22}>
                      <Row justify="space-between">
                        <Col lg={{ order: 1, span: 12 }} xs={{ order: 1, span: 24 }}>
                          <h4 className="mb-lg-15 mb-xs-0">
                            {t('scheduleTime.modalExams.selectedServicesTitle')}
                          </h4>
                        </Col>
                        <Col lg={{ order: 2, span: 12 }} xs={{ order: 3, span: 15 }}>
                          <h4 className="mb-15">{t('scheduleTime.modalExams.totalValueServicesTitle')}</h4>
                        </Col>
                        <Col lg={{ order: 3, span: 12 }} xs={{ order: 2, span: 24 }} className="tags-container ml-lg-30 ml-md-30 ml-xs-15 pb-20">
                          {
                            batteryList.map(exam =>
                              <Tag
                                className="exam-tag mt-5 pl-15 pr-15"
                                visible={true}
                                key={exam.id}
                                closable={!exam.attached_service_id}
                                onClose={() => handleDeleteExam(exam)}
                              >
                                {exam.battery_name}
                              </Tag>
                            )
                          }
                        </Col>
                        <Col lg={{ order: 4, span: 6 }} xs={{ order: 4, span: 9 }} className={'patient-amount'}>
                          {
                            getNumberFormatted({
                              countryCode: batteryList[0].locate_code,
                              currencyCode: batteryList[0].currency_code,
                              amount: batteryList.reduce((acc, service) => acc + service.value, 0)
                            })
                          }
                        </Col>
                        <Col lg={{ order: 5, span: 6 }} xs={{ order: 5, span: 24 }}>
                          <Button
                            onClick={handleFinalizeWorker}
                            className="cmt-button primary"
                            type='primary'
                            size='large'
                            block
                          >
                            {t('scheduleTime.modalExams.finalizePatient')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    </Modal>
  )
}

export default ModalExams
