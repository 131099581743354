import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  Popconfirm,
  Form,
  Switch,
} from 'antd'

const ActiveConfirm = ({
  active,
  object,
  saveObject,
  loading,
  disabled,
  context,
  t,
}) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const handleOnClick = () => {
    setOpen(true)
  };

  const handleOk = async () => {
    await saveObject(form.getFieldValue('active'), object);
    setOpen(false);
  };

  const handleCancel = () => {
    form.resetFields()
    setOpen(false);
  }

  return (
    <Popconfirm
      icon=''
      visible={open}
      title={
        <div className='ant-popover-user-table-active'>
          <Row justify='end'>
            <Col>
              <span className='icon-system-close' onClick={handleCancel} />
            </Col>
          </Row>
          <Row>
            <Col>
              <h3><strong>{t(`companyProfile.${context}.title.${form.getFieldValue('active') ? 'active' : 'inactive'}`)}</strong></h3>
              <p>{t(`companyProfile.${context}.message.${form.getFieldValue('active') ? 'active' : 'inactive'}`)}</p>
            </Col>
          </Row>
          <Row gutter={[10, 0]} className='mt-20'>
            <Col lg={12} xs={24}>
              <Button
                loading={loading.form}
                className='cmt-button primary outline'
                onClick={handleCancel}
                type='primary'
              >
                Cancelar
              </Button>
            </Col>
            <Col lg={12} xs={24}>
              <Button
                loading={loading.form}
                className='cmt-button primary'
                onClick={handleOk}
                type='primary'
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </div>
      }
      showCancel={false}
      okButtonProps={{ className: 'hidden' }}
    >
      <Form
        form={form}
        layout='vertical'
        size='large'
        className='cmt-form'
      >
        <Form.Item
          name='active'
          className='mb-0'
        >
          <Switch
            defaultChecked={active}
            onClick={handleOnClick}
            className='secondary'
            disabled={disabled}
          />
        </Form.Item>
      </Form>
    </Popconfirm>
  )
};

export default ActiveConfirm;