import PublicClientsNavbar from "./publicClientsNavbar";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <PublicClientsNavbar>
      <Component {...rest} />
    </PublicClientsNavbar>
  );
};

export default PublicRoute;
