import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Row, Col, Layout, Form, Button, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import PasswordStrengthBar from 'react-password-strength-bar'
import { MailFilled } from '@ant-design/icons';
import '../css/login.less'
import { createPassword } from '../services/auth'
import PublicClientsNavbar from './publicClientsNavbar'

const { Content } = Layout

const CreateNewPassword = () => {
  const [form] = Form.useForm()
  const [password, setPassword] = useState('')
  const [passwordStrength, setPasswordStrength] = useState()
  const [isSuccess, setIsSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation();

  const validatePassword = (_, value) => {
    if (passwordStrength <= 1) {
      return Promise.reject('Contraseña muy débil');
    }
    return Promise.resolve();
  };

  const onFinish = async values => {
    const urlParams = new URLSearchParams(window.location.search);
    values['reset_password_token'] = urlParams.get('reset_password_token');
    values['redirect_url'] = urlParams.get('redirect_url');

    try {
      setLoading(true)
      const response = await createPassword(values)

      if (response.success) {
        setIsSuccess(true)
        setLoading(false)
      } else {
        setIsSuccess(false)
        setLoading(false)
        message.error(response.errors[0])
      }
    } catch (err) {
      setIsSuccess(false)
      setLoading(false)
      message.error(err)
    }
  }

  if (isSuccess) {
    return (
      <PublicClientsNavbar>
        <Content style={{ marginTop: -18 }}>
          <Row justify="center" className="recovery-password-container success">
            <Col
              lg={10}
              md={24}
              sm={24}
              xs={24}
              className="recovery-password-container-element pt-lg-40 pt-xs-40"
              style={{ height: 'calc(100vh - 120px)' }}
            >
              <Row justify="center">
                <Col lg={20}>
                  <div class="text-center">
                    <MailFilled />
                  </div>
                  <h1 className="text-center">¡Tu contraseña se ha cambiado exitosamente!</h1>
                  <p>
                    Ahora podras ingresar con tu contraseña nueva
                  </p>
                  <div className="text-center">
                    <Link to="/app/login?redirect=/app/reservas">
                      <Button type="link">Volver al login</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </PublicClientsNavbar>
    )
  }

  return (
    <PublicClientsNavbar>
      <Content style={{ marginTop: -18 }}>
        <Row justify="center" className="registration-form-container">
          <Col lg={10} xs={24} className="registration-form-container-element pt-lg-40 pt-xs-40">
            <Row justify="center">
              <Col lg={24}>
                <h1 className="text-center">Crea tu nueva contraseña</h1>
              </Col>
            </Row>
            <Row justify="center">
              <Col lg={18} xs={20} className="mt-lg-70 pb-lg-110">
                <Form
                  form={form}
                  onFinish={onFinish}
                  size="large"
                  className='cmt-form'
                >
                  <Form.Item
                    extra={t('registrationInReservation.passwordPolicy')}
                    name="password"
                    rules={[{
                      required: true,
                      message: 'Debes ingresar tu contraseña',
                    },
                    {
                      validator: validatePassword,
                    }]}
                  >
                    <Input.Password
                      className='cmt-input'
                      type="password"
                      placeholder="Contraseña nueva"
                      size="large"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <PasswordStrengthBar
                    password={password}
                    style={{
                      width: '100%',
                      display: 'none',
                    }}
                    onChangeScore={(score) => {
                      setPasswordStrength(score);
                    }}
                    className="password-strength-bar"
                    shortScoreWord="Muy débil"
                    minLength={8}
                    scoreWords={[
                      "Muy débil",
                      "Débil",
                      "Regular",
                      "Buena",
                      "Fuerte",
                    ]}
                  />
                  <Form.Item
                    name="password_confirmation"
                    hasFeedback
                    rules={[{
                      required: true,
                      message: 'Debes confirmar la contraseña',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Las dos contraseñas que has introducido no coinciden.'));
                      },
                    }),
                    ]}
                  >
                    <Input.Password
                      type="password"
                      placeholder="Repetir contraseña nueva"
                      size="large"
                    />
                  </Form.Item>
                  <Row justify="center">
                    <Col lg={24} className="mt-lg-20 pb-lg-200">
                      <Form.Item>
                        <Button loading={loading} htmlType="submit" className="change-password-button" size="large" block>Cambiar contraseña</Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </PublicClientsNavbar>
  )
}

export default CreateNewPassword