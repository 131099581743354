import { useState, useEffect } from "react";
import "./PrivateLayout.less";
import { Link, navigate, graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet";
import { Row, Col, Layout, Menu, Affix, Avatar } from "antd";
import { HasAccess } from '@permify/react-role';
import Info from './Info';
import { getUserName, getEmail, logout } from '../services/auth';
import LogoCmtNaranjo2023 from "../images/LogoCmtNaranjo2023.svg";
import { handlers } from "../helpers/handlers";
import { useTranslation } from "react-i18next";
import { getCompanies } from '../services/profilesAndCostCenters';
import MobilePrivateLayout from "./MobilePrivateLayout";

const { Header, Content } = Layout;
const { SubMenu } = Menu;

const PrivateLayout = ({ children, page }) => {
  const [userData, setUserData] = useState();
  const [affixActive, setAffixActive] = useState(false);
  const [companyUser, setCompanyUser] = useState(false);
  const { t } = useTranslation();
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;;

  const dataName = getUserName();

  const validateSuperUser = async () => {
    try {
      const response = await getCompanies({ userEmail: getEmail() });
      setCompanyUser(response);
    } catch (error) {
      console.error(error);
    };
  };

  useEffect(() => {
    setUserData(dataName && dataName.charAt());
    validateSuperUser();
  }, [dataName]);

  const handleAffix = (value) => {
    setAffixActive(value);
  };

  const handleLogout = () => {
    logout(() => navigate('/app/login/'));
  };

  const dataQuery = useStaticQuery(
    graphql`
      query ServicesQuery {
        wpgraphql {
          settings {
            nodes {
              fields {
                country
                centralPhone
                centralEmail
                noticeEmail
                facebook
                youtube
                twitterX
                linkedin
                instagram
                collectionPayments
                frequentQuestions
                opinion
              }
            }
          }
        }
      }
    `
  );


  return (
    <Layout className={`layout-${page} affix-${affixActive}`}>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Helmet>

      <Info
        data={dataQuery && dataQuery}
        device='device-desktop'
        splitPhone={handlers.splitPhone}
        countryCode={countryCode}
      />

      <Affix offsetTop={0} onChange={handleAffix}>
        <Header className="header-private-layout">
          <Row justify="center" align="middle" className="main-wrapper">
            <Col xl={21} lg={22} xs={22}>
              <Row align="middle">
                <Col xl={4} lg={3} xs={10} className="logo-container">
                  <Row align="middle">
                    <Col>
                      <Link to="/">
                        <img src={LogoCmtNaranjo2023} alt="CMT logo naranjo" />
                      </Link>
                    </Col>
                  </Row>
                </Col>

                <Col xl={20} lg={21} xs={14}>
                  <Row align="middle">
                    <Col lg={24} xs={24}>
                      <Menu mode="horizontal" className="menu-desktop">
                        <Menu.Item key="1">
                          <Link to="/app/" activeClassName="active">
                            Inicio
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <Link to="/app/reservas/" activeClassName="active">
                            Mis reservas
                          </Link>
                        </Menu.Item>
                        <HasAccess
                          permissions="can_view_statistics"
                        >
                          <Menu.Item key="3">
                            <Link to="/app/estadisticas/" activeClassName="active">
                              Estadísticas
                            </Link>
                          </Menu.Item>
                        </HasAccess>
                        <HasAccess
                          permissions="can_view_invoices"
                        >
                          <Menu.Item key="4">
                            <Link to="/app/facturacion/" activeClassName="active">
                              Facturación
                            </Link>
                          </Menu.Item>
                        </HasAccess>
                        <HasAccess
                          permissions="medical_administration"
                        >
                          <Menu.Item key="5">
                            <Link to="/app/medico/" activeClassName="active">
                              Médico
                            </Link>
                          </Menu.Item>
                        </HasAccess>
                        <HasAccess
                          permissions="can_reserve"
                        >
                          <Menu.Item key="6">
                            <Link to="/app/reservar/" activeClassName="active">
                              <strong>{t('navbar.scheduleTime')}</strong>
                            </Link>
                          </Menu.Item>
                        </HasAccess>
                        <SubMenu
                          key="7"
                          title={
                            <Row className="user-wrapper">
                              <Col className="mr-10">
                                <p className="mb-0">{dataName}</p>
                              </Col>
                              <Col>
                                <Avatar>{userData}</Avatar>
                              </Col>
                            </Row>
                          }
                        >
                          <Menu.Item key="sub1">
                            <Link to="/app/perfil-usuario/" activeClassName="active">
                              Perfil usuario
                            </Link>
                          </Menu.Item>
                          {
                            (companyUser?.length > 0)
                            && <Menu.Item key="sub2">
                              <Link to="/app/perfil-empresa/" activeClassName="active">
                                Perfil empresa
                              </Link>
                            </Menu.Item>
                          }
                          <Menu.Item key="sub3">
                            <p aria-label="Cerrar sesión" onClick={handleLogout}>
                              Cerrar sesión
                            </p>
                          </Menu.Item>
                        </SubMenu>
                      </Menu>

                      <MobilePrivateLayout
                        t={t}
                        data={dataQuery && dataQuery}
                        handlers={handlers}
                        dataName={dataName}
                        userData={userData}
                        HasAccess={HasAccess}
                        countryCode={countryCode}
                        handleLogout={handleLogout}
                        companyUser={companyUser}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
      </Affix>

      <Content className="mt-20">
        <Row justify="center">
          <Col lg={24} xs={24}>
            {children}
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default PrivateLayout;
