import { HasAccess } from "@permify/react-role";
import {
  Button,
  Card,
  Col,
  Layout,
  Modal,
  Row,
  Spin
} from "antd";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { useMount } from "react-use";
import SEO from "../../components/seo";
import ModalOnboarding from "../../images/ModalOnboarding.png";
import { billingService } from "./../../services/billing";

const HomePrivate = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startClientsTutorial, setStartClientsTutorial] = useState(false);
  const [companies, setCompanies] = useState();
  const [unpaidCompanies, setUnpaidCompanies] = useState();
  const { t } = useTranslation();
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;

  let steps = [
    {
      target: ".reservation-card",
      placement: "right-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.homePrivate.stepOne.myReservationsTitle')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.homePrivate.stepOne.myReservationsContent')}
        </p>
      ),
    },
    {
      target: ".statistics-card",
      placement: "right-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.homePrivate.stepTwo.statisticsTitle')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.homePrivate.stepTwo.statisticsContent')}
        </p>
      ),
    },
    {
      target: ".billing-card",
      placement: "left-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.homePrivate.stepThree.billingTitle')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.homePrivate.stepThree.billingContent')}
        </p>
      ),
    },
    {
      target: ".medical-card",
      placement: "left-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.homePrivate.stepFour.medicalTitle')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.homePrivate.stepFour.medicalContent')}
        </p>
      ),
    },
  ];

  const fetchCompanies = async () => {
    const response = await billingService.getCompanies();
    setCompanies(response);
  };

  const fetchUnpaidCompanies = async (companies) => {
    const companiesIds = companies.map(({ id }) => id);

    const response = await billingService.getUnpaidTotalByCompany({
      companiesIds: companiesIds,
    });
    if (response) {
      setUnpaidCompanies(response);
    }
  };

  useEffect(() => {
    if (companies) {
      fetchUnpaidCompanies(companies);
    }
  }, [companies]);

  useEffect(() => { }, [unpaidCompanies]);

  useMount(() => {
    const checkTutorialState = localStorage.getItem("homeTutorialDisabled");
    fetchCompanies();

    if (checkTutorialState === "true") {
      setIsModalVisible(false);
    } else {
      if (!isMobile) {
        setIsModalVisible(true);
      }
    }
  });

  const closeModal = () => {
    setIsModalVisible(false);
    localStorage.setItem("homeTutorialDisabled", true);
  };

  const startTutorial = () => {
    setStartClientsTutorial(true);
    setIsModalVisible(false);
  };

  const joyrideCallback = (data) => {
    const { action, index } = data;

    if (action === "skip" || action === "reset") {
      localStorage.setItem("homeTutorialDisabled", true);
    }

    if (action === 'update') {
      const className = {
        0: 'reservation-card',
        1: 'statistics-card',
        2: 'billing-card',
      };
      window.scroll({ top: 0 });
      const element = document.getElementById(className[index]);
      if (element) {
        element.classList.add("animate__animated", "animate__headShake");
      }
    }
  };

  const schemaExtraData = [{
    "@type": "Product",
    "brand": {
      "@type": "Brand",
      "name": "CMT Salud",
    },
  }]


  return (
    <Layout>
      <SEO
        title="App | Reservas, Estadísticas y Facturación | CMT Salud"
        description={"Reserva de horas para exámenes preocupacionales y descarga los resultados de las prestaciones. Revisa la información sobre la facturación de empresa"}
        dataLayer={{
          userId: "",
          pageId: "",
          pageType: "",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Joyride
        callback={joyrideCallback}
        run={startClientsTutorial}
        steps={steps}
        continuous
        showSkipButton
        disableOverlay
        disableScrolling
        locale={{
          skip: "Omitir",
          next: "Continuar",
          last: "Finalizar",
          back: "Atrás",
        }}
      />
      <Row
        justify="center"
        className="home-private-container pt-lg-100 pt-md-40"
      >
        <Col lg={21} md={12} sm={14} xs={22}>
          <Row justify="center" gutter={30}>
            <Col lg={6} className="card-wrapper mb-md-15 mb-sm-15 mb-xs-15">
              <Row
                justify="center"
                id="reservation-card"
                className="reservation-card"
              >
                <Col lg={24} xs={24}>
                  <Link to="/app/reservas/">
                    <Card className="card">
                      <Row justify="center" className="mb-lg-10">
                        <Col
                          lg={6}
                          xs={5}
                          className="reservation-image-wrapper mt-lg-50"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span className="icon-system-calendar" />
                        </Col>
                        <Col lg={24} xs={19} className="title-wrapper mt-lg-20">
                          <h1 className="text-center mb-md-0 mb-sm-0 mb-xs-0">
                            {t('homePrivate.myReservationsTitle')}
                          </h1>
                        </Col>
                      </Row>
                    </Card>
                  </Link>
                </Col>
                <Col lg={22} xs={24} className="mt-20">
                  <p className="text-center">
                    {t("homePrivate.myReservationsContent")}
                  </p>
                </Col>
              </Row>
            </Col>
            <HasAccess
              permissions='can_view_statistics'
              isLoading={<Spin className='cmt-spin' />}
            >
              <Col
                lg={6}
                className="card-wrapper statistics-card-wrapper mb-md-15 mb-sm-15 mb-xs-15"
              >
                <Row
                  justify="center"
                  id="statistics-card"
                  className="statistics-card"
                >
                  <Col lg={24} xs={24}>
                    <Link to="/app/estadisticas/">
                      <Card className="card">
                        <Row justify="center" className="mb-lg-10">
                          <Col
                            lg={7}
                            xs={5}
                            className="statistics-image-wrapper mt-lg-40"
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <span className="icon-brand-chart" />
                          </Col>
                          <Col lg={24} xs={19} className="title-wrapper mt-lg-20">
                            <h2 className="text-center mb-md-0 mb-sm-0 mb-xs-0">
                              {t('homePrivate.statisticsTitle')}
                            </h2>
                          </Col>
                        </Row>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg={22} xs={24} className="mt-20">
                    <p className="text-center">
                      {t('homePrivate.statisticsContent')}
                    </p>
                  </Col>
                </Row>
              </Col>
            </HasAccess>
            <HasAccess
              permissions='can_view_invoices'
              isLoading={<Spin className='cmt-spin' />}
            >
              <Col lg={6} className="card-wrapper mb-md-15 mb-sm-15 mb-xs-15">
                <Row justify="center" id="billing-card" className="billing-card">
                  <Col lg={24} xs={24}>
                    <Link to="/app/facturacion/">
                      <Card className="card">
                        <Row justify="center" className="mb-lg-10">
                          <Col
                            lg={6}
                            xs={5}
                            className="billing-image-wrapper mt-lg-40"
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <i className="icon-brand-billing" />
                          </Col>
                          <Col lg={24} xs={19} className="title-wrapper mt-lg-30">
                            <h3 className="text-center mb-md-0 mb-sm-0 mb-xs-0">
                              {t('homePrivate.billingTitle')}
                            </h3>
                          </Col>
                        </Row>
                      </Card>
                    </Link>
                  </Col>
                  <Col lg={22} xs={24} className="mt-20">
                    <p className="text-center">
                      {t('homePrivate.billingContent')}
                    </p>
                  </Col>
                </Row>
              </Col>
            </HasAccess>
            {
              countryCode === 'PE' &&
                <HasAccess
                  permissions='medical_administration'
                >
                  <Col lg={6} className="card-wrapper mb-md-15 mb-sm-15 mb-xs-15">
                    <Row justify="center" id="medical-card" className="medical-card">
                      <Col lg={24} xs={24}>
                        <Link to="/app/medico/">
                          <Card className="card">
                            <Row justify="center" className="mb-lg-10">
                              <Col
                                lg={6}
                                xs={5}
                                className="medical-image-wrapper mt-lg-40"
                                style={{ display: "flex", justifyContent: "center" }}
                              >
                                <i className="icon-health-briefcase-bold" />
                              </Col>
                              <Col lg={24} xs={19} className="title-wrapper mt-lg-30">
                                <h4 className="text-center mb-md-0 mb-sm-0 mb-xs-0">
                                  MÉDICO
                                </h4>
                              </Col>
                            </Row>
                          </Card>
                        </Link>
                      </Col>
                      <Col lg={22} xs={24} className="mt-20">
                        <p className="text-center">
                          {t('homePrivate.medicalContent')}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </HasAccess>
            }
          </Row>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        footer={false}
        onCancel={closeModal}
        width={900}
        closable={false}
        className="tutorial-modal"
      >
        <Row justify="center" className="mt-30 mb-30">
          <Col className="mb-40">
            <img src={ModalOnboarding} alt="Onboarding iconos" />
          </Col>
          <Col lg={24} xs={24}>
            <h1 className="text-center">
              {t('joyRide.homePrivate.modal.title')}
            </h1>
            <p className="text-center">
              {t('joyRide.homePrivate.modal.content')}
            </p>
          </Col>
          <Col lg={24} xs={24} className="mt-30">
            <Row justify="center">
              <Col className="mr-30">
                <Button
                  className="cmt-button primary outline"
                  type="primary"
                  onClick={closeModal}
                  size="large"
                >
                  Omitir tutorial
                </Button>
              </Col>
              <Col>
                <Button
                  className="cmt-button primary"
                  type="primary"
                  onClick={startTutorial}
                  size="large"
                >
                  ¡Muéstrame!
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Layout>
  );
};

export default HomePrivate;