import React from 'react';
import { Row, Col, Menu } from 'antd';

const Sidebar = ({
  user,
  companiesList,
  currentCompany,
  setCurrentCompany,
}) => {
  const handleMenuIndex = ({ key }) => {
    setCurrentCompany(companiesList.find(company => company.id.toString() === key.toString()));
  }

  return (
    <Row justify='center' className='company-profile__sidebar'>
      <Col lg={24} xs={24} className="pt-lg-80 pt-md-40 pt-sm-40 pt-xs-40 pl-lg-60 pl-md-30 pl-sm-30 pl-xs-30 pr-lg-60 pr-xs-30">
        <Row>
          <Col lg={24} xs={24}>
            <h2>{user.name}</h2>
          </Col>
          <Col lg={24} xs={24}>
            <p className='bold' style={{ fontSize: 16 }}>
              {user.email}
            </p>
          </Col>
        </Row>
        <Row className='mt-lg-50 mt-md-30 mt-sm-30 mt-xs-30'>
          <p className='bold'>Perfil empresa</p>
          <Col lg={24} xs={24}>
            <Menu
              mode='inline'
              style={{ width: '100% ' }}
              onClick={handleMenuIndex}
              defaultSelectedKeys={[`${currentCompany.id}`]}
            >
              {
                companiesList.length > 0 && companiesList.map((company) => {
                  return (
                    <Menu.Item key={company.id} className="mb-20 p-0">
                      {company.business_name}
                    </Menu.Item>
                  )
                })
              }
            </Menu>
          </Col>
        </Row>
      </Col>
    </Row >
  )
}

export default Sidebar