import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Form, Radio, Divider, Checkbox } from 'antd'
import UsersForm from './UsersForm'
import SetPasswordModal from './SetPasswordModal';

const { format } = require('rut.js')

const DetailsModal = ({
  isSuperUser,
  email,
  currentUser,
  modalVisible,
  setModalVisible,
  saveUser,
  loading,
  t,
  countryCode,
}) => {
  const [form] = Form.useForm();
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const disabled = (!isSuperUser || (currentUser && (currentUser.email === email || currentUser.email.includes('@cmtsalud.'))));

  useEffect(() => {
    if (editMode) {
      form.setFieldsValue({
        id: currentUser.id && currentUser.id,
        firstName: currentUser.first_name && currentUser.first_name,
        lastName: currentUser.last_name && currentUser.last_name,
        phone: currentUser.phone,
        documentNumber: currentUser.document_number,
        documentType: currentUser.document_type,
        email: currentUser.email,
        active: !!currentUser.active,
        canReserve: currentUser.extra_data && !!currentUser.extra_data.can_reserve,
        canViewStatistics: currentUser.extra_data && !!currentUser.extra_data.can_view_statistics,
        canDownloadResults: currentUser.extra_data && !!currentUser.extra_data.can_download_results,
        canViewInvoices: currentUser.extra_data && !!currentUser.extra_data.can_view_invoices,
        medicalAdministation: currentUser.extra_data && !!currentUser.extra_data.medical_administration,
        companies: currentUser.companies && currentUser.companies.join(', '),
      })
    }
  }, [editMode])

  const closeModal = () => {
    setEditMode(false)
    setModalVisible(false)
  }

  const showModalPassword = () => {
    setPasswordModalVisible(true);
  }

  return (
    <Modal
      open={modalVisible}
      onCancel={closeModal}
      footer={false}
      centered
      className="details-modal"
    >
      <Row justify='center'>
        {
          !editMode ?
            <Col lg={24} xs={24}>
              {
                currentUser &&
                <Row justify='center' gutter={[0, 30]}>
                  <Col lg={24} xs={24} className="user-header-wrapper mt-20 pt-40 pb-5">
                    <Row>
                      <Col lg={24} xs={24}>
                        <h1 className='text-center mb-0'>{currentUser.email}</h1>
                      </Col>
                    </Row>
                    <Row justify='center'>
                      <Col lg={24} xs={24} style={{ textAlign: "center" }}>
                        <p className='text-center'>Usuario activo
                          <Radio.Group
                            className='cmt-radio custom-radio-buttons'
                            disabled
                            value={currentUser.active}>
                            <Radio value={true}>Sí</Radio>
                            <Radio value={false}>No</Radio>
                          </Radio.Group></p>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={20} xs={22} className="user-body-wrapper">
                    <Row>
                      <Col lg={24} xs={24}>
                        <h2 className='mb-15'>Datos generales</h2>
                      </Col>
                      <Col lg={24} xs={24} className="gray-wrapper p-20">
                        <Row gutter={10}>
                          <Col lg={12} xs={24}>
                            <p>
                              Nombre:
                              <span className='ml-10'>{currentUser.first_name}</span>
                            </p>
                          </Col>
                          <Col lg={12} xs={24}>
                            <p>
                              Apellido:
                              <span className='ml-10'>{currentUser.last_name}</span>
                            </p>
                          </Col>
                          <Col lg={12} xs={24}>
                            <p>{t(`companyProfile.${currentUser.document_type}`)}
                              <span className="ml-10">{
                                (currentUser.document_type === 'rut')
                                  ? format(currentUser.document_number)
                                  : currentUser.document_number
                              }</span>
                            </p>
                          </Col>
                          <Col lg={12} xs={24}>
                            <p>
                              Teléfono:
                              <span className='ml-10'>{currentUser.phone}</span>
                            </p>
                          </Col>
                          <Col lg={12} xs={24}>
                            <p>
                              Correo electrónico:
                              <span className='ml-10'>{currentUser.email}</span>
                            </p>
                          </Col>
                        </Row>
                        <Divider />
                        <Row className='custom-user-view-modal'>
                          <Col lg={24} xs={24}>
                            <h2 className='mb-15'>Usuario autorizado para:</h2>
                          </Col>
                          <Col lg={12} xs={24}>
                            <p>
                              <Checkbox
                                className="cmt-checkbox"
                                disabled
                                checked={currentUser.extra_data && currentUser.extra_data.can_reserve}
                              />
                              <label className='cmt-checkbox-label'>Realizar reservas</label>
                            </p>
                          </Col>
                          <Col lg={12} xs={24}>
                            <p>
                              <Checkbox
                                className="cmt-checkbox"
                                disabled
                                checked={currentUser.extra_data && currentUser.extra_data.can_view_statistics}
                              />
                              <label className='cmt-checkbox-label'>Revisión portal estadísticas</label>
                            </p>
                          </Col>
                          <Col lg={12} xs={24}>
                            <p>
                              <Checkbox
                                className="cmt-checkbox"
                                disabled
                                checked={currentUser.extra_data && currentUser.extra_data.can_download_results}
                              />
                              <label className='cmt-checkbox-label'>Visualizar y descargar resultados</label>
                            </p>
                          </Col>
                          <Col lg={12} xs={24}>
                            <p>
                              <Checkbox
                                className="cmt-checkbox"
                                disabled
                                checked={currentUser.extra_data && currentUser.extra_data.can_view_invoices}
                              />
                              <label className='cmt-checkbox-label'>Información de Facturación</label>
                            </p>
                          </Col>
                          {
                            countryCode?.toUpperCase() === 'PE' &&
                            <Col lg={12} xs={24}>
                              <p>
                                <Checkbox
                                  className="cmt-checkbox"
                                  disabled
                                  checked={currentUser?.extra_data?.medical_administration}
                                />
                                <label className='cmt-checkbox-label'>Administración Médica</label>
                              </p>
                            </Col>
                          }
                        </Row>
                      </Col>
                      {
                        isSuperUser &&
                        <Col lg={24} xs={24} className="mt-30">
                          <Row justify='end'>
                            <Col xl={5} lg={6} xs={24}>
                              <Button
                                onClick={() => setEditMode(true)}
                                className={`cmt-button secondary ${disabled ? 'disabled' : ''}`}
                                type='primary'
                                size='small'
                                disabled={disabled}
                              >
                                <i className='icon-system-edit mr-10' />
                                Editar datos
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      }
                    </Row>
                  </Col>
                </Row>
              }
            </Col>
            :
            <Col lg={24} xs={24}>
              <UsersForm
                form={form}
                loading={loading}
                userEmail={currentUser['email']}
                isSuperUser={isSuperUser}
                containerType="userModal"
                saveUser={saveUser}
                countryCode={countryCode}
                showModalPassword={showModalPassword}
              />
            </Col>
        }
      </Row >
      <SetPasswordModal
        modalVisible={passwordModalVisible}
        setModalVisible={setPasswordModalVisible}
        savePassword={saveUser}
      />
    </Modal >

  )
}

export default DetailsModal