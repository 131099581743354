import config from "../config";
import { handleResponse } from './auth';

export const getCompanies = async (params) => {
  const url = new URL(`${config.client_api}/companies/user/${params.userEmail}`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const getCompaniesCostCenters = async (params) => {
  const url = new URL(`${config.client_api}/companies/costCenters/${params.companyId}`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const getCompaniesUsers = async (params) => {
  const url = new URL(`${config.client_api}/companies/users/${params.companyId}`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const getUserProfileData = async (params) => {
  const url = new URL(`${config.client_api}/users/${params.email}/`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const getUserInviteService = async (token) => {
  const url = new URL(`${config.client_api}/users/invites`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const saveUsersService = async (params, token) => {
  const url = new URL(`${config.client_api}/users/save`)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token || localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params)
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const saveUserProfileService = async (params, token) => {
  const url = new URL(`${config.client_api}/users/profile/save`)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(params)
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const createUserProfileService = async (params, token) => {
  const url = new URL(`${config.client_api}/users/create`)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(params)
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const setUserPassword = async (params, userId) => {
  const url = new URL(`${config.client_api}/users/${userId}/updatePassword`)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(params)
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const inviteNewUsers = async (params) => {
  const url = new URL(`${config.client_api}/users/invites`)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(params)
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const saveCostCenterService = async (params) => {
  const url = new URL(`${config.client_api}/companies/costCenter/${params.companyId}`)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(params)
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const billingDataService = async (params) => {
  const url = new URL(`${config.client_api}/companies/billingData/${params.companyId}`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  }
  return fetch(url, requestOptions).then(handleResponse);
}

export const saveBillingDataService = async (params) => {
  const url = new URL(`${config.client_api}/companies/billingData/${params.companyId}`)
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(params),
  }
  return fetch(url, requestOptions).then(handleResponse);
}