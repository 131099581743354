import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Link } from 'gatsby';
import {
  Spin,
  Row,
  Col,
  Button,
  Radio,
  Empty,
  Popconfirm,
  message,
  Tooltip,
} from "antd";
import { reservationService } from '../../../../services/reservations';
import humanizeList from 'humanize-list';
import moment from 'moment-timezone';
import momentOriginal from 'moment';
import { sortBy, uniq } from 'lodash';
import { useTranslation } from "react-i18next";

const Items = ({ query, company, startDate, endDate, branchOffice, type }) => {
  const [loading, setLoading] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    const filters = getFilters();
    const extra = { page: 1, type: type };
    fetchReservation({ ...filters, ...extra });
  }, [
    query,
    company,
    startDate,
    endDate,
    branchOffice
  ]);

  const getFilters = () => {
    const params = {
      query,
      company,
      startDate,
      endDate,
      branchOffice,
    };

    return Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined)
    );
  };

  const loadMore = (e) => {
    const direction = e.target.value;
    const _currentPage = direction === 'next' ? currentPage + 1 : currentPage - 1
    const extra = { page: _currentPage, type: type };
    const filters = getFilters();
    fetchReservation({ ...filters, ...extra });
    setCurrentPage(_currentPage);
  };

  const fetchReservation = async (filters) => {
    setLoading(true);
    const response = await reservationService.getReservations(filters);
    setReservations(response);
    setLoading(false);
  };

  const fetchDeleteReservation = async (idNumber) => {
    await reservationService.deleteReservation(idNumber);
  };

  const confirm = async (idNumber) => {
    try {
      await fetchDeleteReservation(idNumber);
      ReactGA.event({
        category: "Reservation",
        action: "cancel",
        label: idNumber,
      });

      message.success('Reserva anulada', 5).then(async () => {
        const filters = getFilters();
        const extra = { page: 1, type: type };
        await fetchReservation({ ...filters, ...extra });
      });
    } catch (error) {
      message.error(`Ha ocurrido un error anulando la reserva ${error}`, 5);
    }
  };

  const cancel = (e) => {
    console.log(e);
  };

  const formatDate = (date) => {
    return momentOriginal(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
  };

  const disabledCancel = (reservation) => {
    const date = moment(reservation.reservation_date, 'YYYY-MM-DDTHH:mm:ssZ').tz(reservation.timezone).subtract(1, 'day');
    if (date.toDate() < moment().tz(reservation.timezone).toDate()) {
      return true;
    }

    return false
  }

  const disabledPreAdmission = (reservation) => {
    const date = moment(reservation.reservation_date, 'YYYY-MM-DDTHH:mm:ssZ').tz(reservation.timezone);
    if (date.toDate() <= moment().tz('Etc/UTC').toDate()) {
      return true;
    }

    return false;
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        {reservations.length ? (
          <React.Fragment>
            <Row justify="center" className="titles-desktop-wrapper">
              <Col lg={3}>
                <p className="mb-5">{t('reservations.items.reservationNumberTitle')}</p>
              </Col>
              <Col lg={8}>
                <p className="mb-5">{t('reservations.items.dateTitle')}</p>
              </Col>
              <Col lg={13}>
                <p className="mb-5">{t('reservations.items.branchOfficeTitle')}</p>
              </Col>
            </Row>
            {
              reservations.map((reservation, index) => {
                return (
                  <Row
                    justify="start"
                    align="middle"
                    key={index}
                    className="data-table-reservations pt-lg-0 pt-md-30 pt-sm-30 pt-xs-30 pb-lg-5 pb-ms-30 pb-sm-30 pb-xs-30 pl-lg-0 pl-md-15 pl-sm-15 pl-xs-15 pr-lg-0 pr-md-15 pr-sm-15 pr-xs-15 mt-10"
                  >
                    <Col lg={{ order: 1, span: 3 }} xs={{ order: 1, span: 12 }}>
                      <Row justify="center">
                        <Col xs={24} className="title-mobile-wrapper">
                          <h2>{t('reservations.items.reservationNumberTitle')}</h2>
                        </Col>
                        <Col
                          lg={24}
                          xs={24}
                          className="data-column pt-lg-10 pb-10"
                        >
                          <p
                            className="reservation-number ml-lg-15 ml-xs-0 mb-0"
                            key="1"
                          >
                            {reservation.number}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      lg={{ order: 2, span: 8 }}
                      xs={{ order: 3, span: 24 }}
                      className="date-wrapper mt-xs-15"
                    >
                      <Row justify="center">
                        <Col xs={24} className="title-mobile-wrapper">
                          <h2>{t('reservations.items.dateTitle')}</h2>
                        </Col>
                        <Col
                          lg={24}
                          xs={24}
                          className="data-column pt-lg-10 pb-10"
                        >
                          <p className="mb-0 date-block" key="2">
                            {formatDate(reservation.reservation_date)} -&nbsp;
                            {humanizeList(
                              sortBy(
                                uniq(reservation.block.split(","), (block) => {
                                  return block.split(":")[0];
                                })
                              ),
                              { conjunction: "y" }
                            )}&nbsp;
                            hs
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={{ order: 3, span: 4 }} xs={{ order: 2, span: 12 }}>
                      <Row justify="center">
                        <Col xs={24} className="title-mobile-wrapper">
                          <h2>{t('reservations.items.branchOfficeTitle')}</h2>
                        </Col>
                        <Col
                          lg={24}
                          xs={24}
                          className="data-column pt-lg-10 pb-10"
                        >
                          <p className="branch-office-text mb-0" key="3">
                            {reservation.branch_office}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={{ order: 4, span: 9 }} xs={{ order: 4, span: 24 }}>
                      <Row justify="start">
                        <Col
                          lg={8}
                          xs={24}
                          id={`${index === 0 && "next-detail-button"}`}
                          className={`data-column column-padding pr-lg-15 pr-md-15 pr-sm-0 pr-xs-0 ${index === 0 && "next-detail-button"
                            }`}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Link
                            to={`/app/reservas/${reservation.number}`}
                            style={{ width: "100%" }}
                          >
                            <Button
                              className="cmt-button secondary"
                              type='primary'
                              block
                            >
                              Ver detalle
                            </Button>
                          </Link>
                        </Col>
                        {
                          type === 'nextReservation' && <React.Fragment>
                            <Col
                              className="data-column column-padding pr-lg-15 pr-md-15 pr-sm-0 pr-xs-0 pl-xs-0 mt-lg-0 mt-md-20 mt-sm-20 mt-xs-20 mb-lg-0 mb-md-20 mb-sm-20 mb-xs-20"
                              lg={8}
                              xs={24}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {
                                disabledCancel(reservation) ? (
                                  <Tooltip
                                    color='#223A70'
                                    placement="topLeft"
                                    title="Sólo puedes anular una reserva 24 horas antes de la fecha."
                                  >
                                    <Button
                                      disabled
                                      className="cmt-button disabled"
                                      type='primary'
                                      block
                                    >
                                      Anular hora
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <Popconfirm
                                    title="¿Estás seguro?"
                                    onConfirm={() => confirm(reservation.number)}
                                    onCancel={cancel}
                                    okText="Sí"
                                    cancelText="No"
                                  >
                                    <Button
                                      id={`${index === 0 && "cancel-schedule"}`}
                                      className="cmt-button secondary outline"
                                      type='primary'
                                      block
                                    >
                                      Anular hora
                                    </Button>
                                  </Popconfirm>
                                )
                              }
                            </Col>
                            <Col
                              lg={8}
                              xs={24}
                              id={`${index === 0 && "pre-admission"}`}
                              className={`data-column column-padding pr-lg-15 pr-md-15 pr-sm-0 pr-xs-0 pl-xs-0 mb-lg-0 mb-md-20 mb-sm-20 mb-xs-20 ${index === 0 && "pre-admission"
                                }`}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {disabledPreAdmission(reservation) ? (
                                <Tooltip
                                  color='#223A70'
                                  placement="topLeft"
                                  title="Sólo puedes realizar la pre-admisión hasta 1 hora antes de la reserva."
                                >
                                  <Button
                                    disabled={true}
                                    className="cmt-button disabled"
                                    type='primary'
                                    block
                                  >
                                    Pre-admisión
                                  </Button>
                                </Tooltip>
                              ) : (
                                <Link
                                  to={`/app/reservas/${reservation.number}/pre-admision`}
                                  style={{ width: "100%" }}
                                >
                                  <Button
                                    className="cmt-button secondary outline"
                                    type='primary'
                                    block
                                  >
                                    Pre-admisión
                                  </Button>
                                </Link>
                              )
                              }
                            </Col>
                          </React.Fragment>
                        }
                      </Row>
                    </Col>
                  </Row>
                )
              })
            }
          </React.Fragment>
        ) : (
          <Empty description="No hay empresas asociadas" className='company-profile-empty' />
        )}
        <div className="mt-20">
          <Radio.Group value={"large"} onChange={loadMore}>
            <Radio.Button value="prev" disabled={currentPage === 1}>
              Anterior
            </Radio.Button>
            <Radio.Button
              value="next"
              disabled={!reservations.length || reservations.length < 5}
            >
              Siguiente
            </Radio.Button>
          </Radio.Group>
        </div>
      </Spin>
    </React.Fragment>
  );
};

export default Items;
