import React, { Fragment, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Tooltip,
  Divider,
  Checkbox,
  Radio,
} from 'antd'
import { PhoneInput, DocumentCard } from 'cmt-ui-kit';

const UsersForm = ({
  form,
  loading,
  saveUser,
  showModalPassword,
  userEmail,
  isSuperUser,
  containerType,
  countryCode,
}) => {
  const [documentType, setDocumentType] = useState(countryCode && countryCode.toUpperCase() === 'CL' ? 'rut' : 'dni');

  const tooltipContent = (
    <Row>
      <Col lg={24} xs={24}>
        <Row>
          <Col>
            <span className='icon-alert-rounded' />
          </Col>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <h3 className='mb-0'>Edición</h3>
          </Col>
        </Row>
      </Col>
      <Col lg={24} xs={24}>
        <p className='mb-0'>Contacta a tu ejecutivo comercial CMT para editar este campo.</p>
      </Col>
    </Row>
  );

  return (
    <Form
      form={form}
      onFinish={saveUser}
      layout="vertical"
      size='large'
      className='cmt-form'
    >
      <Row justify='center'>
        <Col
          lg={24}
          xs={24}
          className="user-header-wrapper mt-20 mb-20 pt-40 pb-40"
        >
          <Row>
            <Col lg={24} xs={24}>
              {
                (containerType === "userModal") &&
                <h1 className='text-center mb-0'>{userEmail && userEmail}</h1>
              }
              {
                (containerType === "newUserPage") &&
                <h1 className='text-center mb-0'>Nuevo usuario</h1>
              }
              {
                (containerType === "userPage") &&
                <h1 className='text-center mb-0'>Editar usuario</h1>
              }
            </Col>
            <Col lg={24} xs={24} className={containerType !== 'userModal' ? 'hidden' : ''}>
              <Row justify='center' gutter={10}>
                <Col style={{ display: 'flex', alignItems: 'center' }}>
                  <p className='mb-0'>Usuario activo:</p>
                </Col>
                <Col>
                  <Form.Item
                    name="active"
                    rules={[{
                      required: true,
                      message: 'Debes seleccionar actividad'
                    }]}
                    className='mb-0'
                  >
                    <Radio.Group
                      className='cmt-radio'
                      disabled={containerType !== 'userModal'}
                    >
                      <Radio value={true}>Sí</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col
          lg={20}
          xs={22}
        >
          <Row justify='center' className='user-body-wrapper'>
            <Col lg={24} xs={24}>
              <h2>Perfil de usuario</h2>
            </Col>
            <Col lg={12} xs={24}>
              <Row>
                <Col lg={21} xs={24}>
                  <Form.Item
                    name="firstName"
                    label="Nombre"
                    rules={[{
                      required: true,
                      message: "Nombre es requerido"
                    }]}
                  >
                    <Input className='cmt-input' placeholder='Ingrese nombre' />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col lg={12} xs={24}>
              <Row>
                <Col lg={21} xs={24}>
                  <Form.Item
                    name="lastName"
                    label="Apellido"
                    rules={[{
                      required: true,
                      message: "Apellido es requerido"
                    }]}
                  >
                    <Input className='cmt-input' placeholder='Ingrese apellido' />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col lg={12} xs={24}>
              <Row>
                <Col lg={21} xs={24}>
                  <PhoneInput.ReactPhone
                    countryCode={countryCode}
                    form={form}
                    labelText='Teléfono'
                  />
                </Col>
              </Row>
            </Col>

            <Col lg={12} xs={24}>
              <Row>
                <Col lg={21} xs={24}>
                  <Form.Item
                    name="email"
                    label="Correo electrónico"
                    rules={[{
                      required: true,
                      message: 'Correo electrónico es requerido'
                    }]}
                  >
                    <Input
                      className='cmt-input'
                      placeholder='Ingrese correo electrónico'
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col lg={3} xs={3} className="icon-container pt-20">
                  <Tooltip
                    title={tooltipContent}
                    overlayClassName="info-tooltip"
                    color="#223A70"
                    placement='right'
                  >
                    <span className='icon-alert-rounded' />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            <Col lg={12} xs={24}>
              <Row>
                <Col lg={21} xs={24}>
                  <Form.Item
                    label="Tipo y número de documento"
                  >
                    <DocumentCard
                      countryCode={countryCode}
                      setDocumentType={setDocumentType}
                      form={form}
                      type='person'
                      disabled={containerType != "newUserPage"}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col lg={12} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
              {
                ((isSuperUser && containerType === "userModal") || (containerType !== "userModal")) &&
                <Row style={{ width: '100%' }}>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      name="contraseña"
                      label="Contraseña"
                    >
                      <Button
                        onClick={showModalPassword}
                        className='cmt-button primary outline'
                        type='primary'
                        size='middle'
                      >
                        Configurar contraseña
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              }
            </Col>
            {
              (isSuperUser || ['newUserPage', 'userPage'].includes(containerType)) &&
              <Col xs={24}>
                <Divider />
                <Col lg={24} xs={24}>
                  <h2>Usuario autorizado para</h2>
                </Col>
                <Row align="middle">
                  <Col lg={12} xs={24}>
                    <Form.Item
                      name="canReserve"
                      valuePropName="checked"
                      className='mb-0'
                    >
                      <Checkbox
                        className="cmt-checkbox"
                        disabled={['newUserPage', 'userPage'].includes(containerType) || !isSuperUser}
                      >
                        Realizar reservas
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      name="canViewStatistics"
                      valuePropName="checked"
                      className='mb-0'
                    >
                      <Checkbox
                        className="cmt-checkbox"
                        disabled={['newUserPage', 'userPage'].includes(containerType) || !isSuperUser}
                      >
                        Revisión portal estadísticas
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      name="canDownloadResults"
                      valuePropName="checked"
                      className='mb-0'
                    >
                      <Checkbox
                        className="cmt-checkbox"
                        disabled={['newUserPage', 'userPage'].includes(containerType) || !isSuperUser}
                      >
                        Visualizar y descargar resultados
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    <Form.Item
                      name="canViewInvoices"
                      valuePropName="checked"
                      className='mb-0'
                    >
                      <Checkbox
                        className="cmt-checkbox"
                        disabled={['newUserPage', 'userPage'].includes(containerType) || !isSuperUser}
                      >
                        Información de Facturación
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col lg={12} xs={24}>
                    {
                      countryCode?.toUpperCase() === 'PE' &&
                      <Form.Item
                        name="medicalAdministation"
                        valuePropName="checked"
                        className='mb-0'
                      >
                        <Checkbox
                          className="cmt-checkbox"
                          disabled={['newUserPage', 'userPage'].includes(containerType) || !isSuperUser}
                        >
                          Administración Médica
                        </Checkbox>
                      </Form.Item>
                    }
                  </Col>
                </Row>
              </Col>
            }

            <Col lg={12} xs={24}>
              <Row>
                <Col lg={21} xs={21}>
                  <Form.Item
                    name="companies"
                    label="Empresa"
                  >
                    <Input
                      className='cmt-input'
                      placeholder='Ingrese empresa'
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col lg={3} xs={3} className="icon-container pt-20">
                  <Tooltip
                    title={tooltipContent}
                    overlayClassName="info-tooltip"
                    color="#223A70"
                    placement='right'
                  >
                    <span className='icon-alert-rounded' />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
            <Col lg={12} xs={24}>
              <Row>
                <Col
                  lg={12} xs={24}
                  className='mt-30 pt-10'
                >
                  <Button
                    loading={loading.form}
                    className='cmt-button primary'
                    type='primary'
                    htmlType='submit'
                  >
                    {
                      containerType === "newUserPage" ? 'Crear usuario' : 'Guardar cambios'
                    }
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

export default UsersForm