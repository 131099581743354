import { HasAccess } from '@permify/react-role';
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Empty,
  Form,
  Modal,
  Row,
  Select,
  Tag,
  message
} from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import Joyride from "react-joyride";
import { useMount } from "react-use";
import { billingService } from "../../../services/billing";
import { dashboardService } from "../../../services/dashboard";
import { reservationService } from "../../../services/reservations";
import AgeAndGenderList from "./components/ageAndGenderList";
import BatteriesList from "./components/batteriesList";
import BranchOfficeList from "./components/branchOfficeList";
import GeneralInformation from "./components/generalInformation";

const { RangePicker } = DatePicker;
const defaultDates = [moment().subtract(1, "year"), moment()];

const Dashboard = () => {
  const [companies, setCompanies] = useState();
  const [branchOffices, setBranchOffices] = useState();
  const [loading, setLoading] = useState({
    generalInformation: false,
    download: false,
  });
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startClientsTutorial, setStartClientsTutorial] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const fetchCompanies = async () => {
    setLoading(loading => ({ ...loading, generalInformation: true }));
    try {
      const response = await billingService.getCompanies({ 'permission': 'can_view_statistics' });
      if (response?.length > 0) {
        setCompanies(response);
        await fetchBranchOffices({ companies: response.map(company => company.id) })
        form.setFieldsValue({
          dates: defaultDates,
          company: response[0].id,
        });
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: `Ha ocurrido un error al obtener las compañías. ${error}`,
        style: {
          marginTop: '40vh'
        }
      })
    } finally {
      setLoading(loading => ({ ...loading, generalInformation: false }));
    }
  };

  const fetchBranchOffices = async (params) => {
    try {
      const response = await reservationService.getBranchOffices(params);
      if (response) {
        setBranchOffices(response)
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: `Ha ocurrido un error al obtener las ${t('reservations.list.branchOffice')}. ${error}`,
        style: {
          marginTop: '40vh'
        }
      })
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const showDrawer = () => {
    setVisibleDrawer(true);
  };

  const closeDrawer = () => {
    setVisibleDrawer(false);
  };

  const downloadXls = () => {
    try {
      setLoading(loading => ({ ...loading, download: true }));
      const values = form.getFieldsValue();
      let dates = defaultDates;

      if (values.dates) {
        dates = values.dates;
      }

      dashboardService.download(branchOffices, {
        startDate: dates[0].format("YYYY-MM-DD"),
        endDate: dates[1].format("YYYY-MM-DD"),
        company: values.company || companies[0].id,
      });
    } catch (error) {
      console.error("Error al descargar la matriz de resultados", error);
      message.error({
        content: `Ha ocurrido un error al descargar la matriz de resultados.`,
        style: {
          marginTop: '40vh'
        }
      })
    } finally {
      setLoading(loading => ({ ...loading, download: false }));
    }
  };

  const findSelectedCompany = () => {
    const findCompany = companies.findIndex(
      (item) => item.id === form.getFieldValue("company")
    );
    const filterCompany = companies[findCompany].business_name;
    return filterCompany;
  };

  const onFinish = (values) => {
    closeDrawer();
  };

  let steps = [
    {
      target: ".filter-button",
      placement: "right",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.dashboard.stepOne.title')}</p>,
      content: (
        <p className="joyride-content" dangerouslySetInnerHTML={{ __html: t('joyRide.dashboard.stepOne.content') }} />
      ),
    },
    {
      target: ".general-info",
      placement: "bottom-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.dashboard.stepTwo.title')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.dashboard.stepTwo.content')}
        </p>
      ),
    },
    {
      target: ".branch-office-info",
      placement: "bottom-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.dashboard.stepThree.title')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.dashboard.stepThree.content')}
        </p>
      ),
    },
    {
      target: ".services-info",
      placement: "bottom-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.dashboard.stepFour.title')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.dashboard.stepFour.content')}
        </p>
      ),
    },
    {
      target: ".services-results-info",
      placement: "bottom-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: (
        <p className="joyride-title">
          {t('joyRide.dashboard.stepFive.title')}
        </p>
      ),
      content: (
        <p className="joyride-content">
          {t('joyRide.dashboard.stepFive.content')}
        </p>
      ),
    },
    {
      target: ".download-xls",
      placement: "left",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.dashboard.stepSix.title')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.dashboard.stepSix.content')}
        </p>
      ),
    },
  ];

  useMount(() => {
    const checkTutorialState = localStorage.getItem(
      "statisticsTutorialDisabled"
    );

    if (checkTutorialState === "true") {
      setIsModalVisible(false);
    } else {
      if (!isMobile) {
        setIsModalVisible(true);
      }
    }
  });

  const closeModal = () => {
    setIsModalVisible(false);
    localStorage.setItem("statisticsTutorialDisabled", true);
  };

  const startTutorial = () => {
    setStartClientsTutorial(true);
    setIsModalVisible(false);
  };

  const joyrideCallback = (data) => {
    const { action, index } = data;

    if (action === "skip" || action === "reset") {
      localStorage.setItem("statisticsTutorialDisabled", true);
    }

    if (action === 'update') {
      const className = {
        0: 'filter-button"',
        1: 'general-info',
        2: 'branch-office-info',
        3: 'services-info',
        4: 'services-results-info',
        5: 'download-xls',
      };
      const element = document.getElementById(className[index]);
      if (element) {
        if ([2, 3, 4].includes(index)) {
          const localization = element.getBoundingClientRect();
          if (localization) {
            window.scroll({ top: localization.top - 150 });
          }
        } else {
          window.scroll({ top: 0 });
        }
        element.classList.add("animate__animated", "animate__headShake");
      }
    }
  };

  return (
    <Row justify="center" className="dashboard-container mt-10">
      <Joyride
        callback={joyrideCallback}
        run={startClientsTutorial}
        steps={steps}
        continuous
        showSkipButton
        disableOverlay
        disableScrolling
        styles={{
          options: {
            overlay: { height: "100vh" },
          },
        }}
        locale={{
          skip: "Omitir",
          next: "Continuar",
          last: "Finalizar",
          back: "Atrás",
        }}
      />
      <Col lg={22} xs={22}>
        <Row justify="center">
          <Col lg={14} className="statistics-title-wrapper">
            <h1 className="text-center">{t('dashboard.mainTitle')}</h1>
          </Col>
        </Row>
      </Col>
      <Col lg={22} xs={22}>
        <Row gutter={[16, 16]}>
          <Col lg={5} xs={24}>
            <Button
              id="filter-button"
              className="cmt-button primary"
              type="primary"
              onClick={showDrawer}
              size="large"
              block
            >
              <span className="icon-system-search mr-10" />
              Filtrar información
            </Button>
            <Drawer
              placement="left"
              closable={true}
              onClose={closeDrawer}
              open={visibleDrawer}
              width={450}
              className="dashboard-drawer"
            >
              <Row
                justify="center"
                align="middle"
                className="drawer-container mt-40"
              >
                <Col lg={17} md={15} sm={19} xs={21}>
                  <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    size="large"
                    className="cmt-form"
                  >
                    <Form.Item label="Fecha" name="dates">
                      <RangePicker
                        locale={locale}
                        className="cmt-date-picker"
                        placeholder={["Desde", "Hasta"]}
                        picker="date"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>

                    <Form.Item
                      name="company"
                      label="Empresa"
                      labelCol={{ span: 24 }}
                    >
                      {companies && (
                        <Select
                          defaultValue={companies[0].id}
                          className="cmt-select"
                          placeholder="Selecciona empresa"
                        >
                          {companies.map((company) => {
                            return (
                              <Select.Option
                                key={company.id}
                                value={company.id}
                              >
                                {company.business_name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      )}
                    </Form.Item>

                    <Row justify="center">
                      <Col lg={19} xs={24} className="mt-20">
                        <Form.Item>
                          <Button
                            className="cmt-button primary"
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block
                          >
                            <span className="icon-system-search mr-10" />
                            Ver resultados
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Drawer>
          </Col>
          <Col lg={10} xs={0}></Col>
          <Col lg={9} xs={24}>
            <Row justify="end">
              <Col lg={24} xs={24}
                className="mt-xs-15"
              >
                <Button
                  id="download-xls"
                  className="cmt-button secondary outline mt-lg-0 mt-md-15 mt-xs-15"
                  onClick={downloadXls}
                  style={{ float: "right" }}
                  loading={loading.download}
                  type="default"
                  size="large"
                  disabled={(companies && companies.length === 0) || !companies}
                >
                  <span className="icon-system-download mr-10" />
                  Descargar información .xls
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <h4 className="ml-xs-0 mb-0 mt-lg-10 mt-md-15 mt-xs-15">
              {t('dashboard.filterText')}
            </h4>
          </Col>
          <Col
            lg={18}
            className="filter-tags-wrapper ml-lg-15 ml-xs-0 mt-lg-10 mt-md-10 mt-xs-10"
          >
            {form.getFieldValue("dates") && (
              <Tag>
                {form.getFieldValue("dates")[0].format("LL")} a{" "}
                {form.getFieldValue("dates")[1].format("LL")}
              </Tag>
            )}

            {companies && form.getFieldValue("company") && (
              <Tag>{findSelectedCompany()}</Tag>
            )}
          </Col>
        </Row>
        <Row
          justify="center"
          className="general-information mt-lg-50 mt-md-30 mt-xs-30 mb-lg-60 mb-md-40 mb-xs-40"
        >
          <Col lg={24} xs={24} className="mb-15">
            <h2 id="general-info" className="general-info">
              {t('dashboard.generalInformation.mainTitle')}
            </h2>
          </Col>
          <Col lg={24} xs={24}>
            {
              (companies &&
                form.getFieldValue("dates") &&
                form.getFieldValue("company"))
                ? <GeneralInformation form={form} mainLoading={loading.generalInformation} />
                : <Empty description="Sin datos" />
            }
          </Col>
        </Row>

        {
          companies &&
          form.getFieldValue("dates") &&
          form.getFieldValue("company") && (
            <React.Fragment>
              <BranchOfficeList form={form} />
              <BatteriesList form={form} />
              <AgeAndGenderList form={form} />
            </React.Fragment>
          )
        }
      </Col >
      <Modal
        open={isModalVisible}
        footer={false}
        onCancel={closeModal}
        width={900}
        closable={false}
        className="tutorial-modal"
      >
        <Row justify="center" className="mt-30 mb-30">
          <Col>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="75.636"
              height="75.636"
              viewBox="0 0 75.636 75.636"
            >
              <g
                id="Grupo_383"
                data-name="Grupo 383"
                transform="translate(-1391.939 -1820.278)"
              >
                <g id="Grupo_381" data-name="Grupo 381">
                  <rect
                    id="Rectángulo_346"
                    data-name="Rectángulo 346"
                    width="16.498"
                    height="45.559"
                    transform="translate(1413.236 1840.947)"
                    fill="#309a80"
                  />
                  <rect
                    id="Rectángulo_347"
                    data-name="Rectángulo 347"
                    width="16.498"
                    height="27.484"
                    transform="translate(1439.684 1859.023)"
                    fill="#309a80"
                  />
                </g>
                <g id="Grupo_382" data-name="Grupo 382">
                  <rect
                    id="Rectángulo_348"
                    data-name="Rectángulo 348"
                    width="10.238"
                    height="75.636"
                    transform="translate(1391.939 1820.278)"
                    fill="#96ccbf"
                  />
                  <rect
                    id="Rectángulo_349"
                    data-name="Rectángulo 349"
                    width="75.636"
                    height="9.408"
                    transform="translate(1391.939 1886.506)"
                    fill="#96ccbf"
                  />
                </g>
              </g>
            </svg>
          </Col>
          <Col lg={24} xs={24} className="mt-30">
            <h1 className="text-center statistics">{t('joyRide.dashboard.modal.title')}</h1>
            <p className="text-center">
              {t('joyRide.dashboard.modal.content')}
            </p>
          </Col>
          <Col lg={24} xs={24} className="mt-30">
            <Row justify="center">
              <Col className="mr-30">
                <Button
                  className="cmt-button primary outline"
                  type="primary"
                  onClick={closeModal}
                  size="large"
                >
                  Omitir tutorial
                </Button>
              </Col>
              <Col>
                <Button
                  className="cmt-button primary"
                  type="primary"
                  onClick={startTutorial}
                  size="large"
                >
                  ¡Muéstrame!
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Row >
  );
};

export default Dashboard;
