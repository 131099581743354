import { useState } from 'react';
import { Row, Col, Button, Tag } from 'antd';
import DrawerFilter from './DrawerFilter';
import moment from 'moment';
import useXlsGenerator from '../hooks/useXlsGenerator';

const Header = ({
  t,
  form,
  loading,
  companies,
  branchOffices,
  filters,
  setFilters,
  currentPage,
  setCurrentPage,
  setExpirationPage,
  }) => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const {
    loadingXls,
    setGetJSON,
  } = useXlsGenerator(filters);


  const handleCurrentPage = (page) => {
    if (currentPage !== page) {
      setCurrentPage(page);
    }
  };

  const findCompany = (id) => {
    const filter = companies.filter(item => item.id === id)[0];
    return filter.business_name;
  };

  const findBranchOffice = (id) => {
    const filter = branchOffices.filter(item => item.id === id)[0];
    return filter.name;
  };

  const removeFilter = (key) => {
    const _filters = filters;

    delete _filters[key];

    setFilters({ ..._filters });
    setExpirationPage(expirationPage => ({
      ...expirationPage,
      critical: 1,
      warning: 1,
      normal: 1,
      expired: 1,
      observed: 1,
    }));
  };

  const downloadXlsFile = () => {
    setGetJSON(getJSON => ({ ...getJSON, medical: true }));
  };


  return (
    <Row gutter={[10, 20]} className='header-wrapper'>
      <Col lg={24} xs={24}>
        <Row justify='space-between' align='middle' gutter={[0, 30]}>
          <Col xxl={18} xl={17} lg={16} xs={24}>
            <Row gutter={[10, 20]}>
              <Col xxl={4} xl={6} lg={7} xs={24}>
                <Button
                  className={
                    currentPage === 'medical_care' ? 'cmt-button primary outline' : 'cmt-button disabled outline'
                  }
                  type='primary'
                  onClick={() => handleCurrentPage('medical_care')}
                >
                  Atenciones Médicas
                </Button>
              </Col>
              <Col xxl={4} xl={5} lg={5} xs={24}>
                <Button
                  className={
                    currentPage === 'expirations' ? 'cmt-button primary outline' : 'cmt-button disabled outline'
                  }
                  type='primary'
                  onClick={() => handleCurrentPage('expirations')}
                >
                  Vencimientos
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xxl={6} xl={7} lg={8} xs={24}>
            <Button
              className='cmt-button secondary outline'
              type='primary'
              size='large'
              loading={loadingXls.medical}
              disabled={loadingXls.medical}
              onClick={downloadXlsFile}
            >
              <span className='icon-system-download mr-10' />
              Descargar Vigilancia Médica .xlsx
            </Button>
          </Col>
        </Row>
      </Col>
      <Col lg={24} xs={24}>
        <h1 className='text-center mt-40 mb-20'>
          {currentPage === 'medical_care' ? 'Atenciones Médicas' : 'Vencimientos'}
        </h1>
      </Col>
      <Col lg={24} xs={24}>
        <Row>
          <Col xxl={4} xl={5} lg={6} xs={24}>
            <Button
              className='cmt-button primary'
              type='primary'
              size='large'
              onClick={() => setVisibleDrawer(true)}
            >
              <span className='icon-system-search mr-10' />
              Filtrar información
            </Button>
          </Col>
          <Col lg={24} xs={24} className='filters-wrapper mt-30'>
            <Row gutter={[0, 10]}>
              <Col>
                <p className='bold mr-15'>Filtros aplicados:</p>
              </Col>
              {
                filters.query &&
                <Col>
                  <Tag className="cmt-tag" closable onClose={() => removeFilter('query')}>
                    {filters.query}
                  </Tag>
                </Col>
              }
              {
                (filters.startDate && filters.endDate) &&
                <Col>
                  <Tag className='cmt-tag'>
                    {moment(filters.startDate).format('DD-MM-YYYY')} a {moment(filters.endDate).format('DD-MM-YYYY')}
                  </Tag>
                </Col>
              }
              {
                filters.branchOfficeId &&
                  <Col>
                    <Tag className='cmt-tag' closable onClose={() => removeFilter('branchOfficeId')}>
                      {findBranchOffice(filters.branchOfficeId)}
                    </Tag>
                  </Col>
              }
              {
                filters.companyId &&
                  <Col>
                    <Tag className='cmt-tag' closable onClose={() => removeFilter('companyId')}>
                      {findCompany(filters.companyId)}
                    </Tag>
                  </Col>
              }
            </Row>
          </Col>
        </Row>

        <DrawerFilter
          t={t}
          form={form}
          loading={loading}
          companies={companies}
          branchOffices={branchOffices}
          filters={filters}
          setFilters={setFilters}
          visibleDrawer={visibleDrawer}
          setVisibleDrawer={setVisibleDrawer}
        />
      </Col>
    </Row>
  );
};

export default Header;