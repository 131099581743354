import React, { useState, useEffect } from "react";
import { Row, Col, Tabs } from "antd";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { dashboardService } from "../../../../services/dashboard";
import { maxBy } from "lodash";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;
const dateFormat = "YYYY-MM-DD";

const AgeAndGenderList = ({ batteryList, form }) => {
  const [ageRange, setAgeRange] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const getFilters = () => {
    const params = {
      startDate: form.getFieldValue("dates")[0].format(dateFormat),
      endDate: form.getFieldValue("dates")[1].format(dateFormat),
      companyId: form.getFieldValue("company"),
    };
    return Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined)
    );
  };

  const getAgeRange = async () => {
    const filters = getFilters();
    setLoading(false);
    const ageResponse = await dashboardService.byAgeRange(filters);
    setAgeRange(ageResponse);
    setLoading(false);
  };

  const ageMessage = () => {
    const maxApproved = maxBy(ageRange, (item) => {
      return parseInt(item.approved);
    });

    return `La mayor cantidad de aprobados se encuentra en el rango de entre ${maxApproved.ranges} años de edad.`;
  };


  useEffect(() => {
    getAgeRange();
  }, [
    form.getFieldValue("company"),
    form.getFieldValue("dates")[0].format(dateFormat),
    form.getFieldValue("dates")[1].format(dateFormat),
  ]);


  return (
    <React.Fragment>
      <Row
        justify="center"
        className="exam-result-distribution-container mb-lg-170 mb-md-60 mb-xs-60"
      >
        <Col lg={24} xs={24}>
          <h2 id="services-results-info" className="services-results-info">
            {t('dashboard.ageAndGenderList.distributionByResultsTitle')}
          </h2>
        </Col>
        <Col lg={24} xs={24} className="tab-wrapper mt-lg-50 mt-xs-5 pb-20">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Por rango de edad" key="1" className="tab-by-age">
              <Row justify="center">
                {ageRange &&
                  ageRange.filter((item) => item.approved > 0).length > 1 && (
                    <Col lg={24} xs={24} className="tab-notice">
                      <h3 className="pt-20 pb-15 ml-lg-40 ml-md-15 ml-xs-15 mr-md-10 mr-xs-10">
                        {ageMessage()}
                      </h3>
                    </Col>
                  )}

                <Col lg={24} xs={24} className="bar-chart-wrapper mt-40">
                  <Row justify="end" className="dots-titles-wrapper mt-40 mr-5">
                    <Col lg={24} xs={24}>
                      <Row justify="end">
                        <Col lg={24} xs={24}>
                          <Row
                            justify="end"
                            className="dot-state-wrapper ml-20"
                          >
                            <Col
                              xs={1}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div className="approved-chart-dot mt-lg-0 mt-xs-5"></div>
                            </Col>
                            <Col lg={3} xs={8}>
                              <p className="mb-10">{t('dashboard.batteriesList.approvedTitle')}</p>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={24} xs={24}>
                          <Row
                            justify="end"
                            className="dot-state-wrapper ml-20"
                          >
                            <Col
                              xs={1}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div className="disapproved-chart-dot mt-lg-0 mt-xs-5"></div>
                            </Col>
                            <Col lg={3} xs={8}>
                              <p className="mb-10">{t('dashboard.batteriesList.notApprovedTitle')}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row justify="center" className="bar-chart-container">
                    <div className="bar-chart">
                      {ageRange && (
                        <ResponsiveContainer width={"99%"} height={400}>
                          <BarChart
                            data={ageRange}
                            margin={{
                              top: 5,
                              right: 30,
                              left: 20,
                              bottom: 5,
                            }}
                            barGap={10}
                          >
                            <CartesianGrid
                              vertical={false}
                              strokeDasharray="0"
                            />
                            <XAxis
                              dataKey="ranges"
                              tickLine={false}
                              axisLine={false}
                            />
                            <YAxis
                              allowDecimals={false}
                              tickLine={false}
                              axisLine={false}
                              domain={["auto", "dataMax"]}
                            />
                            <Tooltip />
                            <Bar dataKey="aprobado" fill="#96CCBF" />
                            <Bar dataKey="no aprobado" fill="#EDAB94" />
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </div>
                  </Row>
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AgeAndGenderList;
