import React, { useEffect, useState } from "react";
import { Row, Col, Popover, Spin, message } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { dashboardService } from "../../../../services/dashboard";
import { useTranslation } from "react-i18next";

const dateFormat = "YYYY-MM-DD";

const GeneralInformation = ({ form, mainLoading }) => {
  const [generalInformation, setGeneralInformation] = useState();
    useState();
  const [loading, setLoading] = useState();
  const { t } = useTranslation();

  const getFilters = () => {
    const params = {
      startDate: form.getFieldValue("dates")[0].format(dateFormat),
      endDate: form.getFieldValue("dates")[1].format(dateFormat),
      companyId: form.getFieldValue("company"),
    };
    return Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v !== undefined)
    );
  };

  const getGeneralInformation = async (filters) => {
    setLoading(true);
    try {
      const response = await dashboardService.generalInformation(filters);
      setGeneralInformation(response);
    } catch (error) {
      console.error(error);
      message.error({
        content: `Ha ocurrido un error al obtener la información. ${error}`,
        style: {
          marginTop: '40vh'
        }
      })
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const filters = getFilters();
    getGeneralInformation({ ...filters });
  }, [
    form.getFieldValue("company"),
    form.getFieldValue("dates")[0].format(dateFormat),
    form.getFieldValue("dates")[1].format(dateFormat),
  ]);

  const calcAssistance = (reserved, attended) => {
    if (attended > reserved) {
      return 100;
    }

    let result = (parseInt(attended) * 100) / parseInt(reserved);
    if (Number.isNaN(result)) {
      return 0;
    }

    return Math.round(result);
  };

  const popOverContent = (
    <div style={{ width: 200 }}>
      <p>
        <strong>Monto atendido</strong>
      </p>
      <p>
        El monto atendido se conforma por lorem ipsum dolor sit amet,
        consectetur adipiscing elit, sed do eiusmod tempor incididun
      </p>
    </div>
  );


  const calcReservedServices = (generalInformation) => {
    let result = parseInt(
      generalInformation.reservedPrestationsCount[0]["sum"]
    );
    if (Number.isNaN(result)) {
      return 0;
    }

    return result;
  };

  return (
    <Spin spinning={loading || mainLoading}>
      {generalInformation && (
        <Row justify="start">
          <Col xl={24} lg={24} xs={24}>
            <Row justify="start" gutter={[15, 10]}>
              <Col lg={8} xs={12} className="detail-square-wrapper">
                <Row
                  justify="center"
                  className="detail-square pt-lg-20 pt-md-10 pt-xs-5 pb-lg-20 pb-md-10 pb-xs-5"
                >
                  <Col
                    lg={22}
                    className="info-circle-wrapper"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Popover content={popOverContent}>
                      <InfoCircleOutlined
                        style={{
                          fontSize: 16,
                          color: "#5B356D",
                          marginRight: 3,
                        }}
                      />
                    </Popover>
                  </Col>
                  <Col lg={20} xs={24}>
                    <p className="text-center general-text pt-10 mb-lg-15 mb-xs-10">
                      {generalInformation.reservedCount[0]["count"]}
                    </p>
                  </Col>
                  <Col lg={20} xs={24}>
                    <h3 className="text-center">{t('dashboard.generalInformation.lastReservationsTitle')}</h3>
                  </Col>
                </Row>
              </Col>
              <Col lg={8} xs={12} className="detail-square-wrapper">
                <Row
                  justify="center"
                  className="detail-square pt-lg-20 pt-md-10 pt-xs-5 pb-lg-20 pb-md-10 pb-xs-5"
                >
                  <Col
                    lg={22}
                    className="info-circle-wrapper"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <InfoCircleOutlined
                      style={{ fontSize: 16, color: "#5B356D", marginRight: 3 }}
                    />
                  </Col>
                  <Col lg={20} xs={24}>
                    <p className="text-center general-text pt-10 mb-lg-15 mb-xs-10">
                      {generalInformation.attendedPatientsCount[0]["count"]}
                    </p>
                  </Col>
                  <Col lg={20} xs={24}>
                    <h3 className="text-center">{t('dashboard.generalInformation.lastPatientsTitle')}</h3>
                  </Col>
                </Row>
              </Col>
              <Col lg={8} xs={12} className="detail-square-wrapper">
                <Row
                  justify="center"
                  className="detail-square pt-lg-20 pt-md-10 pt-xs-5 pb-lg-20 pb-md-10 pb-xs-5"
                >
                  <Col
                    lg={22}
                    className="info-circle-wrapper"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <InfoCircleOutlined
                      style={{ fontSize: 16, color: "#5B356D", marginRight: 3 }}
                    />
                  </Col>
                  <Col lg={20} xs={24}>
                    <p className="text-center general-text pt-10 mb-lg-15 mb-xs-10">
                      {calcAssistance(
                        generalInformation.reservedPrestationsCount[0]["sum"],
                        generalInformation.attendedPrestationsCount[0]["count"]
                      )}
                      %
                    </p>
                  </Col>
                  <Col lg={20} xs={24}>
                    <h3 className="text-center">{t('dashboard.generalInformation.attendanceTitle')}</h3>
                  </Col>
                </Row>
              </Col>
              {/* <Col lg={6} xs={12} className="detail-square-wrapper">
                                <Row justify="center" className="detail-square pt-lg-15 pt-xs-5 pb-lg-15 pb-xs-5 mt-lg-0 mt-md-10 mt-xs-10">
                                    <Col lg={22} className="info-circle-wrapper" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <InfoCircleOutlined style={{ fontSize: 16, color: '#5B356D', marginRight: 3 }} />
                                    </Col>
                                    <Col lg={20} xs={24}>
                                        <p className="text-center general-text pt-10 mb-lg-15 mb-xs-10">$ {formatToLocaleString(calcAmountAttended(generalInformation))}</p>
                                    </Col>
                                    <Col lg={20} xs={24}>
                                        <h3 className="text-center">Monto atendido</h3>
                                    </Col>
                                </Row>
                            </Col> */}
              <Col lg={8} xs={12} className="detail-square-wrapper">
                <Row
                  justify="center"
                  className="detail-square pt-lg-20 pt-md-10 pt-xs-5 pb-lg-20 pb-md-10 pb-xs-5"
                >
                  <Col
                    lg={22}
                    className="info-circle-wrapper"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <InfoCircleOutlined
                      style={{ fontSize: 16, color: "#5B356D", marginRight: 3 }}
                    />
                  </Col>
                  <Col lg={20} xs={24}>
                    <p className="text-center general-text pt-10 mb-lg-15 mb-xs-10">
                      {calcReservedServices(generalInformation)}
                    </p>
                  </Col>
                  <Col lg={20} xs={24}>
                    <h3 className="text-center">{t('dashboard.generalInformation.reservedServicesTitle')}</h3>
                  </Col>
                </Row>
              </Col>
              <Col lg={8} xs={12} className="detail-square-wrapper">
                <Row
                  justify="center"
                  className="detail-square pt-lg-20 pt-md-10 pt-xs-5 pb-lg-20 pb-md-10 pb-xs-5"
                >
                  <Col
                    lg={22}
                    className="info-circle-wrapper"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <InfoCircleOutlined
                      style={{ fontSize: 16, color: "#5B356D", marginRight: 3 }}
                    />
                  </Col>
                  <Col lg={20} xs={24}>
                    <p className="text-center general-text pt-10 mb-lg-15 mb-xs-10">
                      {generalInformation.attendedPrestationsCount[0]["count"]}
                    </p>
                  </Col>
                  <Col lg={20} xs={24}>
                    <h3 className="text-center">{t('dashboard.generalInformation.completedServicesTitle')}</h3>
                  </Col>
                </Row>
              </Col>
              <Col lg={8} xs={12} className="detail-square-wrapper">
                <Row
                  justify="center"
                  className="detail-square pt-lg-20 pt-md-10 pt-xs-5 pb-lg-20 pb-md-10 pb-xs-5"
                >
                  <Col
                    lg={22}
                    className="info-circle-wrapper"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <InfoCircleOutlined
                      style={{ fontSize: 16, color: "#5B356D", marginRight: 3 }}
                    />
                  </Col>
                  <Col lg={20} xs={24}>
                    <p className="text-center general-text pt-10 mb-lg-15 mb-xs-10">
                      {generalInformation.approvedPatientsCount[0]["count"]}
                    </p>
                  </Col>
                  <Col lg={20} xs={24}>
                    <h3 className="text-center">{t('dashboard.generalInformation.approvedServicesTitle')}</h3>
                  </Col>
                </Row>
              </Col>
              {/* <Col lg={6} xs={12} className="detail-square-wrapper">
                                <Row justify="center" className="detail-square pt-lg-15 pt-xs-5 pb-lg-15 pb-xs-5 mt-lg-0 mt-md-10 mt-xs-10">
                                    <Col lg={22} className="info-circle-wrapper" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <InfoCircleOutlined style={{ fontSize: 16, color: '#5B356D', marginRight: 3 }} />
                                    </Col>
                                    <Col lg={20} xs={24}>
                                        <p className="text-center general-text pt-10 mb-lg-15 mb-xs-10">{generalInformation.disaprovedPatientsCount[0]['count']}%</p>
                                    </Col>
                                    <Col lg={21} xs={24}>
                                        <h3 className="text-center">Prestaciones no aprobadas</h3>
                                    </Col>
                                </Row>
                            </Col> */}
            </Row>
          </Col>
        </Row>
      )}
      {/* <Col lg={24} xs={24} className="account-wrapper mt-lg-15 mt-md-10 mt-xs-10 pt-lg-40 pt-md-20 pt-xs-20 pb-30">
                {
                    businessAccountInformation &&
                    <React.Fragment>
                        <Row justify="center" align="middle">
                            <Col lg={8} xs={22}>
                                <Row justify="start" align="top" className="mb-lg-40 mb-xs-20">
                                    <Col lg={24} xs={24}>
                                        <Row justify="start">
                                            <Col>
                                                <h3>Empresa:</h3>
                                            </Col>
                                            <Col>
                                                <p className="ml-5 account-text">{businessAccountInformation.business_name}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={24} xs={24}>
                                        <Row justify="start">
                                            <Col>
                                                <h3>RUT:</h3>
                                            </Col>
                                            <Col>
                                                <p className="ml-5 account-text">{businessAccountInformation.rut}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} xs={22}>
                                <Row justify="center" className="account-balance pt-lg-20 pt-xs-15">
                                    <Col lg={20} xs={24}>
                                        <h3 className="text-center">Saldo de cuenta</h3>
                                    </Col>
                                    <Col lg={20} xs={24}>
                                        <p className="text-center account-balance-amount mb-lg-20 mb-xs-15">$ {formatToLocaleString(businessAccountInformation.account_balance)}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={8} xs={21}>
                                <Row justify="center" align="middle">
                                    <Col lg={11} xs={24} className="see-account-button-wrapper mt-lg-0 mt-md-30 mt-xs-30 ml-lg-30 ml-xs-0">
                                        <Button className="see-account-button pr-30 pl-30" size="large" block>Ver cuenta</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </React.Fragment>
                }
            </Col> */}
    </Spin>
  );
};

export default GeneralInformation;
