import React from 'react';
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { getNumberFormatted } from '../../../helpers/handlers';

const Transfer = ({ reservation, setTransferPage }) => {
  const { t } = useTranslation();
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;

  return (
    <Row justify="center" className='transfer-container'>
      <Col lg={18} xs={22}>
        <Row justify="center">
          <Col>
            <h1 className='mt-lg-0 mt-md-30 mt-sm-30 mt-xs-30'>{t('scheduleTime.transfer.paymentTitle')}</h1>
          </Col>
        </Row>
        <Row justify='space-between' className='mt-20'>
          <Col
            lg={{ order: 1, span: 15 }} xs={{ order: 2, span: 24 }}
            className='gray-box pl-lg-40 pl-md-20 pl-sm-20 pl-xs-20 pr-lg-40 pr-md-20 pr-sm-20 pr-xs-20 pt-lg-40 pt-md-20 pt-sm-20 pt-xs-20 pb-20'
          >
            <h2 className='mb-20'>{t('scheduleTime.transfer.paymentDataTitle')}</h2>
            <p>{t('scheduleTime.transfer.companyText')}</p>
            {
              countryCode === 'CL' &&
              <p dangerouslySetInnerHTML={{ __html: t('scheduleTime.transfer.companyRut')}} />
            }
            <p dangerouslySetInnerHTML={{ __html: t('scheduleTime.transfer.accountNumberText')}} />
            <p dangerouslySetInnerHTML={{ __html: t('scheduleTime.transfer.bankText')}} />
            <p dangerouslySetInnerHTML={{ __html: `Email: ${reservation.branch_office_payment_email || t('scheduleTime.transfer.emailText')}`}} />
            <p dangerouslySetInnerHTML={{ __html: t('scheduleTime.transfer.subject')}} />
          </Col>
          <Col
            lg={{ order: 2, span: 8 }} xs={{ order: 1, span: 24 }}
            className='gray-box pl-lg-40 pl-md-20 pl-sm-20 pl-xs-20 pr-lg-40 pr-md-20 pr-sm-20 pr-xs-20 pt-lg-40 pt-md-20 pt-sm-20 pt-xs-20 pb-20 mb-lg-0 mb-md-30 mb-sm-30 mb-xs-30'
          >
            <h2 className='mb-20 gray'>{t('scheduleTime.transfer.payAmountTitle')}</h2>
            <p className='total-amount mt-50'>{reservation.total_amount ?
              getNumberFormatted({
                countryCode: reservation.locate_code,
                currencyCode: reservation.currency_code,
                amount: reservation.total_amount
              }) : '-'}</p>
          </Col>
          {
            countryCode === 'PE' &&
              <Col
                lg={{ order: 3, span: 15 }} xs={{ order: 3, span: 24 }}
                className='gray-box pl-lg-40 pl-md-20 pl-sm-20 pl-xs-20 pr-lg-40 pr-md-20 pr-sm-20 pr-xs-20 pt-lg-40 pt-md-20 pt-sm-20 pt-xs-20 pb-20 mt-30'
              >
                <h2 className='mb-20'>{t('scheduleTime.transfer.paymentDataTitleUsd')}</h2>
                <p>{t('scheduleTime.transfer.companyText')}</p>
                <p dangerouslySetInnerHTML={{__html: t('scheduleTime.transfer.accountNumberTextUsd')}} />
                <p dangerouslySetInnerHTML={{ __html: t('scheduleTime.transfer.bankTextUsd') }} />
                <p dangerouslySetInnerHTML={{ __html: `Email: ${reservation.branch_office_payment_email || t('scheduleTime.transfer.emailText')}`}} />
                <p dangerouslySetInnerHTML={{ __html: t('scheduleTime.transfer.subject')}} />
              </Col>
          }
        </Row>
        <Row justify='end' className='mt-lg-20 mt-md-50 mt-sm-50 mt-xs-50 mb-40'>
          <Col lg={3} xs={24}>
            <Button
              className='cmt-button'
              type='primary'
              size='large'
              onClick={() => setTransferPage(false)}
            >
              Finalizar
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
};

export default Transfer;
