import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Modal,
  Tag,
  message,
} from 'antd'
import { PhoneInput } from 'cmt-ui-kit';

const BillingDataModal = ({
  billingData,
  loading,
  saveBillingData,
  countryCode,
  setOpen,
  open,
}) => {
  const [form] = Form.useForm();
  const [email, setEmail] = useState('');
  const [emailList, setEmailList] = useState([]);

  useEffect(() => {
    if (billingData && open) {
      form.setFieldsValue({
        name: billingData.name,
        phone: billingData.phone,
        email: '',
      });
      setEmailList((billingData.email && billingData.email.split(',')) || []);
    }
  }, [open, billingData])

  const onChangeEmail = (event) => {
    setEmail(event.target.value)
  }

  const onBlurEmail = () => {
    const _emailList = emailList;
    if (email) {
      _emailList.push(email);
      setEmailList(_emailList);
      form.setFieldsValue({
        email: '',
      });
      setEmail('');
    }
  }

  const onEraseEmail = (value) => {
    const _emailList = emailList && emailList.filter(email => email !== value);
    setEmailList(_emailList);
  }

  const onFinish = () => {
    if (emailList && emailList.length > 0) {
      saveBillingData({
        name: form.getFieldValue('name'),
        phone: form.getFieldValue('phone'),
        email: emailList.map(email => email && email).join(','),
      });
      setOpen(false);
    } else {
      message.error({
        content: "Debe ingresar al menos un correo electrónico",
        style: {
          marginTop: '35vh'
        }
      })
    }
  }

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={false}
      centered
      className="billing-modal"
    >
      <Form
        form={form}
        layout="vertical"
        size='large'
        className='cmt-form ml-80 mr-80'
      >
        <Row>
          <Col lg={24} xs={24} className="ml-10 mt-40 mb-20">
            <h2>Los datos de facturación serán enviados al siguiente contacto:</h2>
          </Col>
        </Row>
        <Row className='gray-wrapper pb-20 pt-20' gutter={[40, 0]}>
          <Col lg={12} xs={24}>
            <Form.Item
              name="name"
              label="Nombre y Apellido"
              rules={[{
                required: true,
                message: "Nombre es requerido"
              }]}
            >
              <Input className='cmt-input' placeholder='Ingrese nombre y apellido' />
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <Form.Item
              name="email"
              label="Correo electrónico"
            >
              <Input
                className='cmt-input'
                placeholder='Ingrese correo electrónico'
                onChange={onChangeEmail}
                onBlur={onBlurEmail}
                onPressEnter={onBlurEmail}
                value={email}
              />
              {
                emailList && emailList.map((email, index) =>
                  <Tag
                    key={index}
                    closable
                    className="cmt-tag mt-10"
                    onClose={(event) => {
                      event.preventDefault();
                      onEraseEmail(email);
                    }}
                  >
                    {email}
                  </Tag>
                )
              }
            </Form.Item>
          </Col>
          <Col lg={12} xs={24}>
            <PhoneInput.ReactPhone
              countryCode={countryCode}
              form={form}
              labelText='Teléfono'
            />
          </Col>
        </Row>
        <Row justify='center' className='mt-50 mb-50'>
          <Col lg={8} xs={24}>
            <Button
              loading={loading.form}
              className='cmt-button primary'
              type='primary'
              onClick={onFinish}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default BillingDataModal