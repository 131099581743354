import config from "../config";

const getBills = async (params) => {
  const url = new URL(`${config.client_api}/invoices`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
  const dataResponse = response.json()
  return dataResponse
}

const getCompanies = async (params) => {
  const url = new URL(`${config.client_api}/invoices/companies`)
  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  }
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
  const dataResponse = response.json()
  return dataResponse
}

const billingDetail = async (params) => {
  const url = new URL(`${config.client_api}/invoices/details`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
  const dataResponse = response.json()
  return dataResponse
}

const checkPaid = async (params) => {
  const url = new URL(`${config.client_api}/invoices/checkPaid`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  })
  const dataResponse = response.json()
  return dataResponse
}

const getUnpaidTotalByCompany = async (params) => {
  const url = new URL(`${config.client_api}/invoices/getUnpaidTotalByCompany`)
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(params)
  })
  const dataResponse = response.json()
  return dataResponse
}

export const billingService = {
  getBills,
  billingDetail,
  getCompanies,
  getUnpaidTotalByCompany,
  checkPaid
}
