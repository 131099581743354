import { Row, Col, Tabs, Spin, Badge } from 'antd';
import TableExpirations from './TableExpirations';

const { TabPane } = Tabs;

const Expirations = ({
  loading,
  data,
  filters,
  expirationPage,
  setExpirationPage,
  fetchExpirations,
  }) => {

  const handleTabPaneName = (keyName) => {
    switch (keyName) {
      case 'critical':
        return 'Críticos';
      case 'warning':
        return 'Advertencias';
      case 'normal':
        return 'Vigentes';
      case 'expired':
        return 'Vencidos';
      case 'observed':
        return 'Observados';
      default:
        return '';
    };
  };


  return (
    <Row gutter={[0, 30]} className='expirations-wrapper'>
      <Col lg={24} xs={24} className='mt-70'>
        <h2>Próximos Vencimientos y Observados</h2>
      </Col>
      <Col lg={24} xs={24}>
        <Spin className='cmt-spin' spinning={loading.expirationsTable}>
          <Tabs className='cmt-tabs' defaultActiveKey={1}>
          {
            Object.keys(data).map((key, index) => {
              if (key) {
                return (
                  <TabPane
                    key={index + 1}
                    tab={
                      <Badge
                        count={data[key]?.pagination?.total || 0}
                        offset={[15, 0]}
                        showZero
                      >
                        <p className='mb-0'>{handleTabPaneName(key)}</p>
                      </Badge>
                    }
                  >
                    <TableExpirations
                      filters={filters}
                      tabName={key}
                      tableData={data[key].data}
                      pagination={data[key].pagination}
                      expirationPage={expirationPage}
                      setExpirationPage={setExpirationPage}
                      fetchExpirations={fetchExpirations}
                      handleTabPaneName={handleTabPaneName}
                    />
                  </TabPane>
                )
              } else {
                return null
              }
            })
          }
          </Tabs>
        </Spin>
      </Col>
    </Row>
  );
};

export default Expirations;