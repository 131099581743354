import React, { useState } from 'react'
import { Row, Col, Button, Form, message, Input } from 'antd'
import { reservationNotificationService } from '../services/reservationNotification'
import UserIconPurple from '../images/UserIconPurple.svg'
import CloseIconGray from '../images/CloseIconGray.svg'
import moment from 'moment'

const { format, validate } = require('rut.js')

const GenerateReservationNotification = () => {
   const [loading, setLoading] = useState(false)
   const [reservation, setReservation] = useState()
   const [addedPatients, setAddedPatients] = useState([])
   const [form] = Form.useForm()

   const onFinish = async values => {
      try {
         setLoading(true)
         const response = await reservationNotificationService.getReservation(values)
         if (response.id) {
            setReservation(response)
            setLoading(false)
         } else {
            message.error({
               content: 'No existe reserva asociada a ese n°.',
               style: {
                  marginTop: '40vh'
               }
            })
            setLoading(false)
         }
      } catch (err) {
         console.error(err)
         message.error('Error interno', err)
         setLoading(false)
      }
   }

   const onChangeRutInput = event => {
      const formatRut = format(event.target.value)
      form.setFieldsValue({ rut: formatRut })
   }

   const formatDate = (date) => {
      let format = moment(date, 'YYYY-MM-DD').locale("es").format('dddd, DD [de] MMMM [de] YYYY')
      const splitDate = format.split(",")
      const lowerCaseDayName = splitDate[0].toLowerCase()
      const capitalizeDayName = lowerCaseDayName.replace(/\b(\w)/g, s => s.toUpperCase())
      const dateFormat = splitDate[1]
      return `${capitalizeDayName}, ${dateFormat}`
   }

   const onFinishAddPatient = values => {
      if (addedPatients.length > 0) {
         const checkIfPatientWasAdded = addedPatients.filter(item => item.rut === values.rut)
         if (checkIfPatientWasAdded.length > 0) {
            message.error({
               content: 'Ese trabajador ya ha sido agregado.',
               style: {
                  marginTop: '40vh'
               }
            })
         } else {
            setAddedPatients(addedPatients => [...addedPatients, { full_name: values.nameAndLastName, rut: values.rut }])
         }
      } else {
         setAddedPatients(addedPatients => [...addedPatients, { full_name: values.nameAndLastName, rut: values.rut }])
      }
      form.resetFields()
   }

   const removePatient = rut => {
      const filterPatients = addedPatients.filter(item => item.rut !== rut)
      setAddedPatients([...filterPatients])
   }

   const downloadZip = async () => {
      try {
         setLoading(true)
         const reservationNumber = reservation.number
         const stringify = JSON.stringify(addedPatients)
         await reservationNotificationService.downloadNotificationsZip(reservationNumber, { patients: stringify })
         setLoading(false)
      } catch (err) {
         setLoading(false)
         message.error({
            content: 'Error interno.',
            style: {
               marginTop: '40vh'
            }
         })
      }
   }

   return (
      <Row justify="center">
         <Col lg={21} className="mt-40 pb-100 reservation-notification-container">
            <Row justify="center">
               <Col lg={24}>
                  <h1 className="text-center">Orden médica para salvoconducto</h1>
               </Col>
            </Row>
            <Row justify="center">
               {
                  !reservation ?
                     <Col lg={24} className="reservation-form-wrapper">
                        <Row justify="center">
                           <Col lg={24}>
                              <h2 className="text-center">Genera la notificación de orden médica para salvoconducto</h2>
                           </Col>
                           <Col lg={10} className="mt-30">
                              <Form layout="vertical" onFinish={onFinish} size="large" form={form} style={{ width: '100%' }}>
                                 <Row justify="center">
                                    <Col lg={16} xs={24}>
                                       <Form.Item
                                          label="Ingresa el número de reserva"
                                          name="code"
                                          rules={[
                                             {
                                                required: true,
                                                message: 'El n° de reserva es requerido.'
                                             }
                                          ]}
                                       >
                                          <Input placeholder="Ej. R123456" />
                                       </Form.Item>
                                    </Col>
                                    <Col lg={10} xs={24} className="mt-30">
                                       <Button loading={loading} htmlType="submit" block>Buscar reserva</Button>
                                    </Col>
                                 </Row>
                              </Form>
                           </Col>
                        </Row>
                     </Col>
                     :
                     <Col lg={20} className="reservation-wrapper mt-10">
                        <Row justify="center" className="reservation-wrapper__data pt-30 pb-30 p-xs-40">
                           <Col lg={20}>
                              <Row>
                                 <Col>
                                    <h3>Reserva n° {reservation.number}</h3>
                                 </Col>
                              </Row>
                              <Row>
                                 <Col>
                                    <Row>
                                       <Col>
                                          <h4 className="mr-10">Fecha:</h4>
                                       </Col>
                                       <Col>
                                          <p>{formatDate(reservation.dates)}</p>
                                       </Col>
                                    </Row>
                                 </Col>
                              </Row>
                              <Row justify="space-between">
                                 <Col lg={15}>
                                    <Row>
                                       <Col>
                                          <h4 className="mr-10">Empresa:</h4>
                                       </Col>
                                       <Col>
                                          <p>{reservation.company.business_name}</p>
                                       </Col>
                                    </Row>
                                 </Col>
                                 <Col lg={9}>
                                    <Row>
                                       <Col>
                                          <h4 className="mr-10">Solicitante:</h4>
                                       </Col>
                                       <Col>
                                          <p>{reservation.user.user_profile.first_name} {reservation.user.user_profile.last_name}</p>
                                       </Col>
                                    </Row>
                                 </Col>
                              </Row>
                           </Col>
                        </Row>
                        <Row justify="center" className="reservation-wrapper__patient mt-40">
                           <Col lg={24}>
                              <h4 className="title">Agrega los trabajadores que necesitarán el documento:</h4>
                           </Col>
                           <Col lg={24} className="reservation-wrapper__patient-form pt-20 pb-20 pl-30 pr-30">
                              <Form form={form} onFinish={onFinishAddPatient} size="large" style={{ width: '100%' }} layout="vertical">
                                 <Row justify="space-between">
                                    <Col lg={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                       <img src={UserIconPurple} width="24" height="24" alt="Icono de usuario" />
                                    </Col>
                                    <Col lg={8} xs={24} className="mt-xs-40">
                                       <Form.Item
                                          label="Nombre y apellido"
                                          name="nameAndLastName"
                                          rules={[
                                             {
                                                required: true,
                                                message: 'Nombre y apellido son requeridos.'
                                             }
                                          ]}
                                       >
                                          <Input placeholder="Nombre y apellido completo" />
                                       </Form.Item>
                                    </Col>
                                    <Col lg={8} xs={24}>
                                       <Form.Item
                                          label="RUT"
                                          name="rut"
                                          onChange={onChangeRutInput}
                                          rules={[
                                             {
                                                required: true,
                                                message: 'N° de RUT es requerido.'
                                             },
                                             {
                                                validator: (_, value) => {
                                                   if (validate(value)) {
                                                      return Promise.resolve()
                                                   } else {
                                                      return Promise.reject(new Error('RUT inválido.'))
                                                   }
                                                }
                                             }
                                          ]}
                                       >
                                          <Input placeholder="Número de RUT" />
                                       </Form.Item>
                                    </Col>
                                    <Col lg={6} className="mt-5" style={{ display: 'flex', alignItems: 'center' }}>
                                       <Button htmlType="submit" block>Agregar trabajador</Button>
                                    </Col>
                                 </Row>
                              </Form>
                           </Col>
                        </Row>
                        {
                           addedPatients.length > 0 &&
                           <React.Fragment>
                              <Row justify="center" className="reservation-wrapper__added-patients mt-20 mb-40 pt-15 pb-20 pl-20 pr-20">
                                 <Col lg={24}>
                                    <h4 className="title mb-0">Trabajadores agregados:</h4>
                                 </Col>
                                 {
                                    addedPatients.map((patient, index) => {
                                       return (
                                          <Col lg={24} key={index} className="patient-gray-row mt-15">
                                             <Row justify="space-between" className="pt-10 pb-10 pl-10 pr-10">
                                                <Col lg={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                   <img src={UserIconPurple} width="17" height="17" alt="Icono de usuario" />
                                                </Col>
                                                <Col lg={10}>
                                                   <p className="mb-0">{patient.full_name}</p>
                                                </Col>
                                                <Col lg={10}>
                                                   <p className="mb-0">RUT: {patient.rut}</p>
                                                </Col>
                                                <Col lg={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="close-icon-wrapper">
                                                   <img onClick={() => removePatient(patient.rut)} src={CloseIconGray} width="18" height="18" alt="Icono de cierre" />
                                                </Col>
                                             </Row>
                                          </Col>
                                       )
                                    })
                                 }
                              </Row>
                              <Row justify="center" className="mt-30 mb-60">
                                 <Col lg={6}>
                                    <Button onClick={downloadZip} loading={loading} size="large" type="primary" block>Generar notificaciones</Button>
                                 </Col>
                              </Row>
                           </React.Fragment>
                        }
                     </Col>
               }
            </Row>
         </Col>
      </Row>
   )
}

export default GenerateReservationNotification