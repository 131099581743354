import React, { useState } from 'react';
import { Row, Col, Table, Button } from 'antd'
import CostCenterDetailModal from './CostCenterDetailModal';
import NewCostCenterModal from './NewCostCenterModal';
import ActiveConfirm from './ActiveConfirm';

const AssociatedCostCenters = ({
  isSuperUser,
  costCenters,
  saveCostCenter,
  loading,
  countryCode,
  saveCostCenterActive,
  t,
}) => {
  const [currentCostCenter, setCurrentCostCenter] = useState()
  const [modalVisible, setModalVisible] = useState(false)
  const [newCostCenterModalVisible, setNewCostCenterModalVisible] = useState(false)

  const showModal = (costCenterData) => {
    setCurrentCostCenter(costCenterData);
    setModalVisible(true);
  }

  const showNewCostCenterModalVisible = () => {
    setNewCostCenterModalVisible(true);
  }

  const columns = [
    {
      key: 0,
      title: 'Nombre',
      dataIndex: 'name',
      width: '45%',
      render: (name) => {
        return <p className='mb-0'>{name}</p>
      }
    },
    {
      key: 1,
      title: 'Código',
      dataIndex: 'code',
      width: '20%',
      render: (code) => {
        return <p className='mb-0'>{code}</p>
      }
    },
    {
      key: 2,
      title: 'Activo',
      dataIndex: 'active',
      align: 'center',
      width: '15%',
      render: (active, costCenter) => {
        return (
          <ActiveConfirm
            active={active}
            object={costCenter}
            saveObject={saveCostCenterActive}
            loading={loading}
            disabled={!isSuperUser}
            context='costCenter'
            t={t}
          />
        )
      }
    },
    {
      key: 3,
      title: '',
      dataIndex: 'id',
      align: 'center',
      width: '10%',
      render: (id, costCenter) => {
        return (
          <Button
            onClick={() => showModal(costCenter)}
            className='cmt-button secondary outline'
            type='primary'
            size='small'
          >
            Ver más
          </Button>
        )
      }
    }
  ]

  return (
    <Row justify='center' className='company-profile__cost-centers pt-60'>
      <Col lg={24} xs={24}>
        <h2 className='mb-215'>Centros de costo asociados</h2>
      </Col>
      <Col lg={24} xs={24}>
        <Table
          rowKey="id"
          className='cmt-table company-profile-table'
          columns={columns}
          dataSource={costCenters && costCenters}
          pagination={false}
          locale={{ emptyText: "No hay centros de costo asociados" }}
        />
      </Col>
      {
        isSuperUser &&
        <Col lg={24} xs={24} className="mt-30">
          <Row justify='end'>
            <Col xl={5} lg={7} xs={24}>
              <Button
                onClick={showNewCostCenterModalVisible}
                className='cmt-button secondary'
                type='primary'
              >
                <i className='icon-system-plus mr-10' />
                Centro de costo
              </Button>
            </Col>
          </Row>
        </Col>
      }

      <CostCenterDetailModal
        isSuperUser={isSuperUser}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        currentCostCenter={currentCostCenter}
        showNewCostCenterModalVisible={showNewCostCenterModalVisible}
        costCenters={costCenters}
        saveCostCenter={saveCostCenter}
        loading={loading}
      />

      <NewCostCenterModal
        newCostCenterModalVisible={newCostCenterModalVisible}
        setNewCostCenterModalVisible={setNewCostCenterModalVisible}
        saveCostCenter={saveCostCenter}
        loading={loading}
        countryCode={countryCode}
      />

    </Row>
  )
}

export default AssociatedCostCenters