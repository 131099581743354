import React, { useState, useEffect } from 'react';
import { useParams } from "@reach/router"
import { Row, Col, Form, Spin } from 'antd';
import UsersForm from './components/UsersForm';
import SetPasswordModal from './components/SetPasswordModal';
import UseUser from './hooks/useUser';

const CreateNewUser = () => {
  const { invitationId } = useParams();
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const useUser = UseUser({ token: invitationId });

  const showModal = () => {
    setModalVisible(true)
  }

  useEffect(() => {
    if (useUser.formUser && useUser.formUser.extra_data && !useUser.formUser.password_confirmation) {
      form.setFieldsValue({
        email: useUser.formUser.email,
        companies: useUser.formUser.business_name,
        canReserve: !!useUser.formUser.extra_data.can_reserve,
        canViewStatistics: !!useUser.formUser.extra_data.can_view_statistics,
        canDownloadResults: !!useUser.formUser.extra_data.can_download_results,
        canViewInvoices: !!useUser.formUser.extra_data.can_view_invoices,
        medicalAdministation: !!useUser.formUser.extra_data.medical_administration,
        documentType: useUser.formUser.document_type
          || (useUser.countryCode && useUser.countryCode.toUpperCase() === 'CL' ? 'rut' : 'dni'),
        documentNumber: useUser.formUser.document_number,
        phone: useUser.formUser.phone,
        firstName: useUser.formUser.first_name,
        lastName: useUser.formUser.last_name,
        active: true,
      });
    }
  }, [useUser.formUser]);

  return (
    <Row justify='center' className='create-new-user pb-80'>
      {
        useUser.loading.getUserInvite
          ? <Spin className="cmt-spin" />
          :
          <Col lg={24} xs={24}>
            <UsersForm
              form={form}
              loading={useUser.loading}
              saveUser={useUser.createUser}
              showModalPassword={showModal}
              containerType="newUserPage"
              userEmail={useUser.user.email}
              isSuperUser={false}
              countryCode={useUser.countryCode}
            />

            <SetPasswordModal
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              savePassword={useUser.saveUserPassword}
            />

          </Col>
      }
    </Row>
  )
}

export default CreateNewUser