import config from "../config";

const getReservation = async (params) => {
  const url = new URL(`${config.admin_url}/api/reservations/by_code`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
  })
  const dataResponse = response.json()
  return dataResponse
}

const downloadNotificationsZip = async (code, params) => {
  const url = new URL(`${config.admin_url}/api/reservations/${code}/download_notifications.zip`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  window.location.href = url
}

export const reservationNotificationService = {
  getReservation,
  downloadNotificationsZip
}