import { Button, Col, Result, Row } from 'antd';
import { Link } from "gatsby"
import React from 'react';

const ErrorPage = ({ code }) => {

  const message = {
    403: 'Lo sentimos, no está autorizado a acceder a esta página.'
  };

  return (
    <Result
      status={code}
      title={code}
      subTitle={message[code]}
      extra={
        <Row justify='center'>
          <Col xl={5} xs={24}>
            <Link to="/" replace>
              <Button className="cmt-button primary" type="primary">
                Ir a la página de inicio
              </Button>
            </Link>
          </Col>
        </Row>
      }
    />
  )
};

export default ErrorPage;
