import React, { useState, useEffect, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Drawer,
  Form,
  Input,
  DatePicker,
  Select,
  Modal,
  message,
  Empty,
} from "antd";
import Items from "../billing/components/Items";
import { billingService } from "../../../services/billing";
import locale from "antd/es/date-picker/locale/es_ES";
import Joyride from "react-joyride";
import { isMobile } from "react-device-detect";
import { useMount } from "react-use";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const Billing = () => {
  const [visibleDrawer, setVisibleDrawer] = useState(false);
  const [number, setNumber] = useState();
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [startClientsTutorial, setStartClientsTutorial] = useState(false);
  const { t } = useTranslation();

  const showDrawer = () => {
    setVisibleDrawer(true);
  };
  const closeDrawer = () => {
    setVisibleDrawer(false);
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setNumber(values.search);
    if (values.dates) {
      setStartDate(values.dates[0].format('YYYY-MM-DD hh:mm:ss'));
      setEndDate(values.dates[1].format('YYYY-MM-DD hh:mm:ss'));
    } else {
      setStartDate(null);
      setEndDate(null);
    }
    closeDrawer();
  };

  const fetchCompanies = async () => {
    let _response = [];
    let _responseFirstElementId;
    try {
      const response = await billingService.getCompanies({ 'permission': 'can_view_invoices' });
      if (response && response.length > 0) {
        _response = response;
        _responseFirstElementId = response[0].id;
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: `Error al traer los datos de facturación`,
        style: {
          marginTop: '40vh',
        },
      });
    } finally {
      setCompanies(_response);
      setCompany(_responseFirstElementId);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useMount(() => {
    const checkTutorialState = localStorage.getItem("billingTutorialDisabled");

    if (checkTutorialState === "true") {
      setIsModalVisible(false);
    } else {
      if (!isMobile) {
        setIsModalVisible(true);
      }
    }
  });

  let steps = [
    {
      target: ".last-invoices",
      placement: "bottom-start",
      disableBeacon: true,
      hideCloseButton: true,
      title: <p className="joyride-title">{t('joyRide.billing.stepOne.title')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.billing.stepOne.content')}
        </p>
      ),
    },
    {
      target: ".first-button",
      disableBeacon: true,
      hideCloseButton: true,
      placement: "left",
      title: <p className="joyride-title">{t('joyRide.billing.stepTwo.title')}</p>,
      content: (
        <Fragment>
          <p className="joyride-content--title mb-5">{t('joyRide.billing.stepTwo.detailContent')}</p>
          <p className="joyride-content">
            {t('joyRide.billing.stepTwo.content')}
          </p>
        </Fragment>
      ),
    },
    {
      target: ".search-billing-button",
      disableBeacon: true,
      hideCloseButton: true,
      placement: "right",
      title: <p className="joyride-title">{t('joyRide.billing.stepThree.title')}</p>,
      content: (
        <p className="joyride-content">
          {t('joyRide.billing.stepThree.content')}
        </p>
      ),
    },
  ];

  const closeModal = () => {
    setIsModalVisible(false);
    localStorage.setItem("billingTutorialDisabled", true);
  };

  const startTutorial = () => {
    setStartClientsTutorial(true);
    setIsModalVisible(false);
  };

  const joyrideCallback = (data) => {
    const { action, index } = data;

    if (action === "skip" || action === "reset") {
      localStorage.setItem("billingTutorialDisabled", true);
    }

    if (action === 'update') {
      const className = {
        0: 'last-invoices',
        1: 'first-button',
        2: 'search-billing-button',
      };
      window.scroll({ top: 0 });
      const element = document.getElementById(className[index]);
      if (element) {
        element.classList.add("animate__animated", "animate__headShake");
      }
    }
  };

  return (
    <Row justify="center" className="billing-container">
      <Joyride
        callback={joyrideCallback}
        run={startClientsTutorial}
        steps={steps}
        continuous
        showSkipButton
        disableOverlay
        disableScrolling
        styles={{
          options: {
            overlay: { height: "100vh" },
          },
        }}
        locale={{
          skip: 'Omitir',
          next: 'Continuar',
          last: 'Finalizar',
          back: 'Atrás'
        }}
      />
      <Col lg={22} md={18} sm={19} xs={22}>
        <Row
          justify="space-between"
          align="middle"
          className="mb-lg-60 mb-md-30 mb-xs-60"
          style={{ height: 50 }}
        >
          <Col lg={5} xs={24}>
            <Button
              id="search-billing-button"
              className="search-billing-button"
              size="large"
              onClick={showDrawer}
              block
            >
              <span className="icon-system-search mr-10" />
              Buscar facturas
            </Button>
          </Col>
          <Col lg={5} xs={24} className="mt-xs-20">
            <Row className='ant-form-item'>
              <Col span={24} className='ant-form-item-label' >
                <label>Empresa</label>
              </Col>
              <Col className='ant-form-item-control'>
                {
                  (companies) && (
                    <Select
                      id='company'
                      className='cmt-select'
                      defaultValue={company}
                      value={company}
                      onChange={(event) => setCompany(event)}
                    >
                      {companies.map((_company) =>
                        <Select.Option key={_company.id} value={_company.id}>
                          {_company.business_name}
                        </Select.Option>
                      )}
                    </Select>
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Drawer
          placement="left"
          onClose={closeDrawer}
          open={visibleDrawer}
          closable={true}
          width={400}
          className="billing-drawer"
        >
          <Row
            justify="center"
            align="middle"
            className="drawer-container mt-40"
          >
            <Col lg={15} md={16} sm={18} xs={22}>
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                className='cmt-form'
                size='large'
              >
                <Form.Item label="Buscar" name="search">
                  <Input
                    className='cmt-date-picker'
                    placeholder="N° de factura o de reserva"
                    size="large"
                  />
                </Form.Item>
                <Form.Item label="Fecha" name="dates">
                  <RangePicker
                    locale={locale}
                    className="date-picker"
                    style={{ width: '100%' }}
                    placeholder={["Desde", "Hasta"]}
                    picker="date"
                    size="large"
                  />
                </Form.Item>
                <Row justify="center">
                  <Col lg={20} xs={24} className="mt-20">
                    <Form.Item>
                      <Button
                        className="search-billing-button"
                        size="large"
                        htmlType="submit"
                        block
                      >
                        <span className="icon-system-search mr-10" />
                        Buscar facturas
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Drawer>
        <Row>
          <Col
            lg={24}
            xs={24}
            className="mt-lg-0 mt-md-30 mt-sm-30 mt-xs-40 mb-lg-10 mb-xs-5"
          >
            <h2 id="last-invoices" className="last-invoices">
              {t('billing.list.lastBillingsTitle')}
            </h2>
          </Col>
          <Col lg={24} xs={24}>
            {
              (companies && company)
                ?
                <Items
                  startDate={startDate}
                  endDate={endDate}
                  number={number}
                  company={company}
                />
                : <Empty description="Sin datos de facturación" />
            }
          </Col>
        </Row>
      </Col >

      <Modal
        open={isModalVisible}
        footer={false}
        onCancel={closeModal}
        width={900}
        closable={false}
        className="tutorial-modal"
      >
        <Row justify='center' className='mt-30 mb-30'>
          <Col>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50.948"
              height="67.866"
              viewBox="0 0 50.948 67.866"
            >
              <g
                id="Grupo_105"
                data-name="Grupo 105"
                transform="translate(-1641.689 -489.424)"
              >
                <path
                  id="Trazado_85"
                  data-name="Trazado 85"
                  d="M1678.122,541.1v16.176h-36.433V489.424h50.948V541.1Z"
                  fill="#5b356d"
                />
                <path
                  id="Trazado_86"
                  data-name="Trazado 86"
                  d="M1692.637,541.121l-14.481-.021v16.19"
                  fill="#ac99b5"
                />
              </g>
            </svg>
          </Col>
          <Col lg={24} xs={24} className="mt-30">
            <h1 className="text-center billing">{t('joyRide.billing.modal.title')}</h1>
            <p className="text-center">
              {t('joyRide.billing.modal.content')}
            </p>
          </Col>
          <Col lg={24} xs={24} className="mt-30">
            <Row justify='center'>
              <Col className='mr-30'>
                <Button
                  className='cmt-button primary outline'
                  type='primary'
                  onClick={closeModal}
                  size="large"
                >
                  Omitir tutorial
                </Button>
              </Col>
              <Col>
                <Button
                  className='cmt-button primary'
                  type='primary'
                  onClick={startTutorial}
                  size="large"
                >
                  ¡Muéstrame!
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </Row >
  );
};

export default Billing;
