import { useState, useCallback, useEffect } from "react";
import { medicalService } from "../../../../services/medical";
import { message } from 'antd';
import { createExcel } from "../../../../helpers/excel";

const useXlsGenerator = (filters) => {
  const [loadingXls, setLoadingXls] = useState({
    medical: false,
    cie10: false,
  });
  const [getJSON, setGetJSON] = useState({
    medical: false,
    cie10: false,
  });


  const fetchMedicalSurvillanceXlsData = useCallback(async () => {
    setLoadingXls(loadingXls => ({ ...loadingXls, medical: true }));
    try {
      const params = {
        ...filters,
        perPage: 100,
        page: 1,
      };

      let mostKeys = {};
      const response = await medicalService.downloadSurvillanceXls(params);
      mostKeys = response.keys;

      if (response?.pagination?.lastPage > 1) {
        const promises = [];
        for (let page = 2; page <= response.pagination.lastPage; page++) {
          const newParams = {
            ...params,
            page,
          };
          promises.push(medicalService.downloadSurvillanceXls(newParams));
        }

        const results = await Promise.all(promises);
        results.forEach(result => {
          if (Object.keys(result.keys).length > Object.keys(mostKeys).length) {
            mostKeys = result.keys;
            response.keys = mostKeys;
          }
          response.results = [...response.results, ...result.results];
        });
      }

      if (response?.results?.length > 0) {
        const attributes = {
          fileName: 'Reporte Vigilancia Médica.xlsx',
        };

        createExcel(response, attributes);
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error('Error en fetchMedicalSurvillanceXlsData', error);
      message.error({
        content: 'Ha ocurrido un error al Descargar Vigilancia Médica .xlsx',
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setGetJSON(getJSON => ({ ...getJSON, medical: false }));
      setLoadingXls(loadingXls => ({ ...loadingXls, medical: false }));
    }
  }, [getJSON]);

  const fetchCIE10XlsData = useCallback(async (params) => {
    setLoadingXls(loadingXls => ({ ...loadingXls, cie10: true }));
    try {
      const response = await medicalService.getCIE10XlsData(params);

      if (response?.results?.length > 0) {
        const attributes = {
          fontSize: 12,
          rowHeight: 60,
          fileName: 'Reporte CIE10.xlsx',
        };

        createExcel(response, attributes);
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error('Error en fetchCIE10XlsData', error);
      message.error({
        content: 'Ha ocurrido un error al Descargar informe CIE10 .xlsx',
        style: {
          marginTop: '35vh',
        },
      });
    } finally {
      setGetJSON(getJSON => ({ ...getJSON, cie10: false }));
      setLoadingXls(loadingXls => ({ ...loadingXls, cie10: false }));
    }
  }, [getJSON]);


  useEffect(() => {
    if (getJSON.medical) {
      fetchMedicalSurvillanceXlsData();
    }
    if (getJSON.cie10) {
      fetchCIE10XlsData(filters);
    }
  }, [fetchMedicalSurvillanceXlsData, fetchCIE10XlsData]);


  return {
    loadingXls,
    setGetJSON,
  };
};

export default useXlsGenerator;