import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Form } from 'antd'
import CostsCenterForm from './CostsCenterForm'

const CostCenterDetailModal = ({
  isSuperUser,
  modalVisible,
  setModalVisible,
  currentCostCenter,
  showNewCostCenterModalVisible,
  saveCostCenter,
  loading,
  countryCode,
}) => {
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (editMode) {
      form.setFieldsValue({
        id: currentCostCenter.id && currentCostCenter.id,
        name: currentCostCenter.name && currentCostCenter.name,
        code: currentCostCenter.code && currentCostCenter.code,
        active: !!currentCostCenter.active,
        responsibleFullName: currentCostCenter.manager,
        phone: currentCostCenter.phone,
        email: currentCostCenter.email,
      });
    }
  }, [editMode]);

  const closeModal = () => {
    setEditMode(false);
    setModalVisible(false);
  };

  return (
    <Modal
      open={modalVisible}
      onCancel={closeModal}
      footer={false}
      centered
      width={`85vw`}
      className="cost-center-detail-modal"
    >
      <Row justify='center'>
        {
          !editMode ?
            <Col lg={24} xs={24}>
              {
                currentCostCenter &&
                <Row justify='center' gutter={[0, 30]}>
                  <Col lg={24} xs={24} className="user-header-wrapper mt-20 pt-40 pb-5">
                    <Row>
                      <Col lg={24} xs={24}>
                        <p className='text-center bold-primary mb-0'>Centro de costo</p>
                        <h1 className='text-center mb-0'>{currentCostCenter['name']} {currentCostCenter['code']}</h1>
                      </Col>
                      <Col lg={24} xs={24}>
                        <p className='text-center'> Centro de costo activo:
                          {
                            currentCostCenter['active'] ?
                              <span className='success ml-10'>Sí</span>
                              :
                              <span className='error ml-10'>No</span>
                          }
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={20} xs={22}>
                    <Row>
                      <Col lg={24} xs={24}>
                        <h2 className='mb-15'>Datos generales</h2>
                      </Col>
                      <Col lg={24} xs={24} className="gray-wrapper p-20">
                        <Row>
                          <Col lg={24} xs={24}>
                            <p>
                              Código:
                              <span className='ml-10'>{currentCostCenter.code}</span>
                            </p>
                          </Col>
                          <Col lg={24} xs={24}>
                            <p>
                              Nombre responsable Centro de costo:
                              <span className='ml-10'>{currentCostCenter.manager}</span>
                            </p>
                          </Col>
                          <Col lg={24} xs={24}>
                            <p>
                              Teléfono:
                              <span className='ml-10'>{
                                currentCostCenter.phone
                              }</span>
                            </p>
                          </Col>
                          <Col lg={24} xs={24}>
                            <p className='mb-0'>
                              Correo electrónico:
                              <span className='ml-10'>{currentCostCenter.email}</span>
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      {
                        isSuperUser &&
                        <Col lg={24} xs={24} className="mt-30">
                          <Row justify='end'>
                            <Col xl={5} lg={6} xs={24}>
                              <Button
                                onClick={() => setEditMode(true)}
                                className="cmt-button secondary"
                                type='primary'
                                size='small'
                              >
                                <i className='icon-system-edit mr-10' />
                                Editar datos
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      }
                    </Row>
                  </Col>
                </Row>
              }
            </Col>
            :
            <Col lg={24} xs={24}>
              <CostsCenterForm
                form={form}
                loading={loading}
                closeModal={closeModal}
                formAction="edit"
                currentCostCenter={currentCostCenter}
                showNewCostCenterModalVisible={showNewCostCenterModalVisible}
                saveCostCenter={saveCostCenter}
                countryCode={countryCode}
              />
            </Col>
        }
      </Row>
    </Modal>
  )
}

export default CostCenterDetailModal
