import React, { Fragment } from 'react'
import { Router } from '@reach/router'
import { PermifyProvider } from "@permify/react-role"
import PrivateRoute from '../components/privateRoute'
import PublicRoute from '../components/publicRoute'
import ReservationRoute from '../components/reservationRoute'
import Login from '../components/Login'
import ChangePassword from '../components/ChangePassword'
import HomePrivate from '../templates/privates/home'
import ReservationList from '../templates/privates/reservations/List'
import ScheduleMain from '../templates/privates/scheduleTime/Main'
import BillingList from '../templates/privates/billing/List'
import Dashboard from '../templates/privates/statistics/Dashboard'
import ReservationDetail from '../templates/privates/reservations/Detail'
import Success from '../templates/privates/scheduleTime/Success'
import RegistrationInReservation from '../components/RegistrationInReservation'
import CreateNewPassword from '../components/CreateNewPassword'
import PreAdmissionMain from '../templates/privates/preAdmission/Main'
import MainView from '../templates/privates/preAdmission/MainView'
import CheckExams from '../templates/checkExams'
import GenerateReservationNotification from '../templates/generateReservationNotification'
import CompanyProfile from '../templates/privates/profilesAndCostCenters/CompanyProfile'
import UserProfile from '../templates/privates/profilesAndCostCenters/UserProfile'
import CreateNewUser from '../templates/privates/profilesAndCostCenters/CreateNewUser'
import PatientReevaluationPage from '../templates/privates/patientReevaluation/patientReevaluationPage'
import PatientReevaluationInfo from '../templates/privates/patientReevaluation/patientReevaluationInfo'
import MedicalMonitoring from '../templates/privates/medicalMonitoring/MedicalMonitoring'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import '../../locales/i18n'

const App = () => {
  return (
    <Fragment>
      <PermifyProvider>
      <I18nextProvider i18n={i18next}>
        <Router>
          <PrivateRoute exact path="/app/" component={HomePrivate} />
          <PrivateRoute exact path="/app/reservas" component={ReservationList} />
          <PrivateRoute exact path="/app/facturacion" component={BillingList} requiredPermissions='can_view_invoices' />
          <PrivateRoute exact path="/app/estadisticas" component={Dashboard} requiredPermissions='can_view_statistics' />
          <PrivateRoute exact path="/app/reservas/:id" component={ReservationDetail} />
          <PrivateRoute exact path="/app/reservas/:id/:atendido?" component={ReservationDetail} />
          <PrivateRoute exact path="/app/cambiar-contrasena" component={ChangePassword} />
          <PrivateRoute exact path="/app/reservas/:id/pre-admision" component={PreAdmissionMain} from="private" />
          <PrivateRoute exact path="/app/perfil-empresa/" component={CompanyProfile} />
          <PrivateRoute exact path="/app/perfil-usuario/" component={UserProfile} />
          <PrivateRoute exact path="/app/medico/" component={MedicalMonitoring} requiredPermissions='medical_administration' />
          <PublicRoute path="/app/reevaluacion/:medicalConsultationId" component={PatientReevaluationPage} />
          <PublicRoute path="/app/pasos-paciente/:medicalConsultationId" component={PatientReevaluationInfo} />
          <ReservationRoute exact path="/app/reservar/" component={ScheduleMain} requiredPermissions='can_reserve' />
          <PublicRoute exact path="/app/reservas/:id/public-pre-admision" component={PreAdmissionMain} from="public" />
          <PublicRoute exact path="/app/reservas/:id/public-pre-admision-view" component={MainView} from="public" />
          <PublicRoute exact path="/app/reservar/:id/exito" component={Success} />
          <PublicRoute path="/app/resultados/:id" type="byToken" component={ReservationDetail} />
          <PublicRoute exact path="/app/reservar/registrarse" component={RegistrationInReservation} />
          <PublicRoute exact path="/app/notificacion-reserva" component={GenerateReservationNotification} />
          <PublicRoute exact path="/app/nuevo-usuario/:invitationId" component={CreateNewUser} />
          <PublicRoute exact path="/app/checker/" component={CheckExams} />
          <PublicRoute exact path="/app/checker/:token" component={CheckExams} />
          <CreateNewPassword exact path="/app/crear-contrasena" />
          <Login exact path="/app/login/" />
        </Router>
      </I18nextProvider>
      </PermifyProvider>
    </Fragment>
  )
}

export default App