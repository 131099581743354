import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Divider, message, Row, Spin } from "antd";
import { getCompany } from '../../../../services/general';
import { format } from 'rut.js';
import PaymentBlock from './PaymentBlock';
import { scheduleTimeService } from '../../../../services/scheduleTime';
import { navigate } from 'gatsby';
import { getNumberFormatted } from '../../../../helpers/handlers';
import mercadopagoLogo from '../../../../images/MercadopagoLogo.webp';
import NewLogoMP from '../../../../images/NewLogoMP.svg';
import { useTranslation } from "react-i18next";
import moment from 'moment'

const SelectPayment = ({
  branchOffice,
  documentNumber,
  documentType,
  costCenter,
  cartState,
}) => {
  const [loading, setLoading] = useState({
    company: false,
    paying: false
  });
  const [company, setCompany] = useState({});
  const [paymentMethod, setPaymentMethod] = useState();
  const { t } = useTranslation();

  const fetchCompany = useCallback(async () => {
    setLoading(loading => ({ ...loading, company: true }));
    try {
      const response = await getCompany({
        query: documentType === 'rut' ? format(documentNumber, { dots: false }) : documentNumber,
        branch_office_id: branchOffice.id
      });

      if (response.data.length) {
        setCompany(response.data[0]);
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: `Error al obtener los datos de la compañía`,
        style: {
          marginTop: '45vh'
        }
      })
    } finally {
      setLoading(loading => ({ ...loading, company: false }));
    }
  }, [setLoading, setCompany]);

  const dataForTable = () => {
    const returnArray = [];
    const returnObj = cartState.patients.reduce((acc, patient) => {
      if (!acc[patient.block]) {
        acc[patient.block] = {};
      }

      patient.services.forEach(service => {
        if (!acc[patient.block][service.battery_name]) {
          acc[patient.block][service.battery_name] = {
            quantity: 1,
            value: service.value,
            unit_value: service.value,
          }
        } else {
          acc[patient.block][service.battery_name].quantity += 1;
          acc[patient.block][service.battery_name].value += service.value;
        }
      });

      return acc;
    }, {});

    Object.keys(returnObj)
      .sort((first, second) => parseInt(first.split(':')[0]) - parseInt(second.split(':')[0]))
      .forEach(blockKey => {
        Object.keys(returnObj[blockKey]).forEach(serviceKey => {
          returnArray.push({
            block: `${blockKey} hs`,
            exam: serviceKey,
            patients: returnObj[blockKey][serviceKey].quantity,
            amount: returnObj[blockKey][serviceKey].unit_value,
            amountAsString: getNumberFormatted({
              countryCode: cartState.locate_code,
              currencyCode: cartState.currency_code,
              amount: returnObj[blockKey][serviceKey].value
            }),
            key: blockKey + serviceKey,
          })
        })
      });

    return returnArray;
  }

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const handlePay = async () => {
    try {
      setLoading(loading => ({ ...loading, paying: true }));
      const data = {
        company_id: company.id,
        child_company_id: null,
        cost_center_id: costCenter,
        contact_method: 'way_web',
        document_number: documentType === 'rut' ? format(documentNumber, { dots: false }) : documentNumber,
        document_type: documentType,
        branch_office_id: branchOffice.id,
        reservation_date: cartState.patients[0].date,
        total_patients: cartState.total,
        patients: cartState.patients.map((patient) => {
          return {
            document_number: patient.documentNumber,
            document_type: patient.documentType,
            external_id: patient.external_id,
            prestations: patient.services.map((prestation) => {
              return {
                id: prestation.id,
                name: prestation.battery_name,
                type: prestation.type === 'Exam' ? 'exam' : 'battery',
                price: prestation.value,
                department_id: prestation.department_id,
                currency_id: prestation.currency_id,
                attached_service_id: prestation.attached_service_id
              }
            }),
            phone: patient.phone,
            block: patient.block,
            block_id: patient.block_id,
            ...(patient.profession && { profession: patient.profession }),
            ...(patient.email && { email: patient.email }),
            ...(patient.names && { names: patient.names }),
            ...(patient.last_name && { last_name: patient.last_name }),
            ...((patient.evaluation_type || patient.evaluation_type === 0) && { evaluation_type: patient.evaluation_type }),
          }
        }),
        paymentMethod,
      }

      const response = await scheduleTimeService.createReservation(data);
      if (response.number) {
        if (window.hj) {
          window.hj('event', 'reservations-success')
        }
        navigate(`/app/reservar/${response.number}/exito?method=${paymentMethod}`);
      } else {
        message.error(`${t('scheduleTime.selectPayment.errorMessage1')}`)
      }
    } catch (error) {
      console.error(error);
      if (error?.includes("reserve_unauthorized")) {
        message.error(`${t('scheduleTime.selectPayment.errorMessage2')}`)
      } else if (error === "unauthorized") {
        message.error(`${t('scheduleTime.selectPayment.expiredSessionText')}`)
      }
    } finally {
      setLoading(loading => ({ ...loading, paying: false }));
    }
  };

  const formatDate = (date) => {
    const format = moment(date, 'YYYY-MM-DD').locale("es").format('dddd, DD [de] MMMM [de] YYYY')
    const splitDate = format.split(",")
    const lowerCaseDayName = splitDate[0].toLowerCase()
    const capitalizeDayName = lowerCaseDayName.charAt(0).toUpperCase() + lowerCaseDayName.slice(1)
    const dateFormat = splitDate[1]
    return `${capitalizeDayName}, ${dateFormat}`;
  };

  const customTableHeader = () => {
    return (
      <Row className="cmt-custom-table-header">
        <Col
          lg={5}
          className="align-item-center-start p-10"
        >
          <h3>Bloque hora</h3>
        </Col>
        <Col
          lg={5}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Cantidad exámenes
          </h3>
        </Col>
        <Col
          lg={9}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Examen
          </h3>
        </Col>
        <Col
          lg={5}
          className="center-item p-10"
        >
          <h3 className="text-center">
            Valor
          </h3>
        </Col>
      </Row>
    );
  };

  const customTableBody = (data, index) => {
    return (
      <Row className="cmt-custom-table-body" key={index}>
        <Col
          lg={5}
          xs={12}
          className="align-item-center p-10"
        >
          <h3 className="hide-desktop text-center">Bloque hora</h3>
          <p className="mb-0">{data.block}</p>
        </Col>
        <Col
          lg={5}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">
            Cantidad de exámenes
          </h3>
          <p className='mb-0 text-center'>{data.patients}</p>
        </Col>
        <Col
          lg={9}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">
            Examen
          </h3>
          <p className='mb-0 text-center'>
            {data.exam}
          </p>
        </Col>
        <Col
          lg={5}
          xs={12}
          className="center-item p-10"
        >
          <h3 className="hide-desktop text-center">
            Valor
          </h3>
          <p className='mb-0 text-center'>
            {data.amountAsString}
          </p>
        </Col>
      </Row>
    );
  };


  return (
    <Row justify="center" className="payment-container">
      <Col lg={18} xs={22} className="mt-lg-40 mt-xs-0 pt-lg-30 pt-md-30 mb-20">
        <Row justify='space-between'>
          <Col
            lg={15} xs={24}
            className='payment-data-wrapper pl-lg-0 pl-md-15 pl-sm-15 pl-xs-15 pr-lg-0 pr-md-15 pr-sm-15 pr-xs-15'
          >
            <Row justify='center' gutter={[0, 20]} className='mb-10'>
              <Col lg={22} xs={24}>
                <h3>{t('scheduleTime.selectPayment.reservationDataTitle')}</h3>
              </Col>
              <Col lg={22} xs={24}>
                <Spin spinning={loading.company}>
                  Empresa: <span>{company.business_name || (documentType === 'rut' ? format(documentNumber, { dots: false }) : documentNumber)}</span>
                </Spin>
              </Col>
              <Col lg={22} xs={24}>
                Sucursal: <span>{branchOffice.name}</span>
              </Col>
              <Col lg={22} xs={24}>
                Fecha: <span>{formatDate(cartState.patients[0].date)}</span>
              </Col>
              <Col lg={22} xs={24}>
                <Divider className='mt-0 mb-0' />
                {
                  customTableHeader()
                }
                {
                  dataForTable().map((data, index) => {
                    return (
                      customTableBody(data, index)
                    )
                  })
                }
                <Divider className='mt-0 mb-0' />
              </Col>
              <Col lg={22} xs={24}>
                <Row justify='end'>
                  <Col>
                    Valor total: <strong>{
                      getNumberFormatted({
                        countryCode: cartState.locate_code,
                        currencyCode: cartState.currency_code,
                        amount: cartState.total_amount
                      })
                    }</strong>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            lg={8} xs={24}
            className='payment-data-wrapper pl-lg-0 pl-md-15 pl-sm-15 pl-xs-15 pr-lg-0 pr-md-15 pr-sm-15 pr-xs-15'
          >
            <Spin spinning={loading.company || loading.paying}>
              <Row justify='center' gutter={[0, 10]}>
                <Col lg={22} xs={24}>
                  <h3>{t('scheduleTime.selectPayment.paymentMethodTitle')}</h3>
                </Col>
                {
                  company.has_agreements ?
                    <Col lg={22} xs={24}>
                      <PaymentBlock
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        name='convenio'
                        disabled={loading.paying}
                        loading={loading.paying}
                      >
                        Convenio
                      </PaymentBlock>
                    </Col>
                    : company.has_credits ?
                      <Col lg={22} xs={24}>
                        <PaymentBlock
                          paymentMethod={paymentMethod}
                          setPaymentMethod={setPaymentMethod}
                          name='convenioOC'
                          disabled={loading.paying}
                          loading={loading.paying}
                        >
                          Orden de Compra
                        </PaymentBlock>
                      </Col>
                      :
                      <>
                        <Col lg={22} xs={24}>
                          <PaymentBlock
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            name='transferencia'
                            disabled={loading.paying}
                            loading={loading.paying}
                          >
                            Transferencia Bancaria
                          </PaymentBlock>
                        </Col>

                        <Col lg={22} xs={24}>
                          <PaymentBlock
                            paymentMethod={paymentMethod}
                            setPaymentMethod={setPaymentMethod}
                            name='sucursal'
                            disabled={loading.paying}
                            loading={loading.paying}
                          >
                            Pago en sucursal
                          </PaymentBlock>
                        </Col>

                        {
                          cartState.locate_code === 'es-CL' && (
                            <Col lg={22} xs={24}>
                              <PaymentBlock
                                paymentMethod={paymentMethod}
                                setPaymentMethod={setPaymentMethod}
                                name='mercadopagocl'
                                disabled={loading.paying}
                                loading={loading.paying}
                              >
                                <Row>
                                  <Col lg={24} xs={24} className="new-logo-wrapper">
                                    <img src={NewLogoMP} alt="Logo nuevo mercado pago" width={60} height={60} />
                                  </Col>
                                  <Col lg={24} xs={24} className="mercadopago-wrapper">
                                    <img src={mercadopagoLogo} alt="Mercadopago logo" height={30} />
                                  </Col>
                                </Row>
                              </PaymentBlock>
                            </Col>
                          )
                        }
                      </>
                }
              </Row>
            </Spin>
          </Col>
        </Row>
      </Col>
      <Col lg={18} xs={22}>
        <Row>
          <Col lg={15} xs={24}>
            <p className='special-text'>
              {t('scheduleTime.selectPayment.infoPaymentText')}
              &nbsp;
              <strong>
                <a
                  href="https://cmtsaludhelp.zendesk.com/hc/es-419"
                  target="_blank"
                  rel="noopener, noreferrer"
                >
                  Más información aquí
                </a>
              </strong>
            </p>
          </Col>
        </Row>
      </Col>
      <Col lg={18} xs={22}>
        <Row justify="end" className="mt-lg-40 mt-md-30 mt-sm-15 mt-xs-10 mb-lg-30 mb-md-0 mb-sm-30 mb-xs-80">
          <Col xl={7} lg={8} xs={24}>
            <Button
              onClick={handlePay}
              disabled={!paymentMethod || loading.paying || loading.company}
              loading={loading.paying || loading.company}
              className={
                (!paymentMethod || loading.paying || loading.company) ? 'cmt-button disabled' : 'cmt-button primary reservations-success'
              }
              type='primary'
              block
              size='large'
            >
              {t('scheduleTime.selectPayment.payButton')}
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SelectPayment;
