import React from 'react'
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  Checkbox,
  Divider,
} from 'antd'

const InviteNewUserModal = ({
  currentCompany,
  newUserModalVisible,
  setNewUserModalVisible,
  loading,
  saveUser,
}) => {
  const [form] = Form.useForm();

  const closeNewUserModal = () => {
    form.resetFields()
    setNewUserModalVisible(false)
  };

  return (
    <Modal
      open={newUserModalVisible}
      onCancel={closeNewUserModal}
      footer={false}
      centered
      width={`85vw`}
      className='details-modal'
    >
      <Row justify='center'>
        <Col lg={24} xs={24} className='user-header-wrapper mt-20 mb-40 pt-40 pb-5'>
          <Row>
            <Col lg={24} xs={24}>
              <h1 className='text-center  mb-0'>Invitar a Nuevos usuarios
              </h1>
            </Col>
            <Col lg={24} xs={24}>
              <p className='text-center'>{currentCompany && currentCompany.business_name}</p>
            </Col>
          </Row>
        </Col>
        <Col lg={20} xs={22}>
          <Row>
            <Col lg={24} xs={24}>
              <p className='small-text'>En esta sección puedes invitar a los usuarios que serán parte de tu equipo. Para ellos, se habilitarán las funciones de solicitud de horas médicas las que podrán asociar a centro de costos activados en la plataforma.</p>
            </Col>
            <Col lg={24} xs={24}>
              <Form
                form={form}
                onFinish={saveUser}
                layout='vertical'
                size='large'
                className='cmt-form'
              >
                <Form.List
                  name='users'
                  initialValue={[{ email: '' }]}
                >
                  {(newUsersList, { add, remove }) => (
                    <>
                      {
                        newUsersList.map(({ key, name, ...restField }, index) => (
                          <Row key={key} className='mt-30'>
                            <Col lg={24} xs={24} className='gray-wrapper p-20'>
                              <Row align='middle'>
                                <Col lg={7} xs={24}>
                                  <Row justify='center' align='middle'>
                                    <Col>
                                      <p className='bold-secondary mb-0'>Nuevo usuario {index + 1}</p>
                                    </Col>
                                    <Col>
                                      <i className='icon-system-user ml-10' />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={12} xs={24}>
                                  <Row justify='center' align='middle'>
                                    <Col lg={24} xs={24}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'email']}
                                        label='Correo electrónico'
                                        rules={[{
                                          required: true,
                                          message: 'Debes completar el correo electrónico'
                                        }]}
                                      >
                                        <Input
                                          className='cmt-input'
                                          placeholder='Ingrese correo electrónico'
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col lg={5} xs={24}>
                                  <Row justify='center' align='middle'>
                                    <Col>
                                      <Button
                                        onClick={() => remove(name)}
                                        className='cmt-button secondary non-outline p-0'
                                        type='primary'
                                      >
                                        <i className="icon-system-delete" />
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <>
                                <Divider />
                                <Col lg={24} xs={24}>
                                  <h2>Asignación de rol a usuario</h2>
                                  <p><strong>Nuevo usuario {index + 1} tendrá autorización para:</strong></p>
                                </Col>
                                <Row align='middle'>
                                  <Col lg={12} xs={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'canReserve']}
                                      valuePropName='checked'
                                      className='mb-0'
                                    >
                                      <Checkbox
                                        className='cmt-checkbox'
                                      >
                                        Realizar reservas
                                      </Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col lg={12} xs={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'canViewStatistics']}
                                      valuePropName='checked'
                                      className='mb-0'
                                    >
                                      <Checkbox
                                        className='cmt-checkbox'
                                      >
                                        Revisión portal estadísticas
                                      </Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col lg={12} xs={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'canDownloadResults']}
                                      valuePropName='checked'
                                      className='mb-0'
                                    >
                                      <Checkbox
                                        className='cmt-checkbox'
                                      >
                                        Visualizar y descarga resultados
                                      </Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col lg={12} xs={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'canViewInvoices']}
                                      valuePropName='checked'
                                      className='mb-0'
                                    >
                                      <Checkbox
                                        className='cmt-checkbox'
                                      >
                                        Información de Facturación
                                      </Checkbox>
                                    </Form.Item>
                                  </Col>
                                  <Col lg={12} xs={24}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'medicalAdministation']}
                                      valuePropName='checked'
                                      className='mb-0'
                                    >
                                      <Checkbox
                                        className='cmt-checkbox'
                                      >
                                        Administración Médica
                                      </Checkbox>
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </>
                            </Col>
                          </Row>
                        ))}
                      <Row gutter={[0, 30]}>
                        <Col lg={24} xs={24} className='mt-30'>
                          <Row justify='end'>
                            <Col xl={5} lg={6} xs={24}>
                              <Button
                                onClick={add}
                                className='cmt-button secondary outline'
                                type='primary'
                                size='middle'
                              >
                                <i className='icon-system-plus mr-10' />
                                Agregar usuario
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={24} xs={24}>
                          <Row justify='center'>
                            <Col xl={5} lg={6} xs={24}>
                              <Button
                                loading={loading}
                                htmlType='submit'
                                className='cmt-button primary'
                                type='primary'
                              >
                                Enviar invitación
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                </Form.List>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row >
    </Modal >
  )
};

export default InviteNewUserModal;
