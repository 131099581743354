import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { Row, Col, Button, Tag, Spin, Divider } from 'antd'
import { scheduleTimeService } from '../../../services/scheduleTime'
import { getNumberFormatted } from '../../../helpers/handlers'
import moment from 'moment'
import 'moment/locale/es'
import { sumBy } from 'lodash'
import Transfer from './Transfer'
import { ClockCircleFilled, WarningFilled } from '@ant-design/icons'
import { useTranslation } from "react-i18next";

const paymentMethods = {
  mercadopagocl: 'Mercadopago',
  transferencia: 'Transferencia bancaria',
  convenio: 'Convenio',
  convenioOC: 'Convenio orden de compra',
  sucursal: 'Pago en sucursal'
}

const Success = () => {
  const [reservationDetails, setReservationDetails] = useState({});
  const [currency, setCurrency] = useState()
  const [loading, isLoading] = useState(false);
  const [transferPage, setTransferPage] = useState(false);
  const params = useParams();
  const { t } = useTranslation();

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();

  const fetchReservation = async () => {
    isLoading(true);
    const response = await scheduleTimeService.reservationSuccess({ number: params.id });
    getCurrency(response)
    setReservationDetails(response);
    isLoading(false);
  }

  useEffect(() => {
    fetchReservation();
    if (query.get('method') === 'transferencia') setTransferPage(true);
  }, [])

  const formatDate = (date) => {
    const format = moment(date, 'YYYY-MM-DD').locale("es").format('dddd, DD [de] MMMM [de] YYYY')
    const splitDate = format.split(",")
    const lowerCaseDayName = splitDate[0].toLowerCase()
    const capitalizeDayName = lowerCaseDayName.replace(/\b(\w)/g, s => s.toUpperCase())
    const dateFormat = splitDate[1]
    return `${capitalizeDayName}, ${dateFormat}`
  }

  const getCurrency = (reservationDetails) => {
    setCurrency({ locate_code: reservationDetails.locate_code, currency_code: reservationDetails.currency_code });
  }

  const getBlock = (patient) => {
    const departments = patient.exams.map(exam => exam.department)

    if (departments.length > 1) {
      return patient.block.split(',')[0]
    }

    return patient.block;
  }

  return (
    <Spin spinning={loading} className='cmt-spin'>
    {
      transferPage ?
        <Transfer
          reservation={reservationDetails}
          setTransferPage={setTransferPage}
        />
      :
        <Row justify="center" className="reservation-success-container">
          <Col lg={22} xs={24}>
            <Row justify="center">
              <Col lg={20} xs={22} className="mt-20">
                <Row justify="center">
                  <Col lg={5} xs={24} className="mb-30" style={{ display: 'flex', justifyContent: 'center' }}>
                  {
                    query.get('state') === 'pending' ?
                      <ClockCircleFilled className='pending-icon' style={{ fontSize: 72 }} />
                    : query.get('state') === 'failure' ?
                        <WarningFilled className='failure-icon' style={{ fontSize: 72 }} />
                      :
                        <span className='icon-brand-feedback' />
                  }
                  </Col>
                  <Col lg={24} xs={24}>
                    {
                      !query.get('state') &&
                      <>
                        <h1 className="text-center success-title">{t('scheduleTime.success.successReservationTitle')}</h1>
                        <h2 className="text-center success-subtitle">{t('scheduleTime.success.successReservationContent')}</h2>
                      </>
                    }
                    {
                      query.get('state') === 'success' &&
                      <>
                        <h1 className="text-center success-title">{t('scheduleTime.success.successPaymentTitle1')} {params.id} {t('scheduleTime.success.successPaymentTitle2')}</h1>
                        <h1 className="text-center success-subtitle">{t('scheduleTime.success.successPaymentText')}</h1>
                      </>
                    }
                    {
                      query.get('state') === 'pending' &&
                      <>
                        <h1 className="text-center pending-title">{t('scheduleTime.success.pendingPaymentTitle1')} {params.id} {t('scheduleTime.success.pendingPaymentTitle2')}</h1>
                        <h2 className="text-center pending-title">{t('scheduleTime.success.pendingPaymentText')}</h2>
                      </>
                    }{
                      query.get('state') === 'failure' &&
                      <>
                        <h1 className="text-center failure-title">{t('scheduleTime.success.rejectedPaymentTitle1')} {params.id} {t('scheduleTime.success.rejectedPaymentTitle2')}</h1>
                        <h2 className="text-center failure-title">{t('scheduleTime.success.rejectedPaymentText')}</h2>
                      </>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center">
              <Col lg={20} xs={24} className="reservation-data-wrapper mt-15 pt-lg-30 pt-md-30 pt-xs-15 pb-lg-30 pb-md-30 pb-xs-30">
                <Row justify="center">
                  <Col lg={22} xs={22}>
                    <h3>{t('scheduleTime.success.reservationNumberTitle')}: {reservationDetails.reservation_number}</h3>
                  </Col>
                  <Col lg={22} xs={22}>
                    <Row>
                      <Col lg={12} xs={24}>
                        <Row>
                          <Col>
                            <h4>{t('scheduleTime.success.branchOfficeTitle')}</h4>
                          </Col>
                          <Col>
                            <p className="ml-10">{reservationDetails.branch_office}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} xs={24}>
                        <Row>
                          <Col>
                            <h4>{t('scheduleTime.success.totalValueTitle')}</h4>
                          </Col>
                          <Col>
                            <p className="ml-10">
                              {currency && reservationDetails && getNumberFormatted({
                                countryCode: currency.locate_code,
                                currencyCode: currency.currency_code,
                                amount: reservationDetails.total_amount
                              })}
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={22} xs={22}>
                    <Row>
                      <Col lg={12} xs={24}>
                        <Row>
                          <Col>
                            <h4 className="mr-10">{t('scheduleTime.success.dateAndTimeTitle')}:</h4>
                          </Col>
                          <Col>
                            <p className="lower-case">{reservationDetails.patients && formatDate(reservationDetails.patients[0].date)}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} xs={24}>
                        <Row>
                          <Col>
                            <h4>{t('scheduleTime.success.referenceNumberPaymentTitle')}</h4>
                          </Col>
                          <Col>
                            <p className="ml-10">{query.get('payment_id') || '-'}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={22} xs={22}>
                    <Row>
                      <Col lg={12} xs={24}>
                        <Row>
                          <Col>
                            <h4>{t('scheduleTime.success.totalPatientsTitle')}</h4>
                          </Col>
                          <Col>
                            <p className="ml-10">{reservationDetails.patients_quantity}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} xs={24}>
                        <Row>
                          <Col>
                            <h4>{t('scheduleTime.success.paymentMethod')}</h4>
                          </Col>
                          <Col>
                            <p className="ml-10">{paymentMethods[query.get('method')] || '-'}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Divider />
                  <Col lg={22} xs={22}>
                    <Row className="patients-data-wrapper pt-xs-0 pt-sm-0 pb-xs-30 pb-sm-30">
                    {
                      reservationDetails.patients && reservationDetails.patients.map((patient, index) => {
                        return (
                          <Col lg={12} xs={22} key={index} className="mt-20">
                            <Row>
                              <Col lg={24} xs={24}>
                                <Row>
                                  <Col>
                                    <span className='icon-system-user' />
                                  </Col>
                                  <Col>
                                    <p className="patient ml-10 mt-5">
                                      {t('scheduleTime.success.patientTitle')} {index + 1}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col lg={24} xs={24}>
                                <h4>Hora: <strong>{getBlock(patient)} hs</strong></h4>
                              </Col>
                              <Col lg={24} xs={24}>
                                Subtotal {t('scheduleTime.success.patientTitle').toLocaleLowerCase()}: <strong>
                                  {
                                    getNumberFormatted({
                                      countrCode: currency.locate_code,
                                      currencyCode: currency.currency_code,
                                      amount: sumBy(patient.exams, 'sale_amount')
                                    })
                                  }
                                </strong>
                              </Col>
                              <Col lg={24} xs={24}>
                                <Row>
                                {
                                  patient.exams.map(item => {
                                    return <Col key={item.id}>
                                      <Tag className="mt-10">{item.name}</Tag>
                                    </Col>
                                  })
                                }
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        )
                      })
                    }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {
              (query.get('state') === 'success' || query.get('state') === 'pending' || query.get('method') !== 'mercadopagocl') &&
              <Row justify="center">
                <Col lg={20} xs={22} className="mt-20 primary-text">
                  {t('scheduleTime.success.confirmationText')}
                </Col>
              </Row>
            }
            <Row
              justify="end"
              className="to-reservation-wrapper mt-lg-40 mt-md-20 mt-sm-30 mt-xs-20 mb-lg-130 mb-md-60 mb-sm-70 mb-xs-70"
            >
              <Col lg={3} xs={24}>
                <Link to="/app/reservas/" style={{ width: '100%' }}>
                  <Button
                    className="cmt-button primary"
                    type='primary'
                    size='large'
                    block
                  >
                    Ir a Reservas
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
    }
    </Spin>
  )
}

export default Success
