import { Row, Col, Drawer, Form, Button, Input, DatePicker, Select, Spin } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import 'moment/locale/es';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const DrawerFilter = ({
  t,
  form,
  loading,
  companies,
  branchOffices,
  setFilters,
  visibleDrawer,
  setVisibleDrawer,
}) => {

  const disableDates = current => {
    const today = moment().endOf('day');
    return current && current > today;
  };

  const onFinish = (values) => {
    let filterValues = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value)
    );

    if (filterValues.range) {
      filterValues = {
        ...filterValues,
        startDate: moment(values.range[0]).format('YYYY-MM-DD'),
        endDate: moment(values.range[1]).format('YYYY-MM-DD'),
      };

      delete filterValues.range;
    }

    setFilters({ ...filterValues });

    setVisibleDrawer(false);
  };


  return (
    <Drawer
      placement="left"
      open={visibleDrawer}
      onClose={() => setVisibleDrawer(false)}
      closable
      width={450}
      className="medical-filter-drawer"
    >
      <Row>
        <Col lg={24} xs={24}>
          <Form
            form={form}
            size='large'
            layout="vertical"
            className='cmt-form'
            onFinish={onFinish}
          >
            <Row justify='end'>
              <Col lg={20} xs={24}>
                <Form.Item name="query" label="Buscar">
                  <Input
                    id="queryInput"
                    className='cmt-input'
                    placeholder={t("medicalMain.drawerFilter.queryPlaceholder")}
                  />
                </Form.Item>
              </Col>
              <Col lg={20} xs={24}>
                <Form.Item
                  name='range'
                  label='Fecha'
                  extra="El límite de selección es de máximo 6 meses"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator() {
                        let startDate = getFieldValue('range')[0];
                        let endDate = getFieldValue('range')[1];
                        let monthsDiff = endDate?.diff(startDate, 'months');

                        if (monthsDiff > 6) {
                          return Promise.reject(new Error('El Rango de Fechas debe ser MÁXIMO 6 meses'));
                        }
                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <RangePicker
                    id="rangeDate"
                    format='DD-MM-YYYY'
                    locale={locale}
                    className="cmt-date-picker"
                    placeholder={['Desde', 'Hasta']}
                    inputReadOnly
                    clearIcon={false}
                    disabledDate={disableDates}
                  />
                </Form.Item>
              </Col>
              <Col lg={20} xs={24}>
                {
                  loading.branchOffices ?
                    <Spin className='cmt-spin' />
                    :
                    branchOffices?.length > 0 &&
                    <Form.Item
                      name="branchOfficeId"
                      label={t("medicalMain.drawerFilter.branchOfficeLabel")}
                    >
                      <Select
                        id="branchOfficesSelect"
                        className='cmt-select'
                        placeholder={t("medicalMain.drawerFilter.branchOfficePlaceholder")}
                      >
                        {
                          branchOffices.map((item, index) => {
                            return <Option id={`option-${index}`} value={item.id} key={item.id}>
                              {item.name}
                            </Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                }
              </Col>
              <Col lg={20} xs={24}>
                {
                  loading.companies ?
                    <Spin className='cmt-spin' />
                    :
                    companies?.length > 0 &&
                    <Form.Item
                      name="companyId"
                      label="Empresa"
                    >
                      <Select
                        id="companiesSelect"
                        className='cmt-select'
                        placeholder="Selecciona empresa"
                      >
                        {
                          companies.map((item, index) => {
                            return <Option id={`option-${index}`} value={item.id} key={item.id}>
                              {item.business_name}
                            </Option>
                          })
                        }
                      </Select>
                    </Form.Item>
                }
              </Col>
              <Col lg={20} xs={24} className='mt-40'>
                <Button
                  className='cmt-button primary'
                  type='primary'
                  htmlType='submit'
                >
                  <span className='icon-system-search mr-10' />
                  Ver resultados
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Drawer>
  );
};

export default DrawerFilter;