import { Row, Col, Statistic, Spin, Empty } from 'antd';

const ByTypeOfCare = ({ loading, data }) => {

  const statisticTitle = (keyName) => {
    switch (keyName) {
      case 'preocupational':
        return 'Pre Ocupacional';
      case 'occupational':
        return 'Ocupacional';
      case 'annual_periodic':
        return 'Anual Periódico';
      case 'retirement':
        return 'Retiro';
      case 'relocation':
        return ' Reubicación';
      case 'rejoin':
        return 'Reingreso';
      default:
        return '';
    }
  };


  return (
    <Spin className='cmt-spin' spinning={loading}>
      <Row className='by-type-of-care-text mt-100'>
        <Col lg={24} xs={24}>
          <h2 className='mb-30'>Reporte general por tipo de atención</h2>
        </Col>
      </Row>
      {
        data ?
          <Row justify='space-between' gutter={[20, 20]} className='care-statistics-wrapper'>
            {
              Object.entries(data).map(([key, value], index) => {
                return (
                  <Col key={index} className='care-item'>
                    <Statistic
                      className="cmt-statistic"
                      title={
                        <div>
                          <p className="title">{value}</p>
                          <p className="sub-title">Examen Médico</p>
                        </div>
                      }
                      value={statisticTitle(key)}
                    />
                  </Col>
                )
              })
            }
          </Row>
          :
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='Sin datos de Reporte general por tipo de atención'
          />
      }
    </Spin>
  );
};

export default ByTypeOfCare;

