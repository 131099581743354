import React from 'react';
import { Row, Col, Spin, Empty } from 'antd';
import { useTranslation } from "react-i18next";
import Sidebar from './components/Sidebar';
import CompanyData from './components/CompanyData';
import AssociatedCostCenters from './components/AssociatedCostCenters';
import AllowedUsers from './components/AllowedUsers';
import UseCompanies from './hooks/useCompanies';
import BillingData from './components/BillingData';

const CompanyProfile = () => {
  const useCompanies = UseCompanies();
  const { t } = useTranslation();
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;

  return (
    <Row justify='center' className='company-profile'>
      {
        (useCompanies.loading.companies || useCompanies.loading.costCenters || useCompanies.loading.users) ?
          <Spin className='cmt-spin mt-60' />
          :
          (useCompanies.companiesList && useCompanies.currentCompany
            && useCompanies.user.email && useCompanies.user.name)
            ? (
              <>
                <Col lg={6} xs={24}>
                  <Sidebar
                    user={useCompanies.user}
                    companiesList={useCompanies.companiesList}
                    currentCompany={useCompanies.currentCompany}
                    setCurrentCompany={useCompanies.setCurrentCompany}
                  />
                </Col>
                <Col lg={18} xs={24} className="mb-60">
                  <Row justify='center'>
                    <Col lg={23} xs={23}>
                      <CompanyData
                        currentCompany={useCompanies.currentCompany}
                        t={t}
                        countryCode={countryCode}
                      />
                      <BillingData
                        isSuperUser={useCompanies.isSuperUser}
                        billingData={useCompanies.billingData}
                        loading={useCompanies.loading}
                        countryCode={countryCode}
                        saveBillingData={useCompanies.saveBillingData}
                      />
                      <AssociatedCostCenters
                        isSuperUser={useCompanies.isSuperUser}
                        costCenters={useCompanies.costCentersList}
                        saveCostCenter={useCompanies.saveCostCenter}
                        loading={useCompanies.loading.saveCostCenters}
                        countryCode={countryCode}
                        t={t}
                        saveCostCenterActive={useCompanies.saveCostCenterActive}
                      />
                      <AllowedUsers
                        isSuperUser={useCompanies.isSuperUser}
                        users={useCompanies.usersList}
                        currentCompany={useCompanies.currentCompany}
                        saveUser={useCompanies.saveUser}
                        permissionsSave={useCompanies.permissionsSave}
                        loading={useCompanies.loading.saveUser}
                        t={t}
                        countryCode={countryCode}
                        currentUser={useCompanies.currentUser}
                        setCurrentUser={useCompanies.setCurrentUser}
                        saveUserActive={useCompanies.saveUserActive}
                        email={useCompanies.user.email}
                      />
                    </Col>
                  </Row>
                </Col>
              </>
            )
            : <Empty description="Sin datos" />
      }
    </Row>
  );
};


export default CompanyProfile;