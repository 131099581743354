import React, { useState, useEffect, useCallback } from 'react';
import { Spin, message } from 'antd';
import { useParams } from '@reach/router';
import { preAdmissionService } from '../../../services/preAdmission';
import WithoutPatients from './components/WithoutPatients';
import WithPatients from './components/WithPatients';

const Main = ({ from }) => {
  const [reservedData, setReservedData] = useState();
  const [services, setServices] = useState();
  const [patients, setPatients] = useState();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const countryCode = window.location.hostname === 'localhost'
    ? process.env.GATSBY_COUNTRY_VALUE
    : window.location.hostname.split('.').at(2).toUpperCase();

  const fetchReservedData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await preAdmissionService.reservationDetail({ number: params.id, from });
      setReservedData(response);

      const response2 = await preAdmissionService.reservationServices({ number: params.id, from });
      setServices(response2);

      const response3 = await preAdmissionService.reservationPatients({ number: params.id, from });
      setPatients(response3);
    } catch (error) {
      console.error(`Error al traer datos de la reserva: ${error}`);
      message.error({
        content: `Error al traer datos de la reserva: ${error}`,
        style: {
          marginTop: '45vh',
        },
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchReservedData();
  }, [fetchReservedData]);

  return (
    <Spin spinning={loading} className='cmt-spin' style={{ marginTop: '30vh' }}>
      {
        (reservedData && services && patients && patients.length === 0) &&
        <WithoutPatients services={services} reservedData={reservedData} from={from} countryCode={countryCode} />
      }
      {
        (reservedData && patients && patients.length > 0) &&
        <WithPatients patients={patients} reservedData={reservedData} from={from} countryCode={countryCode} />
      }
    </Spin>
  );
};

export default Main;