import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  Popconfirm,
  Form,
  Checkbox,
} from 'antd'

const PermissionForm = ({
  extra_data,
  user,
  permissionsSave,
  loading,
  disabled,
}) => {
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const handleOnClick = (extraData) => {
    form.setFieldsValue({
      canReserve: !!extraData.can_reserve,
      canViewStatistics: !!extraData.can_view_statistics,
      canDownloadResults: !!extraData.can_download_results,
      canViewInvoices: !!extraData.can_view_invoices,
      medicalAdministation: !!extraData.medical_administration,
    });
    setOpen(true)
  };

  const handleOk = (values, user) => {
    permissionsSave(values, user);
    setOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
  }

  return (
    <Popconfirm
      icon=''
      visible={open}
      title={
        <div className='ant-popconfirm-user-permissions'>
          <h3><strong>Desea editar el rol de usuario</strong></h3>
          <Form
            form={form}
            onFinish={(values) => handleOk(values, user)}
            layout='vertical'
            size='large'
            className='cmt-form'
          >
            <Row>
              <Col lg={12} xs={24}>
                <Form.Item
                  name='canReserve'
                  valuePropName='checked'
                  className='mb-0'
                >
                  <Checkbox className='cmt-checkbox'>
                    Realizar reservas
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  name='canViewStatistics'
                  valuePropName='checked'
                  className='mb-0'
                >
                  <Checkbox className='cmt-checkbox'>
                    Revisión portal estadísticas
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col lg={12} xs={24}>
                <Form.Item
                  name='canDownloadResults'
                  valuePropName='checked'
                  className='mb-0'
                >
                  <Checkbox className='cmt-checkbox'>
                    Visualizar y descargar resultados
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  name='canViewInvoices'
                  valuePropName='checked'
                  className='mb-0'
                >
                  <Checkbox className='cmt-checkbox'>
                    Información de Facturación
                  </Checkbox>
                </Form.Item>
              </Col>
              {
                countryCode?.toUpperCase() === 'PE' &&
                <Col lg={12} xs={24}>
                  <Form.Item
                    name='medicalAdministation'
                    valuePropName='checked'
                    className='mb-0'
                  >
                    <Checkbox className='cmt-checkbox'>
                      Administración Médica
                    </Checkbox>
                  </Form.Item>
                </Col>
              }
            </Row>
            <Row gutter={[10, 0]} className='mt-20'>
              <Col lg={12} xs={24}>
                <Button
                  loading={loading.form}
                  className='cmt-button primary outline'
                  type='primary'
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
              </Col>
              <Col lg={12} xs={24}>
                <Button
                  loading={loading.form}
                  className='cmt-button primary'
                  htmlType='submit'
                  type='primary'
                >
                  Guardar
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      }
      showCancel={false}
      okButtonProps={{ className: 'hidden' }}
    >
      <Button
        type='primary'
        onClick={() => handleOnClick(extra_data)}
        className='cmt-button primary outline'
        disabled={disabled}
      >
        Editar
      </Button>
    </Popconfirm>
  )
};

export default PermissionForm;