import { HasAccess } from '@permify/react-role';
import { useParams } from '@reach/router';
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Popover,
  Row,
  Spin,
  Tag,
  Tooltip,
  message,
} from "antd";
import humanizeList from 'humanize-list';
import { map, uniq } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
  reservationService,
} from '../../../services/reservations';
import DownloadAttachment from "./components/downloadAttachment";

const Detail = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [reservation, setReservation] = useState();
  const [exams, setExams] = useState();
  const [reservedExams, setReservedExams] = useState();
  const [consultationStates, setConsultationStates] = useState();
  const [dashboard, setDashboard] = useState();
  const params = useParams();
  const [selectedExams, setSelectedExams] = useState([]);
  const { t } = useTranslation();

  const country_code = `${process.env.GATSBY_COUNTRY_VALUE}`;

  const fetchReservationDetail = async (idNumber) => {
    setLoading(true);
    try {
      let response = [];
      if (type === "byToken") {
        response = await reservationService.getReservationByToken(idNumber);
        if (response?.length > 0) {
          setReservation(response[0]);
        }
      } else {
        response = await reservationService.getReservation(idNumber);
        if (response?.length > 0) {
          setReservation(response[0]);
        }
      }
      if (response?.length > 0) {
        await fetchReservationExams(
          params.id,
          response[0].locked,
          type !== "byToken"
        );
        await fetchReservedExams(params.id, type !== "byToken");
        await fetchDashboard(params.id, type !== "byToken");
      }
    } catch (error) {
      console.error(error);
      message.error({
        content: `Error al obtener los datos ${error}`,
        style: {
          marginTop: "40vh",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchReservationExams = async (idNumber, locked, isFromAccount) => {
    if (locked) {
      return;
    }
    if (isFromAccount) {
      const response = await reservationService.getExams(idNumber);
      setExams(response);
    } else {
      const response = await reservationService.getExamsByToken(idNumber);
      setExams(response);
    }
  };

  const fetchReservedExams = async (idNumber, isFromAccount) => {
    if (isFromAccount) {
      const response = await reservationService.getReservedExams(idNumber);
      setReservedExams(response);
    } else {
      const response = await reservationService.getReservedExamsByToken(
        idNumber
      );
      setReservedExams(response);
    }
  };

  const fetchDashboard = async (idNumber, isFromAccount) => {
    if (isFromAccount) {
      const response = await reservationService.getDashboard(idNumber);
      setDashboard(response);
    } else {
      const response = await reservationService.getDashboardByToken(idNumber);
      setDashboard(response);
    }
  };

  const fetchConsultationStateReasons = async () => {
    try {
      const response = await reservationService.getConsultationStateReasons();
      setConsultationStates(response);
    } catch (error) {
      console.error(error);
      message.error({
        content: `Error al obtener los datos ${error}`,
        style: {
          marginTop: "40vh",
        },
      });
    }
  };

  useEffect(() => {
    fetchReservationDetail(params.id);
    fetchConsultationStateReasons();
  }, []);

  const formatDate = (date) => {
    return moment(date, 'YYYY-MM-DDTHH:mm:ssZ').format('DD-MM-YYYY');
  };

  const getStateDescription = (key) => {
    for (const reason of consultationStates) {
      if (reason.key === key) {
        return reason.description;
      }
    }
  };

  const checkExamState = (state, state_result, pendingReasons, reevaluationExpDate) => {
    let popOverContent;

    if (pendingReasons?.length > 0) {
      popOverContent = (
        <Row>
        {
          pendingReasons.map((reason, index) => {
            return (
              <p key={index} className="mb-5">{getStateDescription(reason)}</p>
            )
          })
        }
        </Row>
      );
    }

    if ((state === "pending" || hasPendingReasons(pendingReasons, 'debt')) && pendingReasons?.length > 0 ) {
      return <Popover
        content={popOverContent}
        title="Razones"
        overlayClassName="pending-reason-popover"
      >
        <h3 className="mb-0 pending-title">
          {state_result || 'Pendiente'}&nbsp;
          <span className="ml-5 icon-alert-triangle-bold" />
        </h3>
      </Popover>
    } else if (state.includes('approved')) {
      return <h3 className="mb-0 approved-title">{state_result || t('reservations.detail.approvedTitle')}</h3>;
    } else if (state_result.includes('Reevaluación') && country_code === 'CL') {
      return (
        // <Popover
        //   overlayClassName="reevaluation-popover"
        //   title="Reevaluación CMT"
        //   content={
        //     <p
        //       className="mb-0"
        //       dangerouslySetInnerHTML={{ __html: t('reservations.detail.reevaluationPopover', {date: reevaluationExpDate && formatDate(reevaluationExpDate)})}}
        //     />
        //   }
        // >
          <h3 className="mb-0 reevaluation-title">{state_result}</h3>
        // </Popover>
      );
    } else if (state.includes('reproved') && state_result.includes('Abandonado')) {
      return <Popover
        overlayClassName="abandoned-popover"
        title="Razón"
        content={
          <p className="mb-0">
            {t('reservations.detail.abandonedPopover')}
          </p>
        }
      >
        <h3 className="mb-0 disapproved-title abandoned">{state_result || t('reservations.detail.notApprovedTitle')}</h3>
      </Popover>;
    } else {
      return <h3 className="mb-0 disapproved-title">{state_result || t('reservations.detail.notApprovedTitle')}</h3>;
    }
  };


  const calcAssistance = (val1, val2) => {
    const calcSign = val1 - val2;
    let result = 0;
    if (Math.sign(calcSign) === -1) {
      return result;
    } else {
      result = (val1 / val2) * 100;
      if (result > 100) {
        return 100;
      }
      return Math.round(result);
    }
  };

  const capitalizeText = (text) => {
    let splitWord = text.split(' ');

    splitWord.map((item, index) => {
      splitWord[index] = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    });

    return splitWord.join(' ');
  };

  const absentWorkers = (data) => {
    if (reservation.length && reservation.details.length) {
      const date = moment(
        reservation.details[0].reservation_date,
        "YYYY-MM-DD"
      );
      if (date > moment()) {
        return 0;
      }
    }

    const value = data.total_reserved_patients - data.total_admission_patients;

    if (value < 0) {
      return 0;
    }

    return value;
  };

  const downloadable = (blob, fileName) => {
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      fileName,
    );

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const createParamsCheck = () => {
    const consultations = [];
    for (let item of selectedExams) {
      consultations.push(`medical_consultation_details[][id]=${item}`);
    }
    return consultations.join('&');
  };

  const downloadSelectedExams = () => {
    const url = `${process.env.GATSBY_ADMIN_URL}/report_results_download.zip`;
    const fullUrl = `${url}?${createParamsCheck()}`;
    setDownloading(true);

    message.loading({
      content: 'Estamos procesando la descarga, esto puede tardar un poco',
      duration: 0,
      style: {
        marginTop: '90vh',
      },
    });

    fetch(fullUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/zip',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        downloadable(blob, 'descarga.zip');
      })
      .finally(() => {
        message.destroy();
        setDownloading(false);
      });
  };

  const handleSelectExam = (isChecked, consultationId) => {
    if (isChecked) {
      setSelectedExams((selectedExams) => [...selectedExams, consultationId]);
    } else {
      const filterExams = selectedExams.filter(
        (consultation) => consultation !== consultationId
      );
      setSelectedExams([...filterExams]);
    }
  };

  const handleSelectAllExams = (event) => {
    if (event.target.checked) {
      for (let item of exams) {
        setSelectedExams((selectedExams) => [
          ...selectedExams,
          item.consultationId,
        ]);
      }
    } else {
      setSelectedExams([]);
    }
  };

  const hasPendingReasons = (pendingReasons, key) => {
    return pendingReasons?.length && pendingReasons.includes(key);
  };


  return (
    <Spin spinning={loading} className="cmt-spin">
      <Row justify="center" className="reservation-detail">
        <Col xl={21} lg={23} xs={22}>
          <Row justify="center" className="title-wrapper mt-lg-20" gutter={[0, 20]}>
            <Col lg={24} xs={24}>
              <Row>
                <Col>
                  <Button
                    className="cmt-button primary non-outline pl-0"
                    type="link"
                    onClick={() => window.history.back()}
                  >
                    <span className="icon-system-chevron-left mr-5" />
                    Volver
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col lg={24} xs={24}>
              <h1 className="text-center">{t('reservations.detail.reservationDetailTitle')}</h1>
            </Col>
          </Row>

          <Row
            justify="center"
            className="reservation-detail-container mt-20"
          >
            <Col
              lg={22} xs={24}
              className="reservation-detail-wrapper mt-lg-30 mt-md-30 mt-sm-5 mt-xs-5 mb-lg-50 mb-md-30 mb-sm-0 mb-xs-0"
            >
            {
              reservation && (
                <React.Fragment>
                  <Row justify="center">
                    <Col lg={24}>
                      <h2 className="mb-lg-10 mb-md-10 mb-sm-30 mb-xs-30">
                        {t('reservations.detail.reservationNumberTitle')} {reservation.number}
                      </h2>
                    </Col>
                  </Row>
                  <Row
                    justify="center"
                    className="applicant-mobile-gray-wrapper pt-lg-0 pt-md-20 pt-sm-20 pt-xs-20 pl-lg-0 pl-md-15 pl-sm-15 pl-xs-15 pr-lg-0 pr-md-15 pr-sm-15 pr-xs-0"
                  >
                    <Col lg={24} xs={24}>
                      <Row>
                        <Col lg={10} xs={24}>
                          <Row>
                            <Col>
                              <h3 className="mr-10">{t('reservations.detail.branchOfficeTitle')}</h3>
                            </Col>
                            <Col>
                              <p>{reservation.branch_office}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h3 className="mr-10">{t('reservations.detail.dateTitle')}</h3>
                            </Col>
                            <Col>
                              <p>
                                {formatDate(
                                  reservation.details[0].reservation_date
                                )}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h3 className="mr-10">{t('reservations.detail.timeTitle')}</h3>
                            </Col>
                            <Col>
                              <p>
                                {humanizeList(
                                  uniq(map(reservation.details, "start")),
                                  { conjunction: "y" }
                                )}{" "}
                                hs
                              </p>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={14} xs={24}>
                          <Row>
                            <Col>
                              <h3 className="mr-10">{t('reservations.detail.companyTitle')}</h3>
                            </Col>
                            <Col>
                              <p>{reservation.business_name}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <h3 className="mr-10">{t('reservations.detail.applicantTitle')}</h3>
                            </Col>
                            <Col>
                              <p>{reservation.user}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </React.Fragment>
              )
            }
              <Row
                className="reserved-exams-mobile-gray-wrapper pb-lg-0 pb-md-20 pb-sm-20 pb-xs-20 pl-lg-0 pl-md-15 pl-sm-15 pl-xs-15 pr-lg-0 pr-md-15 pr-sm-15 pr-xs-0"
                gutter={[10, 10]}
              >
                <Col>
                  <h3>{t('reservations.detail.reservedServicesTitle')}</h3>
                </Col>
                <Col>
                  <Row className="exams-tag-wrapper">
                  {
                    reservedExams &&
                      uniq(reservedExams).map((exam, index) => {
                        return (
                          <Tag className="cmt-tag mb-10 mr-10" key={index}>
                            {exam.exam}
                          </Tag>
                        );
                      })
                  }
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row
            justify="space-between"
            className="mt-lg-15 mt-md-10 mt-sm-10 mt-xs-10 mb-lg-70 mb-md-40 mb-sm-30 mb-xs-30"
            gutter={[10, 10]}
          >
          {
            reservation && dashboard?.map((data, index) => {
                return (
                  <React.Fragment key={index}>
                    <Col lg={4} md={8} sm={8} xs={8}>
                      <Row
                        justify="center"
                        align="middle"
                        className="detail-square pt-lg-30 pt-md-20 pt-xs-15 pb-lg-30 pb-md-10 pb-xs-15"
                      >
                        <Col
                          lg={{ order: 1, span: 20 }}
                          md={{ order: 2, span: 20 }}
                          xs={{ order: 2, span: 21 }}
                        >
                          <h3 className="detail-square-workers-title text-center mb-lg-30 mb-md-10 mb-xs-0">
                            {t('reservations.detail.totalPatientsTitle')}
                          </h3>
                        </Col>
                        <Col
                          lg={{ order: 2, span: 22 }}
                          md={{ order: 1, span: 20 }}
                          xs={{ order: 1, span: 21 }}
                        >
                          <p className="text-center mb-xs-10">
                            {data.total_reserved_patients}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={8} sm={8} xs={8}>
                      <Row
                        justify="center"
                        align="middle"
                        className="detail-square square-total-patients-wrapper pt-lg-30 pt-md-20 pt-xs-15 pb-lg-30 pb-md-10 pb-xs-15"
                      >
                        <Col
                          lg={{ order: 1, span: 22 }}
                          md={{ order: 2, span: 22 }}
                          xs={{ order: 2, span: 23 }}
                        >
                          <h3 className="detail-square-workers-title text-center mb-lg-30 mb-md-10 mb-xs-0">
                            {t('reservations.detail.lastPatientsTitle')}
                          </h3>
                        </Col>
                        <Col
                          lg={{ order: 2, span: 22 }}
                          md={{ order: 1, span: 22 }}
                          xs={{ order: 1, span: 23 }}
                        >
                          <p className="text-center mb-xs-10">
                            {data.total_admission_patients}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={8} sm={8} xs={8}>
                      <Row
                        justify="center"
                        align="middle"
                        className="detail-square square-absent-workers-wrapper pt-lg-30 pt-md-20 pt-xs-15 pb-lg-30 pb-md-10 pb-xs-15"
                      >
                        <Col
                          lg={{ order: 1, span: 21 }}
                          md={{ order: 2, span: 22 }}
                          xs={{ order: 2, span: 22 }}
                        >
                          <h3 className="detail-square-workers-title text-center mb-lg-30 mb-md-10 mb-xs-0">
                            {t('reservations.detail.absentPatientsTitle')}
                          </h3>
                        </Col>
                        <Col
                          lg={{ order: 2, span: 22 }}
                          md={{ order: 1, span: 22 }}
                          xs={{ order: 1, span: 22 }}
                        >
                          <p className="text-center mb-xs-10">
                            {absentWorkers(data)}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={8} sm={8} xs={8}>
                      <Row
                        justify="center"
                        align="middle"
                        className="detail-square square-data-wrapper pt-lg-30 pt-md-20 pt-xs-20 pb-lg-30 pb-md-15 pb-xs-20"
                      >
                        <Col
                          lg={{ order: 1, span: 20 }}
                          md={{ order: 2, span: 22 }}
                          xs={{ order: 2, span: 24 }}
                        >
                          <h3 className="detail-square-title text-center mb-lg-30 mb-xs-0">
                            {t('reservations.detail.attendanceTitle')}
                          </h3>
                        </Col>
                        <Col
                          lg={{ order: 2, span: 20 }}
                          md={{ order: 1, span: 22 }}
                          xs={{ order: 1, span: 24 }}
                        >
                          <p className="text-center mb-xs-10">
                            {calcAssistance(
                              data.total_admission_patients,
                              data.total_reserved_patients
                            )}
                            %
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={8} sm={8} xs={8}>
                      <Row
                        justify="center"
                        align="middle"
                        className="detail-square square-data-wrapper pt-lg-30 pt-md-20 pt-xs-20 pb-lg-30 pb-md-15 pb-xs-20"
                      >
                        <Col
                          lg={{ order: 1, span: 20 }}
                          md={{ order: 2, span: 22 }}
                          xs={{ order: 2, span: 24 }}
                        >
                          <h3 className="detail-square-title text-center mb-lg-30 mb-xs-0 approved-title">
                            {t('reservations.detail.approvedPatientsTitle')}
                          </h3>
                        </Col>
                        <Col
                          lg={{ order: 2, span: 20 }}
                          md={{ order: 1, span: 22 }}
                          xs={{ order: 1, span: 24 }}
                        >
                          <p className="text-center mb-xs-10">
                            {data.total_approved}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4} md={8} sm={8} xs={8}>
                      <Row
                        justify="center"
                        align="middle"
                        className="detail-square square-data-wrapper pt-lg-30 pt-md-20 pt-xs-20 pb-lg-30 pb-md-15 pb-xs-20"
                      >
                        <Col
                          lg={{ order: 1, span: 20 }}
                          md={{ order: 2, span: 22 }}
                          xs={{ order: 2, span: 24 }}
                        >
                          <h3 className="detail-square-title text-center mb-lg-30 mb-xs-0 disapproved-title">
                            {t('reservations.detail.notApprovedPatientsTitle')}
                          </h3>
                        </Col>
                        <Col
                          lg={{ order: 2, span: 20 }}
                          md={{ order: 1, span: 22 }}
                          xs={{ order: 1, span: 24 }}
                        >
                          <p className="text-center mb-xs-10">
                            {data.total_disapproved}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </React.Fragment>
                );
              })}
          </Row>

          {reservation?.locked && (
            <Empty
              className="mb-40"
              description={t('reservations.detail.emptyReservationText')}
            />
          )}

          {
            exams?.length == 0 &&
            <Empty
              className="mb-40"
              description={t('reservations.detail.emptyExamsText')}
            />
          }
          {
            (exams?.length > 0) && (
              <Row>
                <Col lg={20} xs={24}>
                  <h2 className="mb-lg-30 mb-md-15 mb-sm-15 mb-xs-15 mt-lg-0 mt-md-20 mt-sm-20 mt-xs-20">
                    {t('reservations.detail.servicesResultsTitle')}
                  </h2>
                </Col>
                <Col xl={22} lg={24} xs={24} className="exam-results">
                  <Row className="exam-results-title" align='center'>
                    <Col lg={4} className='margin-auto'>
                      <p className="mb-0 pl-15 pr-15">{t('reservations.detail.serviceTitle')}</p>
                    </Col>
                    <Col lg={6} className='margin-auto'>
                      <p className="mb-0 pl-15 pr-15">{t('reservations.detail.patientTitle')}</p>
                    </Col>
                    <Col lg={4} className='margin-auto'>
                      <p className="mb-0 pl-15 pr-15">{t('reservations.detail.externalId')}</p>
                    </Col>
                    <Col lg={4} className='margin-auto'>
                      <p className="mb-0 pl-15 pr-15">{t('reservations.detail.resultTitle')}</p>
                    </Col>
                    <Col lg={6} className='margin-auto'>
                      <div style={{ float: "right", marginRight: 15 }}>
                        <span className="mb-0 mr-15 select-all-exams">
                          {t('reservations.detail.selectAllText')}
                        </span>
                        <Checkbox className="cmt-checkbox" onClick={handleSelectAllExams} />
                      </div>
                    </Col>
                  </Row>
                  {
                    exams.map((exam) =>
                      <Row
                        className="exam-results-data pt-lg-10 pt-md-20 pt-xs-20 pb-lg-10 pb-md-20 pb-sm-10 mt-10"
                        key={exam.id}
                        align='center'
                      >
                        <Col lg={4} xs={24} className="margin-auto">
                          <Row justify="space-between" className="pr-15" align='center'>
                            <Col className="mobile-column">
                              <h3 className="mobile-title pl-15">{t('reservations.detail.examTitle')}</h3>
                              <p className="mb-0 mb-md-20 mb-xs-20 pl-15 pr-15">
                                {exam.prestation && capitalizeText(exam.prestation)}
                              </p>
                            </Col>
                            <Col className="mobile-checkbox">
                              {
                                exam.document_visibility && (
                                  <Checkbox
                                    className="cmt-checkbox"
                                    checked={
                                      selectedExams?.includes(exam.consultationId)
                                    }
                                    onClick={(event) =>
                                      handleSelectExam(
                                        event.target.checked,
                                        exam.consultationId
                                      )
                                    }
                                  />
                                )
                              }
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={6} xs={24} className="margin-auto">
                          <h3 className="mobile-title pl-15">{t('reservations.detail.patientTitle')}</h3>
                          <p className="mb-0 mb-md-20 mb-xs-20 pl-15 pr-15">
                            {exam.patient && capitalizeText(exam.patient)}
                          </p>
                        </Col>
                        <Col lg={4} xs={24} className="margin-auto">
                          <h3 className="mobile-title pl-15">{t('reservations.detail.externalId')}</h3>
                          <p className="mb-0 mb-md-20 mb-xs-20 pl-15 pr-15">
                            {exam.external_id}
                          </p>
                        </Col>
                        <Col lg={4} xs={24} className="margin-auto">
                          <h3 className="mobile-title pl-15">{t('reservations.detail.resultTitle')}</h3>
                          <div className="result-state mb-xs-20 pl-15 pr-15">
                            {
                              exam.document_visibility || (exam?.pending_reasons?.length > 0)
                                ? checkExamState(exam.state, exam.state_result, exam.pending_reasons, exam.revaluation_expiration_date)
                                : <h3>Informe Pendiente</h3>
                            }
                          </div>
                        </Col>
                        <Col
                          lg={6}
                          xs={24}
                          className="download-report-wrapper pl-lg-0 pl-md-15 pl-sm-15 pl-xs-15"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {
                            exam.document_visibility
                              ? (
                                <Row justify='space-between' style={{ width: "100%" }} align='center'>
                                  <Col style={{ width: '100%'}}>
                                    <div className="download-actions">
                                      {country_code === 'PE'
                                        ? (
                                          <HasAccess
                                            permissions="can_reserve"
                                          >
                                            <HasAccess
                                              permissions="medical_administration"
                                              renderAuthFailed={
                                                <DownloadAttachment
                                                  service={exam}
                                                  selectedExams={selectedExams}
                                                  handleSelectExam={handleSelectExam}
                                                  reservation={reservation}
                                                  {...(exam.medical_reports !== null && { report: 'RRHH' })}
                                                  country_code={country_code}
                                                />
                                              }
                                            >
                                              <DownloadAttachment
                                                service={exam}
                                                selectedExams={selectedExams}
                                                handleSelectExam={handleSelectExam}
                                                reservation={reservation}
                                                country_code={country_code}
                                              />
                                            </HasAccess>
                                          </HasAccess>)
                                        : (
                                          <DownloadAttachment
                                            service={exam}
                                            selectedExams={selectedExams}
                                            handleSelectExam={handleSelectExam}
                                            reservation={reservation}
                                            country_code={country_code}
                                          />
                                        )
                                      }
                                    </div>
                                  </Col>
                                </Row>
                              ) : (
                                  <Col className="desktop-checkbox">
                                    <div class="download-actions">
                                      <Tooltip
                                        title="El informe aún no ha sido firmado"
                                        color="#223A70"
                                      >
                                        <Button
                                          className="download-report-button"
                                          disabled={true}
                                        >
                                          Descargar informe
                                        </Button>
                                      </Tooltip>
                                    </div>
                                  </Col>
                              )}
                        </Col>
                      </Row>
                    )}
                </Col>
                <Col
                  xl={22}
                  lg={23}
                  xs={24}
                  className="download-button-container mt-30 mb-40"
                >
                  <Row justify="end" className="mt-20" align='center'>
                    <Col
                      md={17}
                      xs={24}
                      className="mobile-select-all-wrapper mb-30"
                    >
                      <Row justify="end" align='center'>
                        <Col className="ml-40 mr-20">
                          <p className="mb-0 select-all-exams">
                            {t('reservations.detail.selectAllText')}
                          </p>
                        </Col>
                        <Col className='mr-lg-0 mr-md-15 mr-sm-15 mr-xs-15'>
                          <Checkbox className="cmt-checkbox" onClick={handleSelectAllExams} />
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={5} lg={6} md={8} sm={9} xs={24}>
                      <Button
                        disabled={selectedExams.length < 1}
                        loading={downloading}
                        onClick={downloadSelectedExams}
                        className="cmt-button download-all-button"
                        block
                      >
                        {exams.length > selectedExams.length
                          ? "Descargar seleccionados"
                          : "Descargar todos"}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Detail;