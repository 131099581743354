import React, { useCallback, useState, useEffect } from 'react';
import { Row, Col, Button, Spin, Form } from 'antd';
import SetPasswordModal from './components/SetPasswordModal'
import UsersForm from './components/UsersForm';
import UseUser from './hooks/useUser';

const UserProfile = () => {
  const useUser = UseUser({ token: undefined });
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);

  const fetchUserData = useCallback(async () => {
    if (useUser && useUser.formUser) {
      form.setFieldsValue(useUser.formUser);
    }
  }, [useUser.formUser]);

  useEffect(() => {
    window.addEventListener('beforeunload', confirmRefreshPage);
    return () => {
      window.removeEventListener('beforeunload', confirmRefreshPage);
    }
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData])

  const confirmRefreshPage = event => {
    event.preventDefault();
    const message = '¿Estás seguro que deseas continuar?';
    return event.returnValue = message;
  };

  const showModalPassword = () => {
    setModalVisible(true);
  };

  return (
    <Row justify='center' className='user-profile'>
      <Col lg={22} xs={22} className="mt-10">
        <Row>
          <Col>
            <Button
              onClick={() => window.history.back()}
              className='cmt-button primary non-outline'
              type='primary'
            >
              <i className='icon-system-chevron-left' />
              Volver
            </Button>
          </Col>
        </Row>
      </Col>
      <Col lg={17} xs={22} className="mb-60">
        {
          useUser.loading.fetch ?
            <Row justify='center'>
              <Spin className='cmt-spin' />
            </Row>
            :
            <Row justify='center'>
              <Col lg={24} xs={24}>
                <UsersForm
                  form={form}
                  loading={useUser.loading}
                  saveUser={useUser.saveUser}
                  showModalPassword={showModalPassword}
                  isSuperUser={false}
                  userEmail={useUser.user.email}
                  containerType="userPage"
                  countryCode={useUser.countryCode}
                />
              </Col>
            </Row>
        }

        <SetPasswordModal
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
          savePassword={useUser.saveUser}
        />

      </Col>
    </Row>
  );
};

export default UserProfile;