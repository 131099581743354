import React from 'react'
import PublicClientsNavbar from './publicClientsNavbar'
import LoginForm from './LoginForm'
import Captcha from './Captcha'

const Login = () => {
  const recaptchaRef = React.createRef();
  return (
    <PublicClientsNavbar page="login-page">
      <Captcha
        recaptchaRef={recaptchaRef}
      />
      <LoginForm
        recaptchaRef={recaptchaRef}
      />
    </PublicClientsNavbar>
  )
}

export default Login