import { Row, Col, Spin, Empty } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
const AgeGenderChart = ({ loading, data }) => {

  const customTooltip = ({ active, payload }) => {
    if (active && payload?.length) {
      return (
        <div className='age-gender-chart-tooltip'>
          <Row justify="space-around">
            <Col className='approved-wrapper'>
              <h4>Aptos</h4>
              <p className='mb-0'>Mujeres {payload[0]['payload']['approved-female']}</p>
              <p className='mb-0'>Hombres {payload[0]['payload']['approved-male']}</p>
            </Col>
            <Col className='reproved-wrapper'>
              <h4>No Aptos</h4>
              <p className='mb-0'>Mujeres {payload[0]['payload']['reproved-female']}</p>
              <p className='mb-0'>Hombres {payload[0]['payload']['reproved-male']}</p>
            </Col>
            <Col className='approved-restrictions-wrapper'>
              <h4>Aptos con restricción</h4>
              <p className='mb-0'>Mujeres {payload[0]['payload']['approved_restrictions-female']}</p>
              <p className='mb-0'>Hombres {payload[0]['payload']['approved_restrictions-male']}</p>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  };

  
  return (
    <Spin className='cmt-spin' spinning={loading}>
      <Row justify='center' className='age-gender-chart-wrapper mt-100'>
        <Col lg={24} xs={24}>
          <h2 className='mb-40'>Resultados de aptitud por rango de edad y género</h2>
        </Col>
        {
          (data.byAgeGender.length > 0) ?
            <>
            {
              data.mostApprovedRange &&
                <Col lg={24} xs={24} className='age-range-info-wrapper mb-40'>
                  <p className='mb-0'>
                    La mayor cantidad de aprobados se encuentran en un rango de {data.mostApprovedRange} años de edad
                  </p>
                </Col>
            }
              <Col lg={24} xs={24} className='chart-container'>
                <Row justify='end'>
                  <Col className='identification-colors-wrapper'>
                    <Row gutter={[0, 10]} style={{ flexDirection: 'column' }}>
                      <Col>
                        <Row gutter={17}>
                          <Col className='circle-wrapper'>
                            <div className='circle approved' />
                          </Col>
                          <Col>
                            <p className='mb-0'>Aptos</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row gutter={17}>
                          <Col className='circle-wrapper'>
                            <div className='circle reproved' />
                          </Col>
                          <Col>
                            <p className='mb-0'>No Aptos</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row gutter={17} align='middle'>
                          <Col className='circle-wrapper'>
                            <div className='circle approved-restrictions' />
                          </Col>
                          <Col>
                            <p className='mb-0'>Aptos con Restricción</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={24} xs={24}>
                    <ResponsiveContainer width={"100%"} height={350}>
                      <BarChart
                        data={data.byAgeGender}
                        margin={{
                          top: 50,
                          right: 40,
                          left: 0,
                          bottom: 5,
                        }}
                        barSize={50}
                      >
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey="range" tickLine={false} tickMargin={10} />
                        <YAxis tickCount={5} axisLine={false} tickLine={false} />
                        <Tooltip content={customTooltip}  />
                        <Bar dataKey="approved" fill="#223A70" />
                        <Bar dataKey="reproved" fill="#F29877" />
                        <Bar dataKey="approved_restrictions" fill="#AAC1F2" />
                      </BarChart>
                    </ResponsiveContainer>
                  </Col>
                </Row>
              </Col>
            </>
          :
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Sin datos de Resultados de aptitud por rango de edad y género"
            />
        }
      </Row>
    </Spin>
  );
};

export default AgeGenderChart;