import React from 'react';
import { Row, Col, Button, Tag, Empty, message, Tooltip } from 'antd';
import AuthMiddleware from './authMiddleware';
import moment from 'moment';
import 'moment/locale/es';
import { DeleteFilled } from '@ant-design/icons';
import { getNumberFormatted } from '../../../../helpers/handlers';
import { format } from "rut.js";
import { findIndex } from 'lodash';

const Confirmation = ({
  scheduleState,
  branchOffice,
  countryCode,
  cartState,
  setCart,
  isLogged,
  setIsLogged,
  t,
}) => {

  const formatDate = (date) => {
    const format = moment(date, 'YYYY-MM-DD').locale("es").format('dddd, DD [de] MMMM [de] YYYY')
    const splitDate = format.split(",")
    const lowerCaseDayName = splitDate[0].toLowerCase()
    const capitalizeDayName = lowerCaseDayName.charAt(0).toUpperCase() + lowerCaseDayName.slice(1)
    const dateFormat = splitDate[1]

    return `${capitalizeDayName}, ${dateFormat}`;
  };

  const handleRemoveService = (event, number, serviceIndex) => {
    event.preventDefault();
    const patients = [...cartState.patients];
    const selectedPatient = patients[number];
    const hasOneService = selectedPatient.services.length === 1;
    const hasTwoServices = selectedPatient.services.length === 2;
    const hasAttachedServices = selectedPatient.services[0].attached_services;

    if (
      (countryCode === 'CL' && hasOneService) ||
      (
        countryCode === 'PE' &&
        ((hasOneService && !hasAttachedServices) || (hasTwoServices && hasAttachedServices))
      )
    ) {
      message.info({
        content: t('scheduleTime.confirmation.removeServiceDisclaimer'),
        style: {
          marginTop: '45vh',
        },
        duration: 8,
      });

      return;
    }

    setCart(state => {
      const [service] = patients[number].services.splice(serviceIndex, 1);

      if (service.attached_services) {
        service.attached_services.forEach(attachedService => {
          const index = findIndex(patients[number].services, (item) => item.id === attachedService.id && item.type === attachedService.type);

          if (index !== -1) {
            const [deletedService] = patients[number].services.splice(index, 1);
            patients[number].amount -= deletedService.value;
            state.total_amount = state.total_amount - deletedService.value

            if (deletedService.attached_services && deletedService.attached_services.length > 0) {
              deletedService.attached_services.forEach(attachedServiceToDelete => {
                const index = findIndex(patients[number].services, (item) => item.id === attachedServiceToDelete.id && item.type === attachedServiceToDelete.type);
                if (index !== -1) {
                  const [attachedDeletedService] = patients[number].services.splice(index, 1);
                  patients[number].amount -= attachedDeletedService.value;
                  state.total_amount = state.total_amount - attachedDeletedService.value
                }
              })
            }
          }
        })
      }

      patients[number].amount -= service.value;

      return {
        ...state,
        patients,
        total_amount: state.total_amount - service.value,
      };

    });
  };

  const handleRemovePatient = (index) => {
    if (cartState && cartState.total > 1) {
      setCart(state => {
        const patients = [...state.patients]
        const amount = patients[index].amount;
        patients.splice(index, 1);
        return {
          ...state,
          total: state.total - 1,
          total_amount: state.total_amount - amount,
          patients,
        }
      });
    }
  };

  const handleNextStep = () => {
    const patients = cartState.patients;
    let canContinue = true;

    patients.forEach(data => {
      if (data.services.length === 0) {
        canContinue = false;
      }
    });

    if (canContinue) {
      scheduleState(4);
      if (window.hj) {
        window.hj('event', 'reservations-payment-methods')
      }
    } else {
      message.error({
        content: t('scheduleTime.confirmation.messageError'),
        style: {
          marginTop: '45vh',
        },
      });
    }
  };


  return (
    <>
    {
      (isLogged && cartState) ?
        <Row justify="center" className="confirmation-container">
          <Col lg={22} xs={24}>
            <Row justify="center">
              <Col
                lg={20} xs={24}
                className="mt-lg-40 mt-xs-0 pt-lg-30 pt-md-30 pt-xs-15 pb-lg-30 pb-md-30 pb-xs-30"
              >
                <Row justify="center" className='reservation-data-wrapper pt-20 pb-10 mb-20'>
                  <Col lg={22} xs={22}>
                    <h3>{t('scheduleTime.confirmation.summaryTitle')}</h3>
                  </Col>
                  <Col lg={22} xs={22}>
                    <Row>
                      <Col lg={12} xs={24}>
                        <Row>
                          <Col>
                            <h4 className="mr-10">{t('scheduleTime.confirmation.branchOfficeTitle')}</h4>
                          </Col>
                          <Col>
                            <p>{branchOffice.name} - {branchOffice.address}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} xs={24}>
                        <Row>
                          <Col>
                            <h4 className="mr-10">{t('scheduleTime.confirmation.totalPatientsTitle')}</h4>
                          </Col>
                          <Col>
                            <p>{cartState.total}</p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={22} xs={22}>
                    <Row>
                      <Col lg={12} xs={24}>
                        <Row>
                          <Col>
                            <h4 className="mr-10">{t('scheduleTime.confirmation.dateTitle')}</h4>
                          </Col>
                          <Col>
                            <p className="lower-case">{cartState.patients.length && formatDate(cartState.patients[0]['date'])}</p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} xs={24}>
                        <Row>
                          <Col>
                            <h4 className="mr-10">{t('scheduleTime.confirmation.totalValueTitle')}:</h4>
                          </Col>
                          <Col>
                            <p>
                            {
                              getNumberFormatted({
                                countryCode: cartState.locate_code,
                                currencyCode: cartState.currency_code,
                                amount: cartState.total_amount
                              })
                            }
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  justify='center'
                  gutter={[0, 15]}
                  className='mt-20'
                >
                {
                  (cartState.patients.length > 0) ?
                    cartState.patients.map((patient, index) =>
                      <Col lg={24} xs={22} key={index + 1} className="patient-row p-20">
                        <Row justify="space-between">
                          <Col lg={8} xs={24}>
                            <Row>
                              <Col lg={24} xs={24} className='mb-lg-10 mb-md-15 mb-sm-15 mb-xs-15'>
                                <Row>
                                  <Col>
                                    <span className='icon-system-user' />
                                  </Col>
                                  <Col>
                                    <p className="ml-10 mb-0 mt-5 patient">
                                      {t('scheduleTime.confirmation.patientTitle')} {index + 1}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                              {
                                patient.documentType && (
                                  <Col lg={24} xs={24}>
                                    <h4>
                                    {
                                      patient.documentType === 'passport' ?
                                        'Pasaporte:'
                                      : 
                                        patient.documentType === 'ce' ?
                                          'Carnet de extranjería:'
                                        :
                                          (patient.documentType && `${patient.documentType.toUpperCase()}:`)
                                    } &nbsp;
                                    <strong>
                                    {
                                      patient.documentNumber && (
                                        patient.documentType === "rut" ? format(patient.documentNumber) : patient.documentNumber
                                      )
                                    }
                                    </strong>
                                    </h4>
                                  </Col>
                                )
                              }
                              <Col lg={24} xs={24}>
                                <h4>Hora:&nbsp; <strong>{patient.block} hs</strong></h4>
                              </Col>
                              <Col lg={24} xs={24}>
                                Subtotal por {t('scheduleTime.confirmation.patientTitle').toLocaleLowerCase()}:&nbsp; <strong>
                                {
                                  getNumberFormatted({
                                    countryCode: cartState.locate_code,
                                    currencyCode: cartState.currency_code,
                                    amount: patient.amount
                                  })
                                }
                                </strong>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={15} xs={23}>
                            {
                              (patient.services.length > 0) ?
                                patient.services.map((item, index) => {
                                  return <Tag
                                    key={index}
                                    className="cmt-tag mt-10"
                                    closable={!item.attached_service_id}
                                    onClose={(e) => handleRemoveService(e, patient.number, index)}
                                    visible={true}
                                  >
                                    {item.battery_name}
                                  </Tag>
                                }
                                )
                                :
                                <Tag className="cmt-tag mt-10">{t('scheduleTime.confirmation.noServicesText')}</Tag>
                            }
                          </Col>
                          <Col lg={1} xs={1} className='trash-icon'>
                          {
                            cartState.total <= 1 ?
                              <Tooltip
                                color='#223A70'
                                title={t('scheduleTime.confirmation.removeDisclaimer')}
                              >
                                <span className='icon-system-delete disabled' />
                              </Tooltip>
                            :
                              <DeleteFilled
                                onClick={() => handleRemovePatient(index)}
                              />
                          }
                          </Col>
                        </Row>
                      </Col>
                    )
                    :
                    <Empty description={`Sin ${t('scheduleTime.confirmation.patientTitle').toLocaleLowerCase()}s ingresados`} />
                }
                </Row>
              </Col>
            </Row>
            <Row
              justify="center"
              className="mt-lg-20 mt-md-20 mt-sm-15 mt-xs-10 mb-lg-30 mb-md-0 mb-sm-30 mb-xs-80"
            >
              <Col lg={20} xs={22}>
                <Row justify="space-between" gutter={[20, 10]}>
                  <Col lg={{ order: 1, span: 16 }} xs={{ order: 2, span: 22 }}>
                    <p className='special-text'>
                      {t('scheduleTime.selectPayment.infoPaymentText')}
                      &nbsp;
                      <strong>
                        <a
                          href="https://cmtsaludhelp.zendesk.com/hc/es-419"
                          target="_blank"
                          rel="noopener, noreferrer"
                        >
                          Más información aquí
                        </a>
                      </strong>
                    </p>
                  </Col>
                  <Col lg={{ order: 2, span: 6 }} xs={{ order: 1, span: 22 }}>
                    <h2>Valor total: <strong>{
                      getNumberFormatted({
                        countryCode: cartState.locate_code,
                        currencyCode: cartState.currency_code,
                        amount: cartState.total_amount
                      })
                    }</strong></h2>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row
              justify="end"
              className="confirm-reservation-button-wrapper mt-lg-40 mt-md-30 mt-sm-15 mt-xs-10 mb-lg-30 mb-md-30 mb-sm-30 mb-xs-80"
            >
              <Col xl={5} lg={6} xs={22}>
                <Button
                  className="cmt-button primary reservations-payment-methods"
                  type='primary'
                  onClick={handleNextStep}
                  block
                  size='large'
                >
                  Continuar a Método de Pago
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        :
        <AuthMiddleware checkIfUserIsLogged={() => setIsLogged(true)} />
    }
    </>
  );
};

export default Confirmation;