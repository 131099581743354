import ExcelJS from "exceljs";

const createExcel = async (rawData, attributes) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Resultados");
  const columns = rawData.keys;
  const data = rawData.results;

  const orderedHeaders = Object.keys(columns).sort((a, b) => a - b).map(key => columns[key]);

  worksheet.columns = orderedHeaders.map((column) => {
    return {
      header: column,
      key: column,
      width: 30,
      style: { font: { size: attributes?.fontSize || 8 }},
    };
  });

  data?.forEach((item) => {
    worksheet.addRow(item);
  });

  worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
    row.eachCell({ includeEmpty: true }, (cell) => {
      if (rowNumber === 1) {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFD3D3D3" },
        };
        cell.font = { bold: true };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      } else {
        cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        row.height = attributes?.rowHeight || 90;
      }
    });
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = attributes?.fileName || "Matriz de resultados.xlsx";
  a.click();
  window.URL.revokeObjectURL(url);
};

export { createExcel };
