import { handleResponse } from './auth';
import config from "../config";

const getReservations = async (params) => {
  const url = new URL(`${config.client_api}/reservations`)
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getReservation = async (idNumber) => {
  const url = new URL(`${config.client_api}/reservations/${idNumber}`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getBranchOffices = async (params) => {
  const url = new URL(`${config.client_api}/branches/requested`);
  if (params) {
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  }
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getCompanies = async () => {
  const url = new URL(`${config.client_api}/companies/requested`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getCompanyId = async (params) => {
  const url = new URL(`${config.clientURL}/companies/getId`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  return fetch(url, requestOptions).then(handleResponse)
}

const deleteReservation = async (idNumber) => {
  const url = `${config.client_api}/reservations/${idNumber}/cancel`
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getExams = async (idNumber) => {
  const url = `${config.client_api}/reservations/${idNumber}/exams`
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getConsultationStateReasons = async () => {
  const url = `${config.general_api}/consultationStateReasons`
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}


const getReservedExams = async (idNumber) => {
  const url = `${config.client_api}/reservations/${idNumber}/reservedExams`
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getDashboard = async (idNumber) => {
  const url = `${config.client_api}/reservations/${idNumber}/dashboard`
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getReservationByToken = async (token) => {
  const url = `${config.client_api}/reservations/byToken/${token}`
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getExamsByToken = async (token) => {
  const url = `${config.client_api}/reservations/byToken/${token}/exams`
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getReservedExamsByToken = async (token) => {
  const url = `${config.client_api}/reservations/byToken/${token}/reservedExams`
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const examChecker = async (data) => {
  const url = `${config.client_api}/exams/check`
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(data)
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const downloadResults = async (data) => {
  const url = `${config.client_api}/reservations/find`
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(data)
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const getDashboardByToken = async (token) => {
  const url = `${config.client_api}/reservations/byToken/${token}/dashboard`
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

const downloadExams = async (params) => {
  const url = new URL(`${config.general_api}/assets/getAsset`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
  return fetch(url, requestOptions).then(handleResponse)
}

export const downloadReport = async (params) => {
  const url = new URL(`${config.client_api}/exams/${params.number}/${params.examId}/reportUrl`)
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
  }
  return fetch(url, requestOptions).then(handleResponse)
}

export const reservationService = {
  getReservations,
  getReservation,
  getBranchOffices,
  getCompanies,
  deleteReservation,
  getExams,
  getReservedExams,
  getDashboard,
  getReservationByToken,
  getExamsByToken,
  getReservedExamsByToken,
  getDashboardByToken,
  examChecker,
  downloadResults,
  getCompanyId,
  downloadExams,
  getConsultationStateReasons,
};
